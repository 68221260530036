<template>
  <a-form
    ref="refSymbol"
    :model="formSymbol"
    name="basic"
    :validate-trigger="['change', 'blur']"
    label-wrap
    label-align="left"
    :rules="rules"
    :scroll-to-first-error="{
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    }"
  >
    <div class="grid grid-cols-3 gap-30px pb-63px">
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.color')"
          :name="['color']"
        >
          <cds-input
            v-model:value="formSymbol.color"
            :maxlength="50"
            :placeholder="t('object_information.color_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.note')"
          :name="['note']"
        >
          <cds-input
            v-model:value="formSymbol.note"
            :maxlength="255"
            :placeholder="t('object_information.note_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.owner_name')"
          :name="['ownerName']"
          :disabled="formMode === FORM_MODE.VIEW"
        >
          <cds-input
            v-model:value="formSymbol.ownerName"
            :maxlength="100"
            :placeholder="t('object_information.owner_name_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :name="['ownerIdCard']"
        >
          <template #label>
            <div
              class="whitespace-nowrap truncate"
              :title="t('object_information.owner_id')"
            >
              {{ t('object_information.owner_id') }}
            </div>
          </template>
          <cds-input
            v-model:value="formSymbol.ownerIdCard"
            :maxlength="100"
            :placeholder="t('object_information.owner_id_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.owner_address')"
          :name="['ownerAddress']"
        >
          <cds-input
            v-model:value="formSymbol.ownerAddress"
            :maxlength="100"
            :placeholder="t('object_information.owner_address_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
    </div>
  </a-form>
  <showConfirmModal ref="showConfirm" :closable="false"></showConfirmModal>
</template>
<script setup>
import { computed, inject, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { FORM_MODE } from '../../../../util/common-constant';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { useRouter, useRoute } from 'vue-router';

const { t } = useI18n();
const { state, dispatch } = useStore();
const router = useRouter();
const formSymbol = reactive(state.ioManagement.formSymbol);
const formMode = computed(() => state.ioManagement.formMode);
const listMergeProfile = computed(() => state.ioManagement.listMergeProfile);
const refSymbol = ref(null);
const showConfirm = ref(null);
const rules = {};
const route = useRoute();
const onSaveSymbol = async (isCallApi) => {
  let title =
    formMode.value !== FORM_MODE.CREATE
      ? 'common.update_confirm'
      : 'common.save_confirm';
  refSymbol.value.validate().then(async () => {
    if (isCallApi) {
      if (await showConfirm.value.onOpenModal(title)) {
        if (await dispatch('ioManagement/checkViolation')) {
          if (formMode.value === FORM_MODE.CREATE) {
            const response = await dispatch('ioManagement/createSymbol');
            if (response) {
              if(listMergeProfile.value.length > 0) {
                await dispatch('ioManagement/mergeProfile', {
                  id: response,
                  idsSimilar: listMergeProfile.value,
                });
              }
              await dispatch('ioManagement/resetForm');
              await router.push({
                name: '/io-management/object-management',
                query: query,
              });
            }
          } else if (formMode.value === FORM_MODE.UPDATE) {
            const response = await dispatch('ioManagement/updateSymbol');
          }
        }
      }
    }
  });
};
const eventBus = inject('eventBus');
const query = reactive({
  page: 1,
  searchName: null,
  searchGroupId: null,
  searchType: null,
  searchImage: '',
});
onMounted(async () => {
  query.page = route.query.page ? route.query.page : 1;
  query.searchName = route.query.searchName ? route.query.searchName : null;
  query.searchType = route.query.searchType ? route.query.searchType : null;
  query.searchImage = route.query.searchImage ? route.query.searchImage : '';
  query.searchGroupId = route.query.searchGroupId
    ? route.query.searchGroupId
    : null;
  eventBus.on('validate-symbol', (isCallApi) => {
    onSaveSymbol(isCallApi);
  });
});
onUnmounted(() => {
  eventBus.off('validate-symbol');
});
</script>
