<template>
  <div class="bg-ems-tag1 w-full rounded-8px p-16px">
    <div class="mb-7px text-ems-main2">{{ cardDetail.time }}</div>
    <div class="flex justify-between">
      <div class="flex flex-col 2xl:flex-row gap-x-17px gap-y-8px w-2/3">
        <img
          src="../../../static/img/CardImageExample.png"
          alt="CardImageExample"
          class="w-88px h-68px object-cover"
        />
        <div class="flex flex-col gap-y-4px">
          <div class="text-white-a500 leading-22px">{{ cardDetail.name }}</div>
          <div class="text-[#379AE6] text-12px leading-19px">
            {{ cardDetail.camera }}
          </div>
          <div class="text-ems-boTro4_600 text-12px leading-19px">
            {{ cardDetail.status }}
          </div>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row gap-x-17px gap-y-10px">
        <img
          src="../../../static/img/icon/WarningIcon.svg"
          alt="WarningIcon"
          class="cursor-pointer"
        />
        <img
          src="../../../static/img/icon/ShareIcon.svg"
          alt="ShareIcon"
          class="cursor-pointer"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
  detail: {
    type: Object,
    default: () => {},
    required: true,
  },
});
const cardDetail = ref(props.detail);
</script>
