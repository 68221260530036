<template>
  <div class="date-picker-chart">
    <a-date-picker
        class="w-full"
        v-model:value="valueDate"
        :placeholder="placeholder"
        :picker="picker"
        :format="format"
        :disabled-date="disabledDate"
        @change="change"
        :show-time="showTime"
    />
  </div>
</template>
<script setup>
import {ref, watch} from "vue";
import dayjs from "dayjs";

const props = defineProps({
  value: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  format: {
    type: String,
    default: 'DD/MM/YYYY'
  },
  picker: {
    type: String,
    default: 'date'
  },
  isFromDate: {
    type: Boolean,
    default: true
  },
  disabledDate: {
    type: Function,
  },
  showTime: {},
})
const emit = defineEmits(['pickDate'])
const valueDate = ref(props.value ? dayjs(props.value) : null);
const change = (val) => {
  let date
  if(val) {
    // date = props.isFromDate ? val.startOf(props.picker) : val.endOf(props.picker)
    date = val
  }
  emit('update:value', val ? date.toDate() : null)
  emit('pickDate')
};
watch(
    () => props.value,
    (val) => (valueDate.value = val ? dayjs(val) : null)
);
</script>
<style>
.date-picker-chart .ant-picker-small:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity)) !important;
}
.date-picker-chart .ant-picker:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity)) !important;
}
.date-picker-chart .ant-picker-suffix {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
}
.date-picker-chart .ant-picker {
  min-width: 100px;
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-color: transparent !important;
}
.date-picker-chart .ant-picker-focused {
  box-shadow: none;
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity)) !important;
}
.date-picker-chart .ant-picker-input input {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
}
.date-picker-chart .ant-picker-clear, .date-picker-chart .ant-picker.ant-picker-disabled {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity)) !important;
}
.date-picker-chart .ant-picker-input input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
</style>
