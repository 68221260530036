import Table from '@/components/table/Table';
import { computed, onMounted, reactive, ref, toRaw ,watch,defineAsyncComponent} from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import ActionButton from '@/components/action-button';
import { ModalStyled } from '@/components/modals/styled';
import { useForm } from 'ant-design-vue/es/form';
import dayjs from 'dayjs';
import { useStore } from 'vuex';
import _ from 'lodash';
import { mapFields } from 'vuex-map-fields';
import Tag from '@/components/tags/Tag';
import { useI18n } from 'vue-i18n';
import { PHAN_HE } from '@/config/Constant.js';
import { debounce } from 'lodash';
import InputSearch from '@/components/input-search/index.vue';
import SelectCustom from '../../../components/select-custom/index.vue';
import DrawerCustom from '@/components/drawer-custom/index.vue'
import InlineSvg from 'vue-inline-svg';

import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import { notification } from 'ant-design-vue';
import {trim, hasPermEdit} from '@/util/common-utils'
const apiFetch = ConstantAPI.menu.SEARCH;
export default {
  name: 'Menu',
  components: {
    'cds-table': Table,
    ActionButton,
    ModalStyled,
    Tag,
    InputSearch,
    SelectCustom,
    DrawerCustom,
    InlineSvg,
    ModalConfirm

  },
  setup() {
    const { t } = useI18n();
    const openDrawer = ref(false);
    const CreateMenu = defineAsyncComponent(() => import('./FormMenuModal.vue'));
    const CreateButton = defineAsyncComponent(() => import('./FormButtonModal.vue'));
    const component = ref(CreateMenu);
    const drawerKey = ref(0);
    const isPopupDelete = ref(false)
    const visible = ref(false)
    const title = ref('')
    const columns = ref([
      {
        title: t('menu.name'),
        dataIndex: 'name',
        key: 'name',
        resizable: true,
        width: 260,
      },
      {
        title: t('menu.icon'),
        dataIndex: 'icon',
        resizable: true,
        key: 'icon',
        width: 100,
        align: 'center',
        scopedSlots: { customRender: 'icon' },
      },
      {
        title: t('menu.type'),
        dataIndex: 'type',
        key: 'type',
        resizable: true,
        align: 'center',
        width: 150,
      },
      {
        title: t('menu.phan-he'),
        dataIndex: 'clientId',
        key: 'clientId',
        resizable: true,
        width: 260,
      },
      {
        title: t('menu.path'),
        dataIndex: 'path',
        key: 'path',
        resizable: true,
        width: 260,
      },
      {
        title: t('menu.order'),
        dataIndex: 'orderNum',
        key: 'orderNum',
        resizable: true,
        width: 120,
      },
      {
        title: t('menu.status'),
        dataIndex: 'status',
        key: 'status',
        resizable: true,
        width: 150,
      },
      {
        title: t('menu.action'),
        key: 'action',
        width: 180,
        fixed: 'right',
      },
    ]);
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };

    const formState = reactive({
      menuName: '',
      clientId: null,
    });
    const { resetFields } = useForm(formState);

    const resetForm = () => {
      resetFields();
      formState.menuName = '';
      formState.clientId = null;
      paramFetch.menuName = formState.menuName;
      paramFetch.clientId = formState.clientId;
    };
    let paramFetch = _.cloneDeep(toRaw(formState));
    const table = ref(null);
    const { state, dispatch } = useStore();
    const perms = computed(() => state.auth.userInfo.permissions);
    const typeMenu = computed(() => (value) => {
      if (value === 1) return { text: t('menu.button'), color: '#3A5EF6' };
      if (value === 0) return { text: t('menu.menu'), color: '#7D3AAE' };
      return { text: t('menu.other'), color: '#7c7c7c' };
    });
    const onCancel = () => {
      dispatch('menu/setVisibleIcon', false)
      openDrawer.value= false
    };
    const onSearch = debounce(function () {
      paramFetch.menuName = trim(formState.menuName);
      paramFetch.clientId =
        formState.clientId === 'all' ? null : formState.clientId;
      table.value.resetAndFetch();
    }, 300);

    const disabledDate = (current) => current && current > dayjs().endOf('day');
    onMounted(() => {
      dispatch('menu/getAll').then();
      dispatch('setTitleHeader', t('menu.menu')).then();
    });
    watch([() => formState.menuName, () => formState.clientId], () => {
      onSearch();
    });

    const buttonActions = [
      {
        permission: 'menu:edit',
        type: 'EDIT',
      },
      {
        type: 'VIEW',
      },
    ];
    const buttonsForm = [
      { type: 'RESET' },
      { type: 'CREATE_BUTTON', permission: 'menu:create-button' },
      { type: 'CREATE_MENU', permission: 'menu:create' },
      { type: 'DELETE', permission: 'menu:delete' },
    ];

    const onClose = ()=>{
      openDrawer.value = false
    }

    const handleSaveSuccess = () => {
      openDrawer.value = false
      table.value.fetchData();
    };
    return {
      resetForm,
      onSearch,
      onCancel,
      disabledDate,
      typeMenu,
      columns,
      apiFetch,
      labelCol,
      wrapperCol,
      formState,
      resetFields,
      dispatch,
      paramFetch,
      table,
      titleModal: '',
      buttonsForm,
      buttonActions,
      PHAN_HE,
      t,
      openDrawer,
      component,
      title,
      CreateMenu,
      CreateButton,
      onClose,
      drawerKey,
      visible,
      handleSaveSuccess,
      isPopupDelete,
      perms,
      hasPermEdit
    };
  },
  computed: {
    ...mapFields('menu', ['visibleMenu', 'visibleButton']),
  },
  methods: {
    async preCreateMenu() {
      this.drawerKey += 1;
      this.title = this.t('menu.create-menu');
      await this.dispatch('menu/preCreateMenu');
      this.component = this.CreateMenu;
      this.component.name = 'CreateMenu';
      this.openDrawer = true
    },
    async preCreateButton() {
      this.drawerKey += 1;
      this.title = this.t('menu.create-button');
      await this.dispatch('menu/preCreateButton');
      this.component = this.CreateButton;
      this.component.name = 'CreateButton';
      this.openDrawer = true
    },
    async saveSuccess() {
      await this.dispatch('menu/setVisibleMenu', false);
      await this.dispatch('menu/setVisibleIcon', false);
      await this.dispatch('menu/setVisibleButton', false);
      this.dispatch('menu/getAll');
      this.$refs.table.fetchData();
    },
    async onEdit(row) {
      this.drawerKey += 1;
      this.openDrawer = true;
      const isMenu = row.type === 0;
      this.title = isMenu ? this.t('menu.update-menu') : this.t('menu.update-button');
      this.component = isMenu ? this.CreateMenu : this.CreateButton;
      this.component.name = isMenu ? 'CreateMenu' : 'CreateButton';
      await this.dispatch('menu/preUpdate', row);
    },
    async onView(row) {
      this.drawerKey += 1;
      this.openDrawer = true;
      const isMenu = row.type === 0;
      this.title = isMenu ? this.t('menu.view-menu') : this.t('menu.view-button');
      this.component = isMenu ? this.CreateMenu : this.CreateButton;
      this.component.name = isMenu ? 'CreateMenu' : 'CreateButton';
      await this.dispatch('menu/preView', row);
    },
    async onDelete() { 
      if(this.$refs.table.selectedRows.length>0){
        this.isPopupDelete = true
      }
      else {
        notification.error({
          message: this.t('common.notification'),
          description: this.t('common.no_record_selected'),
          duration: 4,
        });
      }
    },
    async handleConfirm (){
      if (await this.dispatch('menu/delete', this.$refs.table.selectedRows))
      this.$refs.table.fetchData();
      this.isPopupDelete = false
    }
  },
};
