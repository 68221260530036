<template>
  <cds-main class="!pt-10 list-device flex flex-col h-full">
    <div
      class="mb-3 ! mr-6 ml-4 flex justify-between items-end gap-x-[32px] gap-y-3"
    >
      <div class="text-ems-white font-semibold text-16px uppercase">
        {{ t('log.title') }}
      </div>
      <div class="flex items-center gap-x-[32px]">
        <cds-from-to-date
          :disabled-date="disabledDate"
          v-model:dateFrom="formSearch.dateFrom"
          v-model:dateTo="formSearch.dateTo"
          :is-show-label="false"
          @changeDate="onSearch"
        />
        <input-search
          class="ant-input-sm !pb-0"
          v-model:value="formSearch.keyword"
          size="small"
          :placeholder="$t('common.search')"
          :has-search-advanced="false"
          :maxlength="255"
          @press-enter="onSearch"
          @change="onDelaySearch"
        />
        <ClipButton
          v-if="checkHasPermission(perms, LOG_PERM.EXPORT)"
          type="primary"
          size="small"
          style="height: fit-content"
          class="w-[150px] mt-6px"
          @click="onExport"
        >
          <img
            src="@/static/img/icon/icon-up.svg"
            alt="icon-up"
            height="24"
            width="24"
          />
          <span class="text-sm text-ems-white ml-1 whitespace-nowrap">{{
            $t('common.export-file')
          }}</span>
        </ClipButton>
      </div>
    </div>
    <cds-cards title="" class="h-full full-height-card m-0">
      <cds-table
        ref="table"
        :has-checkbox="false"
        :index-column="true"
        :pagination="true"
        :columns="columns"
        :api-fetch="apiFetch"
        :param-fetch="formSearch"
      >
        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'actionButton' && record">
            <ActionButton :buttons="buttonActions" @onView="onView(record)" />
          </template>

          <template v-if="column.key === 'status' && record">
            <span
              class="text-sm px-10px py-2px border-1 border-solid rounded-[4px]"
              :class="
                record.status === 'Success'
                  ? 'text-ems-boTro4_600 border-ems-boTro4_600'
                  : 'text-ems-main2 border-ems-main2'
              "
              >{{ record.status }}</span
            >
          </template>
        </template>
      </cds-table>
    </cds-cards>
    <div
      class="absolute top-[53%] right-0 z-60 icon-expand-drawer cursor-pointer"
      @click="openDrawer = true"
      v-if="isSave"
    />
    <drawer-custom
      :visible="openDrawer"
      :width="450"
      @onClose="onClose"
      @onCancel="openDrawer = false"
      :component="FormModal"
      :form-state="formState"
      :title="t('log.log-detail')"
      @onHide="onHide"
    />
  </cds-main>
</template>

<script setup>
import { computed, defineAsyncComponent, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { debounce } from 'lodash';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
import ActionButton from '../../../components/action-button';
import { formatDate, checkHasPermission } from '@/util/common-utils';
import ConstantAPI from '../../../config/ConstantAPI';
import InputSearch from '@/components/input-search/index.vue';
import dayjs from 'dayjs';
import { DataService } from '../../../dataService/dataService';
import { notification } from 'ant-design-vue';
import { LOG_PERM } from '@/util/common-constant';

const DrawerCustom = defineAsyncComponent(() =>
  import('@/components/drawer-custom/index.vue')
);
const FormModal = defineAsyncComponent(() =>
  import('./components/FormModal.vue')
);
const apiFetch = ConstantAPI.LOG_MANAGEMENT.GET_LIST;
const { t } = useI18n();
const columns = ref([
  {
    title: t('log.username'),
    dataIndex: 'username',
    key: 'username',
    resizable: true,
    width: 250,
    align: 'left',
  },
  {
    title: t('log.hoTen'),
    dataIndex: 'fullName',
    resizable: true,
    key: 'fullName',
    width: 150,
    align: 'left',
  },
  {
    title: t('log.thaoTac'),
    dataIndex: 'action',
    resizable: true,
    key: 'action',
    width: 150,
    align: 'left',
  },
  {
    title: t('log.chucNang'),
    dataIndex: 'function',
    resizable: true,
    key: 'function',
    width: 150,
    align: 'left',
  },
  {
    title: t('log.status'),
    dataIndex: 'status',
    resizable: true,
    key: 'status',
    width: 150,
    sortable: true,
    align: 'left',
  },
  {
    title: t('log.thoiGian'),
    dataIndex: 'time',
    resizable: true,
    key: 'time',
    sortable: true,
    customRender({ record }) {
      return formatDate(record.time, 'HH:mm:ss DD/MM/YYYY');
    },
    width: 200,
    align: 'left',
  },
  {
    title: t('log.action'),
    dataIndex: 'actionButton',
    key: 'actionButton',
    width: 110,
    align: 'center',
    fixed: 'right',
  },
]);
const buttonActions = [
  {
    type: 'VIEW',
  },
];
const { state, dispatch } = useStore();

const perms = computed(() => state.auth.userInfo.permissions);

const table = ref(null);
const disabledDate = (current) => current && current > dayjs().endOf('day');

onMounted(() => {
  dispatch('setTitleHeader', t('log.header'));
});
const openDrawer = ref(false);
const isSave = ref(false);
const formSearch = reactive({
  keyword: '',
  dateFrom: null,
  dateTo: null,
});
let formState = reactive({
  maNV: null,
  username: null,
  hoTen: null,
  thaoTac: null,
  chucNang: null,
  status: null,
  thoiGian: null,
});
const onSearch = () => {
  table.value.fetchData().then();
};
const DEBOUNCE_TIME = 300;
const onDelaySearch = debounce(() => {
  onSearch();
}, DEBOUNCE_TIME);
const onHide = () => {
  openDrawer.value = false;
  isSave.value = true;
};
const onView = (row) => {
  formState.username = row.username;
  formState.fullName = row.fullName;
  formState.action = row.action;
  formState.function = row.function;
  formState.status = row.status;
  formState.time = row.time;
  openDrawer.value = true;
};
const onClose = (isSaveData) => {
  openDrawer.value = false;
  isSave.value = isSaveData;
};
const onExport = async () => {
  try {
    const response = await DataService.getConfig(
      ConstantAPI.LOG_MANAGEMENT.EXPORT.url,
      formSearch,
      null,
      'blob'
    );
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'log_he_thong.xlsx');
    document.body.appendChild(link);
    link.click();
    notification.success({
      message: t('common.notification'),
      description: t('common.export-file_success'),
      duration: 4,
    });
  } catch (err) {
    notification.error({
      message: t('common.notification'),
      description: t('common.export-file_fail'),
      duration: 4,
    });
  }
};
</script>

<style lang="scss">
.list-device .ant-input-sm input.ant-input {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.list-device .ant-input-sm input.ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.list-device .icon-expand-drawer {
  background-image: url('../../../static/img/icon/open-drawer.svg');
  background-size: cover;
  height: 67px;
  width: 19px;
}
.list-device .icon-expand-drawer:hover {
  background-image: url('../../../static/img/icon/open-drawer-active.svg');
}
.list-device .ant-form-item {
  margin-bottom: 0 !important;
}
.list-device .ant-form-item .ant-picker {
  border: 1px solid;
  border-color: #555 !important;
  background: #1f1f1f !important;
  border-radius: 4px !important;
  height: 39px !important;
  line-height: 39px !important;
  padding-left: 17px !important;
}
</style>
