<template>
  <div
    class="max-h-500px 3xl:max-h-760px overflow-y-auto"
    @scroll="checkScroll"
  >
    <a-table
      :has-checkbox="false"
      :index-column="true"
      :data-source="listFrequency"
      ref="table"
      :pagination="false"
      :columns="columns"
      :loading="isLoading"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'stt' && record">
          {{ index + 1 }}
        </template>
        <template v-if="column.key === 'name'">
          <span
            class="text-ems-neu400 hover:text-ems-main1 hover:underline hover:cursor-pointer"
            @click="onView(record)"
          >
            {{ record.name ? record.name : record.id }}
          </span>
        </template>
        <template v-if="column.key === 'image' && record">
          <div
            class="w-[62px] h-[62px] img-custom hover:cursor-pointer"
            @click="onView(record)"
          >
            <img
              class="w-full h-full object-cover"
              :src="
                record.img ? record.img : require('@/static/img/no-img.png')
              "
              alt=""
            />
          </div>
        </template>
        <template v-if="column.key === 'location' && record">
          <a-tooltip placement="bottom" :title="record.location">
            <span class="line-clamp-1">{{ record.location }} </span>
          </a-tooltip>
        </template>
      </template>
    </a-table>
    <div class="flex justify-center pb-2">
      <a-button
        v-if="isShowButton"
        type="primary"
        ghost
        class="!text-ems-main2 !border-ems-main2 text-base font-semibold"
        @click="handleViewMore()"
      >
        {{ t('common.view-more') }}
      </a-button>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { FORM_MODE, OBJECT_TYPE } from '@/util/common-constant';
import { formatNumberReZero } from '@/util/common-utils';

defineProps({});
const { t } = useI18n();
const { state, dispatch } = useStore();
const isShowButton = ref(false);
const route = useRoute();
const router = useRouter();

const columns = ref([
  {
    title: t('common.stt'),
    key: 'stt',
    width: '10%',
  },
  {
    title: t('report_statistical.image'),
    dataIndex: 'img',
    key: 'image',
    width: '15%',
  },
  {
    title: 'ID',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
  },
  {
    title: t('report_statistical.frequency'),
    dataIndex: 'frequency',
    key: 'frequency',
    width: '20%',
    customRender: ({ record }) => {
      return formatNumberReZero(record.frequency);
    },
  },
  {
    title: t('report_statistical.location'),
    dataIndex: 'location',
    key: 'location',
    width: 100,
  },
]);

const handleViewMore = () => {
  dispatch(
    'reportStatistical/fetchFrequencies',
    listFrequency.value.length + 10
  );
  isShowButton.value = false;
};

const onView = (record) => {
  router.push({
    name: '/io-management/object-management/edit',
    query: {
      id: record.id,
      type: OBJECT_TYPE[record.type],
      mode: FORM_MODE.VIEW,
      page: 1,
      ...route.query,
    },
  });
};

const checkScroll = (e) => {
  if (
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
    listFrequency.value.length !== listDraftFrequency.value.length
  ) {
    isShowButton.value = true;
  }
};

const listFrequency = computed(() => state.reportStatistical.frequencies);
const listDraftFrequency = computed(
  () => state.reportStatistical.draftFrequencies
);
const isLoading = computed(() => state.reportStatistical.loading);
</script>
