<template>
  <div class="bg-ems-gray1000 min-h-100vh">
    <div
      class="flex justify-between bg-ems-gray900 pl-16px pr-24px border-b-1 border-solid border-ems-gray700"
    >
      <div class="pt-38px pb-13px">
        <div class="text-ems-white font-semibold text-16px uppercase">
          {{ t('alert_coordinator.alert_coordinator') }}
        </div>
      </div>
      <div class="flex flex-col justify-center">
        <ClipButton
          class="flex items-center"
          :type="'primary'"
          @click="onOpenDrawer"
        >
          <span>{{ t('alert_coordinator.coordinator') }}</span>
        </ClipButton>
      </div>
    </div>
    <a-form
      ref="formRef"
      :model="formState"
      name="basic"
      :validate-trigger="['change', 'blur']"
      label-wrap
      label-align="left"
      :rules="rules"
      :scroll-to-first-error="{
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }"
    >
      <div class="px-16px mt-16px pb-16px grid grid-cols-10 gap-16px">
        <div class="col-span-7 bg-ems-gray800 pt-20px px-20px pb-28px">
          <div>
            <span class="text-ems-gray500">Camera 01</span>
            <div class="w-full h-513px bg-ems-gray700 mt-5px"></div>
          </div>
          <div class="h-1px w-full bg-ems-gray700 mt-17px mb-26px"></div>
          <div class="grid grid-cols-2 xl:grid-cols-3 gap-30px">
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :label="t('predict_management.name_detail')"
                :name="['username']"
              >
                <cds-input
                  v-model:value="formState.username"
                  :show-count="false"
                  :maxlength="100"
                  :placeholder="t('predict_management.name_detail_placeholder')"
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :label="t('predict_management.group_detail')"
                :name="['username']"
              >
                <cds-input
                  v-model:value="formState.username"
                  :show-count="false"
                  :maxlength="100"
                  :placeholder="
                    t('predict_management.group_detail_placeholder')
                  "
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :label="t('predict_management.status_detail')"
                :name="['username']"
              >
                <cds-select
                  v-model:value="formState.username"
                  :placeholder="
                    t('predict_management.status_detail_placeholder')
                  "
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :label="t('predict_management.camera_detail')"
                :name="['username']"
              >
                <cds-input
                  v-model:value="formState.username"
                  :maxlength="100"
                  :placeholder="
                    t('predict_management.camera_detail_placeholder')
                  "
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :label="t('predict_management.priority_detail')"
                :name="['username']"
              >
                <cds-input
                  v-model:value="formState.username"
                  :show-count="false"
                  :maxlength="100"
                  :placeholder="
                    t('predict_management.priority_detail_placeholder')
                  "
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :label="t('predict_management.time_detail')"
                :name="['username']"
              >
                <cds-date-picker
                  v-model:value="formState.types"
                  placeholder=""
                  class="w-full"
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :label="t('predict_management.accuracy_detail')"
                :name="['username']"
              >
                <cds-input
                  v-model:value="formState.username"
                  :show-count="false"
                  :maxlength="100"
                  :placeholder="
                    t('predict_management.accuracy_detail_placeholder')
                  "
                />
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="col-span-3 bg-ems-gray800 pt-20px pb-28px pr-5px h-938px">
          <div class="flex justify-between pl-20px pr-15px">
            <div
              class="text-ems-gray200 text-18px mb-20px font-semibold uppercase"
            >
              {{ t('alert_coordinator.detail_info') }}
            </div>
            <img
              src="../../../static/img/icon/MenuExpand.svg"
              alt="MenuExpand"
              class="h-24px cursor-pointer"
            />
          </div>
          <div class="pl-20px pr-15px">
            <InputFilter
              v-model:value="formState.nhanDang"
              :show-count="false"
              :maxlength="100"
              :placeholder="t('common.search')"
              class="mb-14px"
            >
              <template #prefix>
                <img
                  src="../../../static/img/icon/IconSearch.svg"
                  alt="IconSearch"
                />
              </template>
            </InputFilter>
            <SelectFilter
              v-model:value="formState.loaiDoiTuong"
              :placeholder="t('alert_coordinator.alert_prediction')"
              :has-icon="true"
              :options="options"
              :is-i18n="false"
            >
              <img
                src="../../../static/img/icon/ClockCountdown.svg"
                alt="ClockCountdown"
              />
            </SelectFilter>
          </div>
          <div
            class="h-1px w-[calc(100%-40px)] mx-auto bg-ems-gray700 mt-17px mb-14px"
          ></div>
          <div
            class="flex flex-col gap-y-14px h-730px pl-20px pr-10px overflow-y-auto"
          >
            <template v-for="item in listCard" :key="item.time">
              <DetailCard :detail="item"></DetailCard>
            </template>
          </div>
        </div>
      </div>
    </a-form>
  </div>
  <drawer-custom
    v-show="onOpenSearch"
    :visible="onOpenSearch"
    :width="450"
    @onClose="onClose"
    @onCancel="onOpenSearch = false"
    :component="CoordinatorForm"
    :form-state="formState"
    :title="t('alert_coordinator.coordinator')"
    @onSearchAdvanced="onSearch"
    @onHide="onHide"
  />
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import ClipButton from '@/components/buttons/ClipButton.vue';
import InputFilter from '@/components/input-filter/index.vue';
import SelectFilter from '@/components/select-filter/index.vue';
import { defineAsyncComponent, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import DetailCard from './DetailCard.vue';

const DrawerCustom = defineAsyncComponent(() =>
  import('@/components/drawer-custom/index.vue')
);
const CoordinatorForm = defineAsyncComponent(() =>
  import('@/view/alert-system/alert-coordinator/CoordinatorForm.vue')
);
const onOpenSearch = ref(false);
const isSave = ref(false);
const { t } = useI18n();
const { dispatch } = useStore();
let formState = reactive({
  popUp: false,
  camera: false,
  VMS: false,
  email: false,
  EMS: false,
  mobileApp: false,
  telegram: false,
});
onMounted(() => {
  dispatch('setTitleHeader', t('alert_coordinator.alert_coordinator'));
});
const resetForm = () => {
  formState = reactive({
    name: '',
    names: '',
    types: '',
    groups: '',
    image: [],
  });
};
const onHide = () => {
  onOpenSearch.value = false;
  isSave.value = true;
};
const onSearch = () => {};
const onOpenDrawer = () => {
  onOpenSearch.value = true;
};
const onClose = (isSaveData) => {
  if (!isSaveData) {
    resetForm();
  }
  onOpenSearch.value = false;
  isSave.value = isSaveData;
};
const listCard = [
  {
    time: '00:37',
    name: 'Đám đông ',
    camera: 'At Camera 01',
    status: 'Xuất hiện',
    img: '',
  },
  {
    time: '00:35',
    name: 'Arlene McCoy',
    camera: 'At Camera 01',
    status: 'Xuất hiện',
  },
  {
    time: '00:33',
    name: 'Đám đông',
    camera: 'At Camera 01',
    status: 'Check-in',
  },
  {
    time: '00:30',
    name: 'Đám đông',
    camera: 'At Camera 01',
    status: 'Xuất hiện',
  },
  {
    time: '00:27',
    name: 'Arlene McCoy',
    camera: 'At Camera 01',
    status: 'Check-out',
  },
  {
    time: '00:26',
    name: 'Arlene McCoy',
    camera: 'At Camera 01',
    status: 'Check-in',
  },
  {
    time: '00:22',
    name: 'Đám đông',
    camera: 'At Camera 01',
    status: 'Xuất hiện',
  },
  {
    time: '00:15',
    name: 'Arlene McCoy',
    camera: 'At Camera 01',
    status: 'Check-out',
  },
];
const options = [
  { value: '1', label: 'Dự đoán 1' },
  { value: '2', label: 'Dự đoán 2' },
  { value: '3', label: 'Dự đoán 3' },
  { value: '4', label: 'Dự đoán 4' },
];
</script>
<style scoped>
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 85, 85, var(--tw-bg-opacity));
}
</style>
