<template>
  <cds-main class="h-full">
    <cds-cards :title="null">
      <a-form
        ref="formRef"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
        :rules="rules"
        :validate-trigger="['change', 'blur']"
      >
        <a-row :gutter="30" class="pt-3">
          <a-col :xxl="8" :xl="8" :lg="12" :md="12" :xs="24">
            <a-form-item>
              <input-search
                class="w-100 ant-input-sm"
                v-model:value="formState.name"
                :maxlength="50"
                size="small"
                :placeholder="$t('common.search')"
                :has-search-advanced="true"
                @press-enter="onSearch"
                @onSearchAdvanced="onSearchAdvanced"
              >
              </input-search>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </cds-cards>
    <cds-cards title="" class="h-[calc(100vh-82px-25px-60px)] full-height-card">
      <cds-table
        ref="table"
        has-checkbox
        :index-column="true"
        pagination
        :columns="columns"
        :api-fetch="apiFetch"
        :param-fetch="formState"
      >
        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'groupName' && record">
            <div class="flex items-center gap-x-2">
              <cds-button
                v-if="record.groupName.length > 0"
                key="submit"
                type="danger"
                size="small"
                type-html="submit"
                class="!w-auto"
              >
                {{ record.groupName[0] }}
              </cds-button>

              <a-tooltip v-if="record.groupName.length >= 2">
                <template #title>
                  <div
                    class="flex items-center ml-1"
                    v-for="(item, index) in record.groupName.slice(
                      1,
                      record.groupName.length
                    )"
                    :key="index"
                  >
                    <cds-button
                      key="submit"
                      type="primary"
                      size="small"
                      type-html="submit"
                      class="!w-auto"
                    >
                      {{ item }}
                    </cds-button>
                  </div>
                </template>
                <cds-button
                  key="submit"
                  type="primary"
                  size="small"
                  type-html="submit"
                  class="!w-auto"
                >
                  ...
                </cds-button>
              </a-tooltip>
            </div>
          </template>
          <template v-if="column.key === 'avatar' && record">
            <img
              v-if="record.avatar"
              :src="record.avatar"
              :alt="record.name"
              class="w-[172px] h-[172px] object-cover"
            />
          </template>
          <template v-if="column.key === 'action' && record">
            <action-button
              :buttons="buttonActions"
              @onView="onView(record)"
            />
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

  <drawer-custom
    v-show="onOpenSearch"
    :visible="onOpenSearch"
    :width="450"
    @onClose="onClose"
    @onCancel="onOpenSearch = false"
    :component="SearchAdvanced"
    :form-state="formState"
    @onSearchAdvanced="onSearch"
    @onHide="onHide"
  />
  <cds-modal
      :title="t('predict_management.detail_alert')"
      :visible="isShowModal"
      :mask-closable="false"
      :width="'1095px'"
      hide-footer
      @onCancel="isShowModal = false"
  >
    <form-modal ref="modal" :detail="formDetail"/>
  </cds-modal>
</template>
<script setup>
import { defineAsyncComponent, onMounted, reactive, ref } from 'vue';
import { maxlength } from '@/util/validation';
import { useForm } from 'ant-design-vue/es/form';
import ConstantAPI from '@/config/ConstantAPI';
import { useStore } from 'vuex';
import InputSearch from '@/components/input-search/index.vue';
import { useI18n } from 'vue-i18n';
import { defaultButton } from '../../../util/common-constant';
import ActionButton from '../../../components/action-button/index.vue';
import FormModal from "../../alert-system/predict-management/FormModal.vue";

const apiFetch = ConstantAPI.user.SEARCH;
const DrawerCustom = defineAsyncComponent(() =>
  import('@/components/drawer-custom/index.vue')
);
const SearchAdvanced = defineAsyncComponent(() =>
  import('@/view/object-information/components/SearchAdvanced.vue')
);
const onOpenSearch = ref(false);
const isSave = ref(false);
const labelCol = {
  lg: 8,
  md: 8,
  xs: 24,
};
const wrapperCol = {
  lg: 16,
  md: 16,
  xs: 24,
};
const { t } = useI18n();
const { buttonActions } = defaultButton('predict-management');
const table = ref(null);
const columns = ref([
  {
    title: t('predict_management.image'),
    dataIndex: 'username',
    key: 'username',
    resizable: true,
    width: 100,
    align: 'center',
  },
  {
    title: t('predict_management.name'),
    dataIndex: 'username',
    key: 'username',
    resizable: true,
    width: 100,
    align: 'left',
  },
  {
    title: t('predict_management.priority'),
    dataIndex: 'registerVehicle',
    resizable: true,
    key: 'registerVehicle',
    width: 100,
    align: 'left',
  },
  {
    title: t('predict_management.accuracy'),
    dataIndex: 'relevantVehicle',
    resizable: true,
    key: 'relevantVehicle',
    width: 100,
    align: 'left',
  },
  {
    title: t('predict_management.status'),
    dataIndex: 'username',
    resizable: true,
    key: 'username',
    width: 100,
    align: 'left',
  },
  {
    title: t('predict_management.camera'),
    dataIndex: 'username',
    resizable: true,
    key: 'username',
    width: 100,
    align: 'left',
  },
  {
    title: t('predict_management.time'),
    dataIndex: 'username',
    resizable: true,
    key: 'username',
    width: 100,
    align: 'left',
  },
  {
    title: t('predict_management.group'),
    dataIndex: 'username',
    resizable: true,
    key: 'username',
    width: 100,
    align: 'left',
  },
  {
    title: t('predict_management.action'),
    key: 'action',
    fixed: 'right',
    width: 100,
    align: 'center',
  },
]);
const { dispatch } = useStore();

onMounted(() => {
  dispatch('setTitleHeader', t('predict_management.predict_management'));
});
// const permissions = computed(() => state.auth.userInfo.permissions || []);
const isShowModal = ref(false)
let formState = reactive({
  name: '',
  names: '',
  types: '',
  groups: '',
  image: [],
});
const rules = ref({
  name: [maxlength(50, 'name')],
});

const { validate } = useForm(formState, rules);
const onHide = () => {
  onOpenSearch.value = false;
  isSave.value = true;
};
const onSearch = (form) => {
  formState = Object.assign(form);
  validate().then(
    () => {
      // setParamFetch();
      table.value.fetchData(true).then();
    },
    () => {}
  );
};

// const handleDeleteRow = async (id) => {
//   let payload = [];
//   payload.push({
//     id,
//   });
//   await dispatch('person/delete', payload);
// };
const formDetail = ref({})
const onView = (detail) => {
  isShowModal.value = true
  formDetail.value = detail
}
const onSearchAdvanced = () => {
  onOpenSearch.value = true;
};

const resetForm = () => {
  formState = reactive({
    name: '',
    names: '',
    types: '',
    groups: '',
    image: [],
  });
};

const onClose = (isSaveData) => {
  if (!isSaveData) {
    resetForm();
  }
  onOpenSearch.value = false;
  isSave.value = isSaveData;
};
</script>
