<template>
  <div class="h-[calc(100%-50px)] travel-route">
    <cds-from-to-date
      v-model:dateFrom="formState.dateFrom"
      v-model:dateTo="formState.dateTo"
      :is-show-label="false"
      :allow-clear="true"
      class="from-to-date mb-[10px]"
      v-if="showSearch"
    />
    <div v-if="isLoading" class="spin bg-transparent h-[400px]">
      <a-spin />
    </div>
    <div v-else class="h-full">
      <Empty
        v-if="!locationData || locationData.length === 0"
        :description="t('common.no_data_displayed')"
        class="mt-[60px]"
      />
      <div
        v-else
        class="flex flex-col gap-[22px] cursor-pointer max-h-[calc(100vh-380px)] overflow-y-auto hidden-scroll pr-1"
      >
        <div
          v-for="(item, idx) in locationData"
          :key="idx"
          class="p-16px rounded-lg flex gap-[20px] items-center bg-ems-tag1 border-1px border-solid overflow-x-clip"
          :class="currentData === idx ? 'border-[#FF0000]' : 'border-ems-tag1'"
          @click="selectItem(idx, item)"
        >
          <img
            :src="
              !item.imageCropUrl
                ? require('@/static/img/no-img.png')
                : item.imageCropUrl
            "
            width="120"
            height="120"
            class="rounded-lg flex-shrink-0 border-1 border-solid border-ems-gray300 mr-1 w-[120px] h-[120px] object-cover"
          />

          <div class="flex flex-col justify-center gap-[12px]">
            <span class="text-lg text-ems-gray100 font-semibold">{{
              item.camera.name
            }}</span>
            <ul class="list-disc ml-20px flex flex-col gap-1">
              <li
                class="text-[12px] leading-normal font-semibold text-ems-gray500"
              >
                {{ t('object_tracing.vi-tri') }} {{ item.camera.location }}
              </li>
              <li
                class="text-[12px] leading-normal font-semibold text-ems-gray500"
              >
                {{ t('object_tracing.frequency-appear') }}
                <span class="text-sm text-ems-main2">{{ item.count }}</span>
              </li>
            </ul>

            <a
              :href="`/trace-management/identification-event?searchCamera=${
                item.camera.id
              }&searchName=${
                item.name ? item.name : item.profileUUID
              }&idTracing=${route.query.id}&tab=${route.query.tab}&subTab=${
                route.query.subTab
              }`"
              class="block w-[fit-content] rounded-full text-12px !text-ems-boTro4_600 px-16px py-3px border-1px border-solid border-ems-boTro4_600"
            >
              {{ t('object_tracing.view-event') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, defineEmits, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Empty from '@/components/empty/index.vue';
import dayjs from "dayjs";

const props = defineProps({
  isLoading: {
    default: false,
  },
  showSearch: {
    default: true,
  },
});
const emits = defineEmits(['selectItem', 'on-search-data']);
const { t } = useI18n();
const { state, dispatch } = useStore();
const route = useRoute();
const locationData = computed(() => state.tracingObject.locationData);
const currentData = ref(null);
const formState = reactive({
  dateFrom: dayjs().subtract(30, 'day'),
  dateTo: dayjs(),
});
onMounted(async () => {});
const selectItem = (idx, item) => {
  currentData.value = idx;
  emits('selectItem', item);
};
watch(
  () => formState,
  (val) => {
    emits('on-search-data', { id: route.query.id, params: val });
  },
  {
    deep: true,
  }
);
defineExpose({
  selectItem,
});
</script>
<style lang="scss">
.travel-route .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.travel-route .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.travel-route .from-to-date .ant-row .ant-picker {
  border-radius: 0 !important;
  height: 34px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-style: none;
}
</style>
