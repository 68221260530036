<template>
  <cds-main class="pt-3">
    <cds-cards class="user-group">
      <template v-slot:title>
        <a-row :gutter="20" class="pl-2">
          <a-col :span="22" class="text-center">
            <cds-heading as="h4">{{ $t('user_group.group_tree') }}</cds-heading>
          </a-col>
          <a-col :span="2" class="text-right">
            <a-tooltip :title="$t('common.new')" v-if="!group">
              <PlusCircleFilled :style="{'font-size': '1.7rem', color: primaryColor}" @click="preCreate"></PlusCircleFilled>
            </a-tooltip>
            <a-tooltip :title="$t('common.close')" v-else>
              <CloseCircleFilled :style="{'font-size': '1.7rem', color: primaryColor}" @click="resetForm"></CloseCircleFilled>
            </a-tooltip>
          </a-col>
        </a-row>
      </template>
      <a-row :gutter="20">
        <a-col :xxl="8" :xl="8" :lg="8" :md="8" :xs="24">
          <cds-cards :title="$t('user_group.group_tree')">
            <cds-tree :show-line="{showLeafIcon: false}" :tree-data="tree" show-icon :field-names="fieldNames" :checkable="false" @select="selectGroup" v-model:selected="treeValue">
              <template #custom-icon>
                <cds-feather-icons :type="'users'"/>
              </template>
              <template #context-menu="{tree}" >
                <a-menu @click="contextMenuClick($event.key, tree.id)">
                  <a-menu-item key="1"><edit-outlined class="icon-context"/> {{ $t('common.update') }}</a-menu-item>
                  <a-menu-item key="2"><delete-outlined class="icon-context"/> {{ $t('common.delete') }}</a-menu-item>
                  <a-menu-item key="3"><plus-circle-outlined class="icon-context"/> {{ $t('user_group.add_to_parent') }}</a-menu-item>
                  <a-menu-item key="3"><plus-circle-outlined class="icon-context"/> {{ $t('user_group.add_subgroup') }}</a-menu-item>
                </a-menu>
              </template>
            </cds-tree>
          </cds-cards>
        </a-col>


        <a-col :xxl="16" :xl="16" :lg="16" :md="16" :xs="24">
          <a-spin :spinning="loading">
            <cds-cards v-if="group">
              <cds-tabs v-if="formMode === FORM_MODE.UPDATE" v-model:active="activeTab">
                <a-tab-pane key="1">
                  <template #tab>
                    <div class="inline-block">
                      <InfoCircleFilled class="tab-icon" style="font-size: 1.5rem"/>
                    </div>
                    <div class="inline-block">{{ $t('user_group.information') }}</div>
                  </template>
                  <GroupInfo @cancel="resetForm"></GroupInfo>
                </a-tab-pane>
                <a-tab-pane key="2">
                  <template #tab>
                    <div class="inline-block">
                      <FileSearchOutlined class="tab-icon" style="font-size: 1.5rem"/>
                    </div>
                    <div class="inline-block">{{ $t('user_group.list') }}</div>
                  </template>
                  <List></List>
                </a-tab-pane>
                <a-tab-pane key="3">
                  <template #tab>
                    <div class="inline-block">
                      <LockOutlined class="tab-icon" style="font-size: 1.5rem"/>
                    </div>
                    <div class="inline-block">{{ $t('user_group.permission') }}</div>
                  </template>
                  <Permission @cancel="resetForm"></Permission>
                </a-tab-pane>
              </cds-tabs>
              <CreateGroup v-else @cancel="resetForm"></CreateGroup>
            </cds-cards>
          </a-spin>
        </a-col>
      </a-row>
    </cds-cards>
  </cds-main>
</template>

<script src="./user-group-controller.js"/>

<style scoped>

.user-group {
  min-height: 87vh;
}
.tab-icon {
  transform: translateY(5px);
}

.icon-context {
  font-size: 1.2rem;
  margin: 0.4rem;
  transform: translateY(-3px);
}

</style>
