<template>
  <cds-main class="report-statistical">
    <!-- Filters components -->
    <div
      class="py-21px pr-15px bg-ems-gray900 border-b border-b-1 border-b-solid border-b-ems-gray700 flex justify-end gap-2.5"
    >
      <a-col :xxl="2" :xl="2">
        <clip-button
          type="primary"
          @click="handleRefresh"
          class="w-full flex items-center justify-center gap-1 mr-[16px] whitespace-nowrap"
          >{{ t('common.refresh') }}</clip-button
        >
      </a-col>
      <a-col :xxl="2" :xl="3">
        <a-date-picker
          :format="monthFormat"
          picker="month"
          placeholder="Tháng: "
          class="!border-[#1d1d1d] w-full"
          v-model:value="searchParams.date"
          @change="handleDateChange"
        />
      </a-col>
      <a-col :xxl="2" :xl="3">
        <cds-select
          :options="listGroupCamera"
          v-model:value="searchParams.groupId"
        />
      </a-col>
      <a-col :xxl="2" :xl="3">
        <cds-select
          :options="timeSelectFilter"
          v-model:value="searchParams.type"
        />
      </a-col>
      <a-col :xxl="2" :xl="3">
        <a-time-picker
          class="!border-[#1d1d1d] w-full"
          v-model:value="searchParams.timeFrom"
          @change="handleTimeChangeFrom"
        />
      </a-col>
      <a-col :xxl="2" :xl="3">
        <a-time-picker
          class="!border-[#1d1d1d] w-full"
          v-model:value="searchParams.timeTo"
          @change="handleTimeChangeTo"
        />
      </a-col>
    </div>
    <!-- Main content -->
    <div class="report-content py-16px container">
      <!-- Chart -->
      <div class="flex gap-16px">
        <!-- Bar chart -->
        <a-col :span="12" class="bg-ems-gray800">
          <OcurrenciesChart />
        </a-col>
        <!-- Line chart -->
        <a-col :span="12" class="bg-ems-gray800">
          <IntrusionChart />
        </a-col>
      </div>
      <!-- Table -->
      <div class="flex gap-16px mt-12px">
        <!-- Table 1 -->
        <a-col :span="12" class="bg-ems-gray800">
          <!-- Header -->
          <div
            class="flex justify-start items-center p-20px border-b border-b-1 border-b-solid border-b-ems-gray700"
          >
            <h3 class="uppercase text-white font-semibold">
              {{ t('report_statistical.title_table_1') }}
            </h3>
          </div>
          <!-- Table -->
          <!-- :scroll="{ y: 500 }" -->
          <FrequencyTable />
        </a-col>

        <!-- Table 2 -->
        <a-col :span="12" class="bg-ems-gray800">
          <!-- Header -->
          <div
            class="flex justify-start items-center p-20px border-b border-b-1 border-b-solid border-b-ems-gray700"
          >
            <h3 class="uppercase text-white font-semibold">
              {{ t('report_statistical.title_table_2') }}
            </h3>
          </div>
          <!-- Table -->
          <NonCheckoutTable />
        </a-col>
      </div>
    </div>
  </cds-main>
</template>
<script setup>
import dayjs from 'dayjs';
import { computed, onMounted, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { TIME_SELECT } from '@/util/common-constant';
import ClipButton from '@/components/buttons/ClipButton.vue';
import NonCheckoutTable from './components/NonCheckoutTable.vue';
import FrequencyTable from './components/FrequencyTable.vue';
import IntrusionChart from './components/IntrusionChart.vue';
import OcurrenciesChart from './components/OcurrenciesChart.vue';

const { t } = useI18n();
const { state, dispatch } = useStore();

// filters
const searchParams = reactive({
  date: dayjs(),
  groupId: '',
  type: 'D',
  timeFrom: dayjs('00:00:00', 'HH:mm:ss'),
  timeTo: dayjs('23:59:59', 'HH:mm:ss'),
});
const monthFormat = 'MM/YYYY';

// options
const timeSelectFilter = TIME_SELECT.filter((e) => e.value !== 'Y');

// methods
const onFilters = async () => {
  await dispatch('reportStatistical/getFrequencies', mapParams());
  await dispatch('reportStatistical/getNumberOfOccurrences', mapParams());
  await dispatch('reportStatistical/getNonCheckout', mapParams());
  await dispatch('reportStatistical/getNumberOfIntrusions', mapParams());
};

const handleDateChange = (date) => {
  if (!date) {
    searchParams.date = dayjs();
  }
};

const handleTimeChangeFrom = (time) => {
  if (!time) {
    searchParams.timeFrom = dayjs('00:00:00', 'HH:mm:ss');
  }
};

const handleTimeChangeTo = (time) => {
  if (!time) {
    searchParams.timeTo = dayjs('00:00:00', 'HH:mm:ss');
  }
};

const handleRefresh = () => {
  searchParams.date = dayjs();
  searchParams.groupId = '';
  searchParams.type = 'D';
  searchParams.timeFrom = dayjs('00:00:00', 'HH:mm:ss');
  searchParams.timeTo = dayjs('23:59:59', 'HH:mm:ss');
};

const mapParams = () => {
  return {
    date: dayjs(searchParams.date).format('YYYY-MM-DD HH:mm:ss'),
    group_id: searchParams.groupId,
    type: searchParams.type,
    time_from: `${dayjs(searchParams.date).format('YYYY-MM-DD')} ${dayjs(
      searchParams.timeFrom
    ).format('HH:mm:ss')}`,
    time_to: `${dayjs(searchParams.date).format('YYYY-MM-DD')} ${dayjs(
      searchParams.timeTo
    ).format('HH:mm:ss')}`,
  };
};

// mounted cycles
onMounted(async () => {
  dispatch('setTitleHeader', t('user_group.dashboard'));
  await dispatch('tracingObject/getListGroupCamera');
  await dispatch('reportStatistical/getFrequencies', mapParams());
  await dispatch('reportStatistical/getNumberOfOccurrences', mapParams());
  await dispatch('reportStatistical/getNonCheckout', mapParams());
  await dispatch('reportStatistical/getNumberOfIntrusions', mapParams());
});

// computed
const listGroupCamera = computed(() => {
  const optionAll = { value: '', label: t('report_statistical.all_camera') };
  const listGroup = state.tracingObject.listGroupCamera.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  return [optionAll, ...listGroup];
});

watch(
  () => [
    searchParams.date,
    searchParams.groupId,
    searchParams.timeFrom,
    searchParams.timeTo,
    searchParams.type,
  ],
  () => onFilters()
);
</script>

<style lang="scss">
.report-statistical .ant-picker {
  height: 39px !important;
  border-radius: 4px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
}
.report-statistical .ant-select .ant-select-selector {
  border-radius: 4px !important;
}
.report-statistical .ant-table-content {
  border-radius: 0;
}
.report-statistical .ant-table-wrapper {
  padding: 13px 8px 6px 16px;
}
.report-statistical .ant-table-wrapper .ant-table {
  border-radius: 0;
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity));
}
.report-statistical .ant-table-wrapper .ant-table .ant-table-container {
  padding-right: 6px;
}
.report-statistical .ant-table-wrapper .ant-table .ant-table-container table {
  --tw-border-opacity: 1;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity));
  border-radius: 0;
}
.report-statistical .ant-table-wrapper .ant-table .ant-table-container table .ant-table-thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: -3px;
}
.report-statistical .ant-table-wrapper .ant-table .ant-table-container table .ant-table-thead .ant-table-cell {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity));
  border-bottom-width: 3px;
  border-radius: 0 !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.report-statistical .ant-table-wrapper .ant-table .ant-table-container table .ant-table-tbody .ant-table-cell {
  --tw-text-opacity: 1;
  color: rgba(157, 157, 157, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity));
  border-bottom-width: 3px;
  text-align: center;
  border-radius: 0 !important;
}
.report-statistical .ant-table-wrapper .ant-table .ant-table-container table .ant-table-tbody .ant-table-cell .img-custom {
  clip-path: polygon(
                    18% 0,
                    100% 0,
                    100% 82%,
                    85% 100%,
                    0 100%,
                    0 17%
                  );
}
.report-statistical .ant-table-wrapper .ant-table .ant-table-container table .ant-table-tbody .ant-table-cell .img-custom img:hover {
  transition: 0.2s;
  transform: scale(1.2);
}
.report-statistical .ant-table-content
    .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
</style>
