<template>
  <div class="user-form">
    <a-form
      @keydown.enter.prevent="onFinish"
      ref="formModal"
      :model="formState"
      layout="vertical"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item :label="$t('common.avatar')" name="avatar">
        <cds-upload
          accept=".jpg,.png,.jpeg"
          v-model:value="avatar"
          root-path="avatar"
          :disabled="readOnly"
        />
      </a-form-item>
      <div class="grid grid-cols-3 gap-3 lg:grid-cols-3 md:grid-cols-1">
        <!--        div-1-->
        <a-form-item :label="$t('user.username')" name="username">
          <cds-input
            class="w-100"
            v-model:value="formState.username"
            :maxlength="64"
            :disabled="formMode !== FORM_MODE.CREATE"
            :placeholder="$t('user.enter_officer_code')"
          />
        </a-form-item>
        <a-form-item :label="$t('user.full_name')" name="fullName">
          <cds-input
            class="w-100"
            v-model:value="formState.fullName"
            :maxlength="50"
            :disabled="readOnly"
            :placeholder="$t('user.full_name_placeholder')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('user_group.system_user_group')"
          name="userRoles"
        >
          <a-select
            v-model:value="formState.userRoles"
            mode="multiple"
            max-tag-count="responsive"
            style="width: 100%"
            :placeholder="$t('user.group')"
            dropdown-class-name="select-modal"
            :options="tree"
            :disabled="readOnly"
            :show-arrow="true"
            :max-tag-text-length="maxTagTextLength"
            :filter-option="filterOption"
            class="text-white"
          >
            <template v-slot:tagRender="{ label, onClose }">
              <a-tag
                :closable="false"
                @close="onClose"
                class="bg-ems-gray500 text-white font-normal flex justify-center border-ems-gray400 relative"
                style="cursor: pointer"
              >
                {{ label }}&nbsp;&nbsp;
                <img
                  v-if="!readOnly"
                  @click="onClose"
                  src="@/static/img/icon/close-icon.svg"
                  class="absolute top-[-3px] right-[-3px]"
                  alt=""
                />
              </a-tag>
            </template>
          </a-select>
          <a-popover placement="top" v-if="formState.userRoles.length > 2">
            <template #content>
              <span
                class="inline-block rounded-[4px] text-ems-gray200 text-sm px-3 bg-ems-gray600 mr-4"
                v-for="(item, idx) in formState.userRoles"
                :key="idx"
              >
                {{ handleFindName(item) }}
              </span>
            </template>
            <span
              class="inline-block rounded-[4px] px-1 bg-ems-gray600 ml-2 absolute top-1 right-[80px] h-[25px] w-[100px] opacity-0"
              v-if="formState.userRoles.length > 0"
            >
            </span>
          </a-popover>
        </a-form-item>
        <!--        div-2-->
        <a-form-item :label="$t('user.subsystem')" name="clientId">
          <cds-select
            dropdown-class-name="select-modal"
            :options="roles"
            :placeholder="$t('user.subsystem_placeholder')"
            label-prop="name"
            value-prop="id"
            :disabled="readOnly"
            v-model:value="formState.clientId"
          />
        </a-form-item>
        <a-form-item
          :label="$t('user.password')"
          name="password"
          v-if="formMode === FORM_MODE.CREATE"
        >
          <a-input-password
            type="password"
            v-model:value="formState.password"
            :placeholder="$t('user.password_placeholder')"
            :maxlength="50"
            class="w-100 ant-input-sm"
            allow-clear
          />
        </a-form-item>
        <a-form-item :label="$t('user.status')" name="status">
          <cds-select
            dropdown-class-name="select-modal"
            :options="status"
            :placeholder="$t('user.status')"
            v-model:value="formState.status"
            :disabled="readOnly"
          />
        </a-form-item>
        <!--        div-3-->
        <a-form-item :label="$t('user.phone')" name="phone">
          <cds-input
            class="w-100"
            v-model:value="formState.phone"
            :maxlength="10"
            :disabled="readOnly"
            :placeholder="$t('user.phone_placeholder')"
          />
        </a-form-item>
        <a-form-item :label="$t('user.position')" name="position">
          <cds-input
            class="w-100"
            v-model:value="formState.position"
            :maxlength="255"
            :disabled="readOnly"
            :placeholder="$t('user.position_placeholder')"
          />
        </a-form-item>
        <a-form-item :label="$t('user.sex')" name="sex">
          <cds-select
            dropdown-class-name="select-modal"
            :options="SEX_VALUE"
            :placeholder="$t('user.sex')"
            v-model:value="formState.gender"
            :disabled="readOnly"
          />
        </a-form-item>
        <a-form-item :label="$t('role-group.email')" name="email">
          <cds-input
            class="w-100"
            v-model:value="formState.email"
            :maxlength="255"
            :disabled="readOnly"
            :placeholder="$t('user.email')"
          />
        </a-form-item>
      </div>
      <div class="flex items-center justify-end gap-3 mt-4">
        <clip-button
          type="grey"
          v-if="formMode === FORM_MODE.UPDATE"
          @click="visible = true"
          >{{ $t('common.resetPassword') }}
        </clip-button>
        <clip-button
          type="primary"
          @click="onFinish"
          v-if="formMode !== FORM_MODE.VIEW"
        >
          {{
            formMode === FORM_MODE.CREATE
              ? $t('common.save')
              : $t('common.update')
          }}
        </clip-button>
      </div>
    </a-form>
  </div>

  <cds-modal
    :title="null"
    :visible="visible"
    :width="460"
    hide-footer
    @onCancel="visible = false"
    :closable="false"
    :centered="true"
  >
    <ModalResetPassword
      ref="modal"
      @on-close="visible = false"
      @reset-password="onResetPassword"
    ></ModalResetPassword>
  </cds-modal>
  <cds-modal
    :visible="isSavePopup"
    :mask-closable="false"
    hide-footer
    @onCancel="isSavePopup = false"
    class="bg-ems-gray800 pb-0"
    :centered="true"
  >
    <ModalConfirm
      @confirm="handleConfirmSave"
      @onClose="isSavePopup = false"
      :title="$t('common.confirm_save')"
    ></ModalConfirm>
  </cds-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRaw, watch } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { FORM_MODE, GENDERS, SEX_VALUE, STATUS } from '@/util/common-constant';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import {
  asyncExistedRule,
  confirmPassword,
  emailRule,
  maxlength,
  // minlength,
  passwordRule,
  patternRuleMessage,
  required,
} from '@/util/validation';
import CdsUpload from '@/components/upload';
import ConstantAPI from '@/config/ConstantAPI';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useForm } from 'ant-design-vue/es/form';
import ModalResetPassword from '@/view/manager/user/ModalResetPassword.vue';
import { useI18n } from 'vue-i18n';
import { SUBSYSTEM } from '@/config/Constant';
import { trim } from '@/util/common-utils';
export default defineComponent({
  components: {
    ModalResetPassword,
    ClipButton,
    'cds-upload': CdsUpload,
    ModalConfirm,
  },
  emits: ['saveSuccess'],
  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const formState = reactive(state.user.user);
    const tree = computed(() => {
      if (!state.role.list || state.role.list.length === 0) {
        return [];
      }
      return state.role.list.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    });
    const formMode = computed(() => state.user.formMode);
    const organizations = computed(() => state.user.organizations);
    const departments = computed(() => state.user.departments);
    const positions = computed(() => state.user.positions);
    const maxTagTextLength = computed(() => {
      return formState.userRoles.length > 1 && 10;
    });
    const roles = SUBSYSTEM;
    const readOnly = computed(() => formMode.value === FORM_MODE.VIEW);
    const { t } = useI18n();
    const rules = computed(() => {
      return formMode.value === FORM_MODE.VIEW
        ? {}
        : {
            username: [
              required('user.username'),
              patternRuleMessage(
                /^[a-zA-Z0-9]+$/,
                t('validate.no_special_characters', {
                  label: t('user.username'),
                }),
                64,
                8
              ),
              asyncExistedRule(
                'user.username',
                ConstantAPI.user.CHECK_EXISTED.url,
                { userId: formState.id }
              ),
            ],
            fullName: [
              required('user.full_name'),
              maxlength(50, 'user.full_name'),
            ],
            phone: [
              patternRuleMessage(
                /^(0|\+84)\d{9,10}$/,
                t('validate.malformed', { label: t('user.phone') })
              ),
              maxlength(10, 'user.phone'),
            ],
            status: [],
            password:
              formMode.value === FORM_MODE.CREATE
                ? [required('user.password'), passwordRule()]
                : [],
            confirmPassword:
              formMode.value === FORM_MODE.CREATE
                ? [required('user.password'), confirmPassword(formState)]
                : [],
            clientId: [required('user.subsystem')],
            email: [
              emailRule(t('role-group.email')),
              maxlength(255, t('role-group.email')),
              asyncExistedRule(
                t('role-group.email'),
                ConstantAPI.user.CHECK_EXISTED.url,
                {
                  userId: formState.id,
                }
              ),
            ],
            avatar: [required('common.avatar')],
            position: [maxlength(255, 'user.position')],
            userRoles: [required('user_group.u_group_tree')],
          };
    });
    const labelCol = {
      lg: 24,
      md: 24,
      xs: 24,
    };
    const wrapperCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const formModal = ref(null);
    const fieldNames = { label: 'name', key: 'value' };
    const onCancel = () => dispatch('user/setVisible', false);
    watch(
      () => formMode,
      (val) => {
        if (val === FORM_MODE.CREATE) {
          rules.value.password = [required('user.password')];
        } else {
          rules.value.password = [];
        }
      }
    );

    const visible = ref(false);
    const isSavePopup = ref(false);
    const { resetFields, validate } = useForm(formState, rules.value);
    const filterOption = (input, option) => {
      return (
        option.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            input
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) >= 0
      );
    };
    const onFinish = () => {
      validate().then(
        () => {
          if (avatar.value.length > 0) {
            isSavePopup.value = true;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    };
    const handleConfirmSave = async () => {
      formState.fullName = trim(formState.fullName);
      formState.position = trim(formState.position);
      const data = _.cloneDeep(toRaw(formState));
      dispatch(
        formMode.value === FORM_MODE.CREATE ? 'user/create' : 'user/update',
        data
      ).then(() => {
        isSavePopup.value = false;
        emit('saveSuccess');
      });
    };

    const avatar = computed({
      get() {
        return formState.avatar ? [formState.avatar] : [];
      },
      set(avatars) {
        (avatars || []).length > 0
          ? (formState.avatar = avatars[0])
          : (formState.avatar = null);
      },
    });

    const onResetPassword = () => {
      const data = _.cloneDeep(toRaw(formState));
      dispatch('user/resetDefaultPassword', data.id).then(
        (visible.value = false)
      );
    };

    const handleFindName = (id) => {
      const foundItem = tree.value.find((item) => item.value === id);
      if (foundItem) {
        return foundItem.label;
      }
      return null;
    };
    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      fieldNames,
      formModal,
      onFinish,
      onCancel,
      roles,
      status: STATUS,
      genders: GENDERS,
      formMode,
      FORM_MODE: FORM_MODE,
      avatar,
      tree,
      organizations,
      departments,
      positions,
      resetFields,
      visible,
      onResetPassword,
      SEX_VALUE,
      readOnly,
      handleConfirmSave,
      isSavePopup,
      filterOption,
      handleFindName,
      maxTagTextLength,
    };
  },
});
</script>

<style lang="scss">

.user-form {
  .ant-tag {
    width: max-content;
  }
  .ant-select-selection-search {
    width: 100%;
  }
  .ant-select-selector {
    min-height: 39px !important;
    padding: 0px;
    position: relative;
  }
  .ant-select-selection-overflow {
    min-height: 39px;
    padding-left: 6px;
    top: 6px;
  }
  .ant-select-selection-search {
    height: 39px !important;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .author-info {
    position: relative;

    .btn-upload {
      position: absolute;
      bottom: -10px;
      left: 60px;
      border: 1px solid white;
    }
  }

  :deep .ant-upload {
    width: 186px;
    height: 186px;
  }

  :deep .ant-upload-list-picture-card-container {
    width: 186px;
    height: 186px;
  }
}

</style>
