<template>
  <div class="relative select-filter-scroll">
    <div
      v-if="hasIcon"
      class="absolute z-100 h-full w-30px flex justify-end items-center"
    >
      <slot></slot>
    </div>
    <a-select
      v-model:value="valueSelect"
      :mode="mode"
      style="width: 100%"
      class="select-filter"
      :class="hasIcon ? 'select-icon' : ''"
      :placeholder="placeholder"
      :maxTagCount="maxTagCount"
      :max-tag-text-length="maxTagTextLength"
      :options="listOption"
      :allowClear="allowClear"
      :filter-option="filterOption"
      size="medium"
      @change="change"
    >
    </a-select>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue';
import VueTypes from 'vue-types';
import { getText } from '@/util/common-utils';
import _ from 'lodash';
import { i18n } from '@/main';

export default defineComponent({
  name: 'Select',
  props: {
    options: VueTypes.array.def([]),
    labelProp: VueTypes.string.def('label'),
    valueProp: VueTypes.string.def('value'),
    maxTagCount: VueTypes.number.def(3),
    maxTagTextLength: VueTypes.number.def(30),
    multiple: VueTypes.bool.def(false),
    placeholder: VueTypes.string,
    allowClear: VueTypes.bool.def(false),
    value: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.number,
      VueTypes.array,
    ]),
    isAll: VueTypes.bool.def(false),
    hasIcon: VueTypes.bool.def(false),
    isI18n: VueTypes.bool.def(true),
  },
  setup(props, { emit }) {
    const { t } = i18n.global;
    const listOption = computed(() => {
      const data = _.cloneDeep(props.options);
      if (props.isAll) {
        data.unshift({
          value: null,
          label: 'All',
        });
      }
      return data.map((e) => ({
        label: props.isI18n ? t(e[props.labelProp]) : e[props.labelProp],
        value: e[props.valueProp],
        disabled: e.disabled,
      }));
    });
    const valueSelect = ref(props.value);
    const change = (val) => {
      emit('update:value', val);
      emit('change');
    };
    const mode = computed(() => (props.multiple ? 'multiple' : null));
    watch(
      () => props.value,
      (val) => (valueSelect.value = val)
    );
    const getTextLabel = computed(
      () => (value) => getText(value, listOption.value)
    );
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      valueSelect,
      listOption,
      change,
      mode,
      getTextLabel,
      filterOption,
    };
  },
});
</script>
<style>
.select-filter {
  height: 39px;
}
.select-filter .ant-select-selector {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity)) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 55, 55, var(--tw-border-opacity)) !important;
  border-width: 1px !important;
  border-radius: 8px !important;
}
.select-icon .ant-select-selector {
  padding-left: 33px !important;
}
.select-filter .ant-select-selector:hover {
  border: solid 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity)) !important;
}
.select-filter .ant-select-selector .ant-select-selection-search {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
}
.select-filter
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 100% !important;
}
.select-filter.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: solid 1px !important;
  box-shadow: none;
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity)) !important;
}
.select-filter
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 39px !important;
  padding-left: 6px !important;
}
.select-filter .ant-select-arrow, .ant-select-selection-item, .ant-select-dropdown .ant-empty-description {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.select-filter .ant-select-item-option-content {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
}
.select-filter .ant-select-dropdown {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}
.select-filter
  .ant-select-dropdown
  .ant-select-item.ant-select-item-option-selected {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.select-filter
  .ant-select-dropdown
  .ant-select-item.ant-select-item-option-selected
  .ant-select-item-option-content {
  padding-left: 0;
}
.select-filter
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgba(123, 123, 123, var(--tw-text-opacity)) !important;
  position: relative !important;
  line-height: 39px !important;
  padding-left: 6px !important;
}
.select-icon .ant-select .ant-select-selector {
  padding-left: calc(3rem - 8px);
}
.select-filter.ant-select-multiple {
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 55, 55, var(--tw-border-opacity)) !important;
  border-width: 1px !important;
  border-radius: 8px !important;
}
.select-filter.ant-select-multiple .ant-select-selection-overflow {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.select-filter.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: solid 1px transparent !important;
}
.select-filter.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border: solid 1px #D9D9D9 !important;
}
.select-filter.ant-select-multiple .ant-select-selection-overflow::-webkit-scrollbar {
  height: 5px !important;
}
.select-filter .ant-select-selection-search input {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
}
</style>
