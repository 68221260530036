<template>
  <div class="px-20px py-18px">
    <div class="flex justify-between items-center">
      <div class="text-ems-gray200 text-16px font-semibold uppercase">
        {{ t('object_information.violate_info') }}
      </div>
      <div class="flex items-center gap-16px">
        <div class="w-240px">
          <InputFilter
            v-model:value="formState.nhanDang"
            :show-count="false"
            :maxlength="100"
            :placeholder="t('common.search')"
          >
            <template #prefix>
              <img
                src="../../../static/img/icon/IconSearch.svg"
                alt="IconSearch"
              />
            </template>
          </InputFilter>
        </div>
        <a-button
          class="bg-ems-main2 border-none rounded-8px cursor-pointer hover:!bg-ems-main2 hover:opacity-80 !w-[36px] !h-[36px] p-0 focus:bg-ems-main2 disabled:bg-ems-main2 disabled:hover:bg-ems-main2"
          @click="addRow"
          :disabled="formMode === FORM_MODE.VIEW"
        >
          <cds-feather-icons type="plus" size="24" class="text-ems-white" />
        </a-button>
      </div>
    </div>
    <div class="h-1px w-full bg-ems-gray700 mt-17px mb-26px"></div>
    <EditTable
      ref="editTable"
      :columns="columns"
      :data="formState.violation"
      @delete-row="deleteRow"
      @done-edit-row="doneEditRow"
      @revertRow="revertRow"
      class="max-h-[490px] overflow-y-auto"
      :disabled-action="disabled"
      :searchKey="formState.nhanDang"
    ></EditTable>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { reactive, ref, computed } from 'vue';
import InputFilter from '@/components/input-filter/index.vue';
import EditTable from '@/components/edit-table/index.vue';
import { required } from '@/util/validation';
import { FORM_MODE } from '@/util/common-constant';
import _ from 'lodash';

const { t } = useI18n();
const { state, dispatch } = useStore();
const formState = reactive(state.ioManagement.formState);
const formMode = computed(() => state.ioManagement.formMode);
const editTable = ref(null);
const addRow = () => {
  formState.violation.unshift({
    stt: formState.violation.length + 1,
    id: Date.now(),
    status: 'new',
    charge: '',
    decisionNoDate: '',
    decisionUnit: '',
  });
  editTable.value.editRow(formState.violation[0].id);
};
const deleteRow = (id) => {
  formState.violation.splice(
    formState.violation.findIndex((item) => item.id === id),
    1
  );
};
const revertRow = (data) => {
  let i = formState.violation.findIndex((item) => item.id === data.id);
  if (i > -1) {
    formState.violation[i].charge = data.data.charge;
    formState.violation[i].decisionNoDate = data.data.decisionNoDate;
    formState.violation[i].decisionUnit = data.data.decisionUnit;
  }
};
const doneEditRow = (data) => {
  let i = formState.violation.findIndex((item) => item.id === data.id);
  if (i > -1) {
    formState.violation[i].charge = data.charge;
    formState.violation[i].decisionNoDate = data.decisionNoDate;
    formState.violation[i].decisionUnit = data.decisionUnit;
  }
};

const columns = [
  {
    title: t('object_information.crime'),
    dataIndex: 'charge',
    resizable: true,
    width: 300,
    type: 'input',
    rules: [required('object_information.crime')],
    formParentName: ['violation'],
  },
  {
    title: t('object_information.date_decided'),
    sortDirections: ['descend', 'ascend'],
    resizable: true,
    sortable: true,
    dataIndex: 'decisionNoDate',
    align: 'center',
    width: 160,
    type: 'input',
    rules: [required('object_information.date_decided')],
    formParentName: ['violation'],
  },
  {
    title: t('object_information.organization_decided'),
    resizable: true,
    sortable: true,
    dataIndex: 'decisionUnit',
    align: 'center',
    width: 160,
    type: 'input',
    rules: [required('object_information.organization_decided')],
    formParentName: ['violation'],
  },
  {
    title: t('common.action'),
    key: 'action',
    width: 90,
    fixed: 'right',
  },
];
defineProps({
  disabled: { default: false },
});
</script>
<style>

</style>
