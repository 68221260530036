<template>
  <div
    class="w-100 mb-10 icon-custom h-[600px] overflow-x-hidden overflow-y-auto"
  >
    <i
      v-for="icon in emsIcons"
      :key="icon"
      @click="chooseIcon(icon)"
      class="ems-icon"
    >
      <InlineSvg
        :src="`/assets/ems/${icon}.svg`"
        :alt="icon"
        height="16"
        width="16"
        class="inline-block fill-current hover:text-ems-main2"
        :class="(chose === icon ? 'text-ems-main2 ' : 'text-ems-white ') + icon"
      />
    </i>
    <div class="mt-12px"></div>
    <cds-feather-icons
      :type="icon.name"
      v-for="icon in icons"
      :key="icon.name"
      @click="chooseIcon(icon.name)"
      size="14"
      :class="(chose !== icon.name ? 'icon ' : 'active-icon ') + icon.name"
    />
  </div>

  <hr class="footer" />

  <div class="w-100 text-right">
    <cds-button
      class="mr-20"
      key="back"
      type="light"
      size="small"
      @click="onCancel"
    >
      <cds-feather-icons class="mr-10" type="x" />
      {{ t('menu.cancel') }}
    </cds-button>
    <cds-button
      key="submit"
      type="primary"
      size="small"
      type-html="submit"
      @click="saveIcon"
    >
      <cds-feather-icons class="mr-10" type="check" />
      {{ t('menu.choose') }}
    </cds-button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import * as Icons from 'feather-icons';
import { useI18n } from 'vue-i18n';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  components: {
    InlineSvg,
  },
  setup() {
    const { t } = useI18n();
    const { state, dispatch } = useStore();
    const icons = Icons.icons;
    const onCancel = () => dispatch('menu/setVisibleIcon', false);
    const emsIcons = [
      'ems-combat',
      'ems-coordinate',
      'ems-coordination-channel',
      'ems-coordination-management',
      'ems-device',
      'ems-forecast',
      'ems-group-management',
      'ems-identification',
      'ems-integrate',
      'ems-io-info',
      'ems-io-management',
      'ems-list',
      'ems-live',
      'ems-log',
      'ems-map',
      'ems-menu',
      'ems-profile-group',
      'ems-profile-management',
      'ems-profile',
      'ems-report-statistic',
      'ems-setting',
      'ems-statistic',
      'ems-synthetic',
      'ems-system',
      'ems-trace',
      'ems-tracing-group-io',
      'ems-tracing-io-group',
      'ems-tracing-object',
      'ems-user-management',
      'ems-user',
      'ems-vehicle',
      'ems-warn-management',
      'ems-warning-config',
      'ems-warning-coordination',
      'ems-warning',
    ];
    let chose = null;
    return {
      onCancel,
      chose,
      icons,
      state,
      dispatch,
      t,
      emsIcons,
    };
  },
  methods: {
    chooseIcon(data) {
      this.chose = data;
      this.$forceUpdate();
    },
    saveIcon() {
      if (this.chose) {
        this.state.menu.menu.icon = this.chose;
        this.onCancel();
      }
    },
  },
});
</script>

<style lang="scss">

.icon-custom {
  .icon {
    padding: 0.2rem;
    margin: 1rem;
    cursor: pointer;
  }
  .ems-icon {
    padding: 0.2rem;
    margin: 15px;
    cursor: pointer;
  }

  .icon:hover {
    svg.feather {
      color: #e21a3d;
    }
  }

  .active-icon {
    padding: 0.2rem;
    margin: 1rem;
    svg.feather {
      color: rgba(226, 26, 61, 1);
    }
  }

  svg.feather {
    color: white;
  }
}

</style>
