<template>
  <button
      :type="type"
      :class="`${buttonClass} flex items-center justify-center`"
      :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>
<script setup>
import VueTypes from 'vue-types';
import { computed, defineProps } from 'vue';
const props = defineProps({
  type: VueTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'link',
    'dark',
    'light',
    'white',
    'dashed',
    'error',
    'default',
  ]).def('default'),
  disabled: VueTypes.bool.def(false),
  size: VueTypes.oneOf(['large', 'default', 'small']).def('small'),
});
const buttonClass = computed(() => {
  const baseClass = 'px-4 outline-none border-none rounded max-w-[132px] max-h-[26px] text-sm';
  const typeClass =
      {
        primary: 'bg-ems-main1 text-white-a500 ',
        secondary: 'bg-[#FF69A5] text-white-a500',
        success: 'bg-ems-boTro4_1000 text-white-a500',
        info: 'bg-[#2C99FF] text-white-a500',
        warning: 'bg-[#FA8B0C] text-white-a500',
        link: 'bg-[#1890ff] text-white-a500',
        danger: 'bg-[#FF4D4F] text-white-a500',
        dark: 'bg-[#272B41] text-white-a500',
        light: 'bg-[#9299B8] text-white-a500',
        white: 'bg-white-a500 text-[#5A5F7D]',
        dashed: 'bg-[#5A5F7D] text-white-a500',
        error: 'bg-[#f5222d] text-white-a500',
        default: 'bg-white-a500 text-[#5A5F7D]',
      }[props.type] || 'bg-white-a500 text-[#5A5F7D]';

  const sizeClass =
      {
        small: 'py-[8px]',
        default: 'py-[11px]',
        large: 'py-[14px]',
      }[props.size] || 'py-[8px]';
  const disabledClass = props.disabled
      ? 'opacity-75 cursor-not-allowed'
      : 'hover:bg-opacity-80';
  return `${baseClass} ${typeClass} ${sizeClass} ${disabledClass} `;
});
</script>
<style lang="scss">


</style>
