<template>
  <cds-main class="!pt-10 list-device overflow-hidden">
    <div class="mb-3 ! mr-6 ml-4 flex justify-between items-center gap-20">
      <h1
        class="text-base text-white font-semibold uppercase whitespace-nowrap"
      >
        {{ t('channel.header') }}
      </h1>

      <div class="flex items-center flex-wrap gap-x-[32px] gap-y-3">
        <a-input
          size="large"
          :placeholder="t('group-management.placeholder-input-search')"
          v-model:value="formSearch.q"
          class="w-[250px] ant-input-sm rounded-[8px] bg-ems-tag1 border-none"
          @press-enter="onSearch"
        >
          <template #prefix>
            <img
              src="@/static/img/group-management/input-search.svg"
              alt="input-suffix"
              height="16"
              width="16"
            />
          </template>
        </a-input>

        <div class="flex items-center gap-4">
          <ClipButton
            v-if="checkHasPermission(perms, CHANNEL_PERM.DELETE)"
            type="primary"
            size="small"
            style="height: 40px"
            class="w-[120px]"
            @click="onDelete"
          >
            <cds-feather-icons
              type="trash-2"
              size="18"
              class="text-ems-white mr-2"
            />
            <span class="text-sm text-ems-white">{{
              $t('common.delete')
            }}</span>
          </ClipButton>
          <ClipButton
            v-if="checkHasPermission(perms, CHANNEL_PERM.CREATE)"
            type="primary"
            size="small"
            style="height: 40px"
            class="w-[120px]"
            @click="createGroup"
          >
            <cds-feather-icons
              type="plus"
              size="18"
              class="text-ems-white mr-2"
            />
            <span class="text-sm text-ems-white">{{ $t('common.add') }}</span>
          </ClipButton>
        </div>
      </div>
    </div>

    <div style="height: calc(100vh - 60px - 80px - 16px)">
      <cds-table
        ref="table"
        :has-checkbox="true"
        :index-column="true"
        :pagination="true"
        :columns="columns"
        :api-fetch="apiFetch"
        :param-fetch="paramFetch"
        key-field="channelId"
      >
        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'action' && record">
            <ActionButton
              :buttons="buttonActions"
              @onEdit="onEdit(record)"
              @onView="onView(record)"
            />
          </template>

          <template v-if="column.key === 'active' && record">
            <tag
              tag-type="colorful"
              :color="!record.active ? '#B5122E' : '#006400'"
              :text="
                !record.active ? t('channel.inactive') : t('channel.active')
              "
            ></tag>
          </template>
          <template v-if="column.key === 'participantCount' && record">
            <p>{{ record.participantCount }} {{ t('channel.person') }}</p>
          </template>
        </template>
      </cds-table>
    </div>
    <div
      class="absolute top-[53%] right-0 z-60 icon-expand-drawer cursor-pointer"
      @click="openDrawer = true"
      v-if="isSave"
    />
    <drawer-custom
      :visible="openDrawer"
      :width="450"
      @onClose="onClose"
      @onCancel="openDrawer = false"
      :component="FormModal"
      @onSearchAdvanced="onSaveChannel"
      @saveSuccesss="onSaveChannel"
      :title="title"
      @onHide="onHide"
    />
    <cds-modal
      :visible="isPopupDelete"
      :mask-closable="false"
      hide-footer
      @onCancel="isPopupDelete = false"
      class="bg-ems-gray800 pb-0"
    >
      <ModalConfirm
        ref="modal"
        @confirm="handleConfirm"
        @onClose="isPopupDelete = false"
        :title="t('common.confirm_delete')"
      ></ModalConfirm>
    </cds-modal>
  </cds-main>
</template>

<script setup>
import {
  onMounted,
  reactive,
  ref,
  toRaw,
  defineAsyncComponent,
  computed,
  watch,
} from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import { useForm } from 'ant-design-vue/es/form';
import { useStore } from 'vuex';
import { maxlength } from '@/util/validation';
import _ from 'lodash';
import ClipButton from '@/components/buttons/ClipButton.vue';
import Tag from '@/components/tags/Tag';
import { useI18n } from 'vue-i18n';
import ActionButton from '@/components/action-button';
import { ACTION } from '@/config/Constant.js';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import { debounce } from 'lodash';
import { checkHasPermission } from '@/util/common-utils';
import { CHANNEL_PERM } from '@/util/common-constant';
const apiFetch = ConstantAPI.channel.SEARCH;
const { t } = useI18n();
const isPopupDelete = ref(false);
const DrawerCustom = defineAsyncComponent(() =>
  import('@/components/drawer-custom/index.vue')
);
const FormModal = defineAsyncComponent(() =>
  import('./components/FormModal.vue')
);
const columns = ref([
  {
    title: t('channel.channel'),
    dataIndex: 'name',
    key: 'name',
    resizable: true,
    width: 250,
  },
  {
    title: t('channel.desc'),
    dataIndex: 'description',
    resizable: true,
    key: 'description',
    width: 150,
  },
  {
    title: t('channel.member'),
    dataIndex: 'participantCount',
    resizable: true,
    key: 'participantCount',
    width: 150,
  },
  {
    title: t('channel.status'),
    dataIndex: 'active',
    resizable: true,
    key: 'active',
    width: 150,
  },
  {
    title: t('channel.action'),
    dataIndex: 'action',
    key: 'action',
    width: 110,
    align: 'center',
    fixed: 'right',
  },
]);
const buttonActions = [
  {
    permission: 'channel:edit',
    type: 'EDIT',
  },
  {
    type: 'VIEW',
  },
];
const { state, dispatch } = useStore();
const perms = computed(() => state.auth.userInfo.permissions);
const action = computed(() => state.channelManagement.action);
const title = computed(() => {
  return action.value === ACTION.VIEW
    ? t('channel.view')
    : action.value === ACTION.EDIT
    ? t('channel.edit')
    : t('channel.create');
});
const openDrawer = ref(false);
const isSave = ref(false);
const table = ref(null);

onMounted(() => {
  dispatch('setTitleHeader', t('channel.header'));
  dispatch('channelManagement/getListGroup');
  dispatch('channelManagement/getListUserByGroup');
});
const formSearch = reactive({
  q: '',
  allowInactive:false
});
const rules = ref({
  q: [maxlength(255, 'Key')],
});
const { validate } = useForm(formSearch, rules);
let paramFetch = _.cloneDeep(toRaw(formSearch));
const setParamFetch = () => {
  paramFetch.q = formSearch.q;
};
const delayedFetchData = debounce(() => {
  table.value.page = 1;
  setParamFetch();
  table.value.fetchData().then();
}, 500);

watch(
  () => formSearch.q,
  () => {
    delayedFetchData();
  }
);

const onHide = () => {
  openDrawer.value = false;
  isSave.value = true;
};
const createGroup = () => {
  dispatch('channelManagement/preCreate').then(() => {
    openDrawer.value = true;
  });
};
const onSearch = () => {
  validate().then(
    () => {
      setParamFetch();
      table.value.fetchData().then();
    },
    () => {}
  );
};
const onEdit = (row) => {
  dispatch('channelManagement/preUpdate', row).then(() => {});
  openDrawer.value = true;
};
const onView = (row) => {
  dispatch('channelManagement/preView', row).then();
  openDrawer.value = true;
};
const onDelete = () => {
  if (table.value.selectedRows.length > 0) {
    isPopupDelete.value = true;
  }
};
const onClose = (isSaveData) => {
  if (!isSaveData) {
    dispatch('channelManagement/preCreate').then();
  }
  openDrawer.value = false;
  isSave.value = isSaveData;
};
const onSaveChannel = () => {
  openDrawer.value = false;
  table.value.resetAndFetch(true);
};
const handleConfirm = async () => {
  let arrID = table.value.selectedRows.map((item) => item.channelId);
  dispatch('channelManagement/delete', {
    channelIds: arrID,
  }).then(() => {
    isPopupDelete.value = false;
    table.value.resetAndFetch(true);
  });
};
</script>

<style lang="scss">
.list-device .ant-input-sm input.ant-input {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.list-device .ant-input-sm input.ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.list-device .icon-expand-drawer {
  background-image: url('../../static/img/icon/open-drawer.svg');
  background-size: cover;
  height: 67px;
  width: 19px;
}
.list-device .icon-expand-drawer:hover {
  background-image: url('../../static/img/icon/open-drawer-active.svg');
}
.list-device .ant-form-item {
  margin-bottom: 0 !important;
}
.list-device .ant-form-item .ant-picker {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-style: none;
}
.list-device .ant-tag {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
</style>
