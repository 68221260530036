<template>
  <template v-if="readOnly">
    {{ valueInput }}
  </template>
  <a-input
    v-else
    class="w-100 ant-input-filter"
    v-model:value="valueInput"
    :maxlength="maxlength"
    :show-count="showCount"
    :size="size"
    :iconName="iconName"
    :placeholder="placeholder"
    :allowClear="allowClear"
    :disabled="disabled"
    autoComplete="off"
    @input="onInput"
    @blur="onBlur"
    @press-enter="pressEnter"
    @change="onChange"
  >
    <template #prefix>
      <slot name="prefix"></slot>
    </template>
    <template #suffix>
      <slot name="suffix"></slot>
    </template>
  </a-input>
</template>

<script>
import { defineComponent, ref } from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  props: {
    value: VueTypes.string.def(''),
    placeholder: VueTypes.string,
    maxlength: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    showCount: VueTypes.bool.def(false),
    allowClear: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    size: VueTypes.string.def('middle'),
    icon: VueTypes.string,
    iconName: VueTypes.string,
    readOnly: VueTypes.bool.def(false),
  },
  emits: ['press-enter', 'change'],
  setup(props) {
    const valueInput = ref(props.value);
    return {
      valueInput,
    };
  },
  methods: {
    iconClick() {
      this.$emit('iconClick', this.iconName);
    },
    onInput(e) {
      this.valueInput = e.target.value
        ? (e.target.value + '').trimLeft()
        : e.target.value;
      this.$emit('update:value', this.valueInput);
    },
    onBlur() {
      this.$emit(
        'update:value',
        this.valueInput ? (this.valueInput + '').trim() : this.valueInput
      );
    },
    pressEnter(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('press-enter', e);
    },
    onChange() {
      this.$emit('change');
    },
  },
  watch: {
    value(val) {
      this.valueInput = val;
    },
  },
});
</script>

<style>
.ant-input-filter {
  padding: 6px 12px;
  border-radius: 8px;
  border: solid 1px !important;
  line-height: 25px;
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 55, 55, var(--tw-border-opacity)) !important;
}
.ant-input-filter .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.ant-input-filter .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
.ant-input-filter .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border: solid 1px !important;
  box-shadow: none !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 196, 196, var(--tw-border-opacity)) !important;
}
.ant-input-filter .ant-input-show-count-suffix {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.ant-input-filter .anticon.ant-input-clear-icon {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
.ant-input-filter
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper, .ant-input-filter
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 0, 0, var(--tw-border-opacity)) !important;
}
.ant-input-filter
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-filter
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
}
</style>
