<template>
  <div
    ref="chartDom"
    :class="className"
    :style="{
      height: height,
      width: width,
    }"
  />
</template>

<script setup>
import { init } from 'echarts';
import 'echarts/theme/macarons';
import VueTypes from 'vue-types';
import { useStore } from 'vuex';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  shallowRef,
  watch,
} from 'vue';

const { state } = useStore();
const chart = shallowRef();
const chartDom = ref();

const collapsed = computed(() => state.themeLayout.collapsed);

const props = defineProps({
  className: String,
  width: VueTypes.bool.def('100%'),
  height: VueTypes.string.def('300px'),
  option: Object,
});

const handleResize = () => {
  chart.value.resize();
};

watch(
  () => props,
  (val) => {
    if (chart.value) {
      chart.value.setOption(val.option, true);
    }
  },
  { deep: true }
);

watch(
  () => collapsed.value,
  () => {
    setTimeout(() => {
      handleResize();
    }, 200);
  }
);

onMounted(() => {
  window.addEventListener('resize', handleResize);
  nextTick(() => {
    chart.value = init(chartDom.value);
    props.option.textStyle = {
      fontFamily: 'Saira',
      color: 'transparent',
      fontSize: '14px',
    };
    chart.value.setOption(props.option);
  });
});

onBeforeUnmount(() => {
  if (chart.value) {
    window.removeEventListener('resize', handleResize);
    chart.value.dispose();
    chart.value = undefined;
  }
});

defineExpose({ chart, handleResize });
</script>
