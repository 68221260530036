import { computed, onMounted, reactive, ref, toRaw, watch } from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import ActionButton from '@/components/action-button';
import { ModalStyled } from '@/components/modals/styled';
import { useForm } from 'ant-design-vue/es/form';
import { useStore } from 'vuex';
import FormModal from './FormModal';
import { getText, greaterThanCurrent,trim, hasPermEdit } from '@/util/common-utils';
import { maxlength } from '@/util/validation';
import _ from 'lodash';
import { mapFields } from 'vuex-map-fields';
import Tag from '../../../components/tags/Tag';
import {
  defaultButton,
  FORM_MODE,
  STATUS,
  STATUS_VALUE,
} from '@/util/common-constant';
import UserDetail from './UserDetail';
import Table from '../../../components/table/Table';
import InputSearch from '@/components/input-search/index.vue';
import IconMenu from '@/view/system/menu/IconMenu.vue';
import ButtonStatus from '@/components/button-status/index.vue';
import { useI18n } from 'vue-i18n';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { debounce } from 'lodash';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import { notification } from 'ant-design-vue';
const apiFetch = ConstantAPI.user.SEARCH;
export default {
  name: 'Role',
  components: {
    ClipButton,
    IconMenu,
    InputSearch,
    'cds-table': Table,
    ActionButton,
    ModalStyled,
    FormModal,
    Tag,
    UserDetail,
    ButtonStatus,
    ModalConfirm,
  },
  setup() {
    const { t } = useI18n();
    const columns = ref([
      {
        title: t('user.username'),
        dataIndex: 'username',
        key: 'username',
        resizable: true,
        width: 200,
      },
      {
        title: t('user.full_name'),
        dataIndex: 'fullName',
        key: 'fullName',
        resizable: true,
        width: 250,
      },
      {
        title: t('object_information.email'),
        dataIndex: 'email',
        resizable: true,
        key: 'email',
        width: 200,
      },
      {
        title: t('user.phone'),
        dataIndex: 'phone',
        resizable: true,
        key: 'phone',
        width: 200,
      },
      {
        title: t('user_group.u_group_tree'),
        dataIndex: 'rolesName',
        resizable: true,
        key: 'rolesName',
        width: 250,
      },
      {
        title: t('user.status'),
        dataIndex: 'status',
        key: 'status',
        width: 180,
        resizable: true,
      },
      {
        title: t('role-group.action-group'),
        titleCenter: true,
        key: 'action',
        width: 150,
        align: 'center',
        fixed: 'right',
      },
    ]);
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };
    const { buttonsForm, buttonActions } = defaultButton('user');
    const DEBOUNCE_TIME = 500;
    const formState = reactive({
      username: '',
      cpId: null,
      dateFrom: null,
      dateTo: null,
    });
    const rules = ref({
      username: [maxlength(50, 'Username')],
    });
    const { resetFields, validate, validateInfos } = useForm(formState, rules);
    const setParamFetch = () => {
      paramFetch.username =trim( formState.username);
      paramFetch.cpId = formState.cpId;
      paramFetch.dateFrom = formState.dateFrom;
      paramFetch.dateTo = formState.dateTo;
    };
    const delayedFetchData = debounce(() => {
      table.value.page = 1;
      setParamFetch();
      table.value.fetchData().then();
    }, DEBOUNCE_TIME);
    const refresh = () => {
      resetForm();
    };
    watch(
      () => formState.username,
      () => {
        delayedFetchData();
      }
    );

    const resetForm = () => {
      resetFields();
      formState.dateFrom = null;
      formState.dateTo = null;
      formState.cpId = null;
      setParamFetch();
    };
    let paramFetch = _.cloneDeep(toRaw(formState));
    const fieldNames = { label: 'name', key: 'value' };
    const table = ref(null);
    const { state, dispatch } = useStore();
    const perms = computed(() => state.auth.userInfo.permissions);
    const isPopupDelete = ref(false);
    const idDelete = ref();
    const isView = computed(() => state.user.formMode === FORM_MODE.VIEW);
    const loading = computed(() => state.user.loading);
    const visible = ref(false);
    const isShowDelete = ref(false);
    onMounted(() => {
      dispatch('setTitleHeader', t('user.title')).then();
      dispatch('userGroup/getAll').then();
      dispatch('role/getAll').then();
      dispatch('user/loadSuggest').then();
    });
    const disabledDate = greaterThanCurrent();
    const status = computed(() => (value) => t(getText(value, STATUS)));
    const gender = computed(() => (value) => {
      if (value === 1) return { text: 'Male', color: '#006899' };
      if (value === 0) return { text: 'Female', color: '#e7699e' };
      return { text: 'OTHER', color: '#7c7c7c' };
    });
    const permissions = computed(
      () => (state.auth.userInfo || {}).permissions || []
    );
    const hasButton = (permission) => permissions.value.includes(permission);
    const handleSelectCheckbox = (record) => {
      if (record.length > 0) {
        isShowDelete.value = true;
      } else {
        isShowDelete.value = false;
      }
    };
    const onDelete = async (row) => {
      if (isShowDelete.value) {
        idDelete.value = row.id;
        isPopupDelete.value = true;
      } else {
        notification.error({
          message: t('common.notification'),
          description: t('common.no_record_selected'),
          duration: 4,
        });
      }
    };
    const onDeleteRecord = async (row) => {
      idDelete.value = row.id;
      isPopupDelete.value = true;
    };

    const handleConfirm = async () => {
      let id;
      if (idDelete.value) {
        id = idDelete.value;
      } else {
        let listIDRole = table.value.selectedRows.map((item) => item.id);
        id = listIDRole.join(',');
      }
      if (await dispatch('user/delete', id)) {
        isPopupDelete.value = false;
        idDelete.value = '';
        table.value.fetchData();
      }
    };
    return {
      columns,
      apiFetch,
      labelCol,
      wrapperCol,
      formState,
      refresh,
      resetFields,
      resetForm,
      validate,
      validateInfos,
      rules,
      disabledDate,
      dispatch,
      table,
      titleModal: '',
      paramFetch,
      fieldNames,
      status,
      gender,
      loading,
      buttonsForm,
      buttonActions,
      isView,
      hasButton,
      visible,
      t,
      STATUS_VALUE,
      isPopupDelete,
      onDelete,
      handleConfirm,
      handleSelectCheckbox,
      onDeleteRecord,
      perms,
      hasPermEdit
    };
  },
  computed: {
    ...mapFields('user', ['visible']),
  },
  methods: {
    preCreate() {
      this.dispatch('user/preCreate').then(() => {
        this.titleModal = 'user.add_user';
        this.visible = true;
      });
    },
    saveSuccess() {
      this.$refs.table.fetchData();
      this.visible = false;
    },
    onEdit(row) {
      this.titleModal = 'user.update_user';
      this.dispatch('user/preUpdate', row).then(() => (this.visible = true));
    },
    onView(row) {
      this.titleModal = 'user.view_user';
      this.dispatch('user/preView', row).then(() => (this.visible = true));
    },
  },
};
