<template>
  <div class="flex justify-center">
    <div class="w-570px">
      <span class="text-14px font-normal text-ems-gray500">Camera 01</span>
      <div class="w-full h-350px bg-ems-gray700 mt-5px"></div>
    </div>
  </div>
  <div class="h-1px w-full bg-ems-gray700 mt-56px mb-27px"></div>
  <div class="grid grid-cols-3 gap-30px">
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('predict_management.name_detail')"
        :name="['username']"
      >
        <cds-input
          v-model:value="formDetail.username"
          :show-count="false"
          :maxlength="100"
          disabled
          :placeholder="t('predict_management.name_detail_placeholder')"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('predict_management.group_detail')"
        :name="['username']"
      >
        <cds-input
          v-model:value="formDetail.username"
          :show-count="false"
          :maxlength="100"
          disabled
          :placeholder="t('predict_management.group_detail_placeholder')"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('predict_management.status_detail')"
        :name="['username']"
      >
        <cds-select
          v-model:value="formDetail.username"
          disabled
          :placeholder="t('predict_management.status_detail_placeholder')"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('predict_management.camera_detail')"
        :name="['username']"
      >
        <cds-input
          v-model:value="formDetail.username"
          :maxlength="100"
          disabled
          :placeholder="t('predict_management.camera_detail_placeholder')"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('predict_management.priority_detail')"
        :name="['username']"
      >
        <cds-input
          v-model:value="formDetail.username"
          :show-count="false"
          :maxlength="100"
          disabled
          :placeholder="t('predict_management.priority_detail_placeholder')"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('predict_management.time_detail')"
        :name="['username']"
      >
        <cds-date-picker
          v-model:value="formDetail.username"
          placeholder=""
          disabled
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('predict_management.accuracy_detail')"
        :name="['username']"
      >
        <cds-input
          v-model:value="formDetail.username"
          :show-count="false"
          :maxlength="100"
          disabled
          :placeholder="t('predict_management.accuracy_detail_placeholder')"
        />
      </a-form-item>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { defineProps, ref } from 'vue';

const { t } = useI18n();
const props = defineProps({
  detail: {
    type: Object,
    default: () => {},
  },
});
const formDetail = ref(props.detail);
</script>
