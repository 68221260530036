<template>
  <div class="io-select" @click="openModal">
    <Tag tag-type="dynamicTag" color="#108ee9"
         :data="texts"></Tag>
    <CaretUpOutlined class="close-icon" v-if="open"></CaretUpOutlined>
    <CaretDownOutlined class="close-icon" v-else></CaretDownOutlined>
    <cds-modal
        :visible="open"
        width="900px"
        @onCancel="onCancel"
        @onOk="onOk"
    >
      <cds-tabs>
        <a-tab-pane key="1">
          <template #tab>
            <div class="inline-block">
              <TeamOutlined class="tab-icon" style="font-size: 1.5rem"/>
            </div>
            <div class="inline-block">{{ $t('user_group.io_group') }}</div>
          </template>
          <div class="container">
            <cds-tree :tree-data="tree" :field-names="fieldNames" v-model:value="groupVals"/>
          </div>
        </a-tab-pane>

        <a-tab-pane key="2">
          <template #tab>
            <div class="inline-block">
              <UserOutlined class="tab-icon" style="font-size: 1.5rem"/>
            </div>
            <div class="inline-block">{{ $t('user_group.io') }}</div>
          </template>
          <div class="container">
            <a-row>
              <a-col :span="24" v-for="(io, i) in ioSelects" :key="i" class="mb-3">
                <a-row>
                  <a-col :span="24">
                    <a-checkbox v-model:checked="ioVals[io.id]">{{ io.name }}</a-checkbox>
                  </a-col>
                  <template v-if="io.vehicles && io.vehicles.length">
                    <a-col :span="6" class="pl-6">
                      {{ $t('user_group.registered_lp') }}
                    </a-col>

                    <a-col :span="18">
                      <a-checkbox v-for="(vehicle, index) in io.vehicles" :key="index" v-model:checked="ioVals[vehicle.id]">
                        {{ vehicle.licensePlate }}
                      </a-checkbox>
                    </a-col>
                  </template>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </a-tab-pane>

      </cds-tabs>
    </cds-modal>
  </div>
</template>

<script>
import {computed, defineComponent, ref, watch} from "vue";
import Tag from '@/components/tags/Tag.vue';
import {CaretDownOutlined, CaretUpOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons-vue';
import Tabs from "@/components/tabs/Tabs.vue";
import {useStore} from "vuex";
import VueTypes from "vue-types";

export default defineComponent({
  components: {
    'cds-tabs': Tabs,
    TeamOutlined,
    UserOutlined,
    Tag,
    CaretDownOutlined,
    CaretUpOutlined
  },
  props: {
    groups: VueTypes.array,
    ios: VueTypes.object,
  },
  emits: ['update:groups', 'update:ios'],
  setup(props, {emit}) {
    const open = ref(false)
    const {state} = useStore();
    const ioSelects = computed(() => state.person.ioSelects);
    const tree = computed(() => state.userGroup.tree);
    const groups = computed(() => state.userGroup.groups);
    const openModal = () => {
      open.value = !open.value
    }
    const fieldNames = {title: 'name', key: 'id'};
    const ioVals = ref(props.ios)
    const groupVals = ref(props.groups)
    const texts = computed(() => {
      const t = (groupVals.value || []).map(e => {
        const group = groups.value.find(i => e === i.id)
        if (group) return group.name
        return null
      });
      if (t.length < 4) {
        const ios = Object.keys(ioVals.value).filter(e => ioVals.value[e]);
        for (let i = 0; i < ioSelects.value.length; i++) {
          const io = ioSelects.value[i];
          if (ios.includes(io.id)) {
            t.push(io.name);
          }
          if (t.length < 4) {
            const vehicles = io.vehicles || []
            for (let v = 0; v < vehicles.length; v++) {
              const vehicle = vehicles[v]
              if (ios.includes(vehicle.id)) {
                t.push(vehicle.licensePlate);
              }
              if (t.length === 4) {
                break
              }
            }
          }
          if (t.length === 4) {
            break
          }
        }
      }
      return t;
    })
    const onOk = () => {
      emit('update:ios', ioVals.value);
      emit('update:groups', groupVals.value);
      open.value = false;
    }
    watch(() => props.ios, v => ioVals.value = v)
    watch(() => props.groups, v => groupVals.value = v)
    const onCancel = () => {
      ioVals.value = props.ios;
      groupVals.value = props.groups;
      open.value = false;
    }
    return {
      open,
      openModal,
      tree,
      fieldNames,
      ioSelects,
      ioVals,
      groupVals,
      onCancel,
      onOk,
      texts
    }
  }
});
</script>

<style scoped lang="scss">

.io-select {
  border: .5px solid #e6e6e6;
  padding: .5rem 2rem .5rem .5rem;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  min-width: 430px;

  &:hover {
    border: .5px solid #3c74b0;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.container {
  max-height: 60vh;
  overflow: auto;
}

.tab-icon {
  transform: translateY(5px);
}

</style>
