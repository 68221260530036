<template>
  <div class="intrusion-event flex">
    <a-col :span="24" class="bg-ems-gray800 pb-20px pr-6px">
      <div class="flex justify-between items-center p-20px">
        <div class="flex gap-[30px] items-center">
          <h3 class="uppercase text-white font-semibold">
            {{ t('intrusion-event.list_intrusion_event') }}
          </h3>
          <div class="py-4px px-24px border-1 border-ems-gray700 rounded-full">
            <span class="text-ems-white"
              >{{ t('menu.hien') }}:
              <span class="text-ems-main1">{{ countIntrusion }}</span
              ><span class="text-ems-gray600">
                / {{ totalIntrusion }}</span
              ></span
            >
          </div>
        </div>
        <div class="flex gap-[30px] items-center">
          <a-input
            v-model:value="formState.keyword"
            :maxlength="255"
            class="ant-input-sm rounded h-[34px]"
            :placeholder="t('common.search')"
            allowClear="true"
            @press-enter="onSearch"
            @change="onChangeInput"
          >
            <template #prefix>
              <cds-feather-icons type="search" size="14" />
            </template>
          </a-input>
          <cds-select
            class="h-[34px]"
            :placeholder="t('group-management.select-type-placeholder')"
            :options="OBJECT_TYPE_VALUE"
            allowClear="true"
            v-model:value="formState.type"
            @change="onChangeInput"
          />
        </div>
      </div>
      <div
        class="max-h-419px 3xl:max-h-[calc(100vh-774px)] overflow-y-auto pl-20px pb-20px"
        @scroll="checkScroll"
      >
        <a-table
          :has-checkbox="false"
          :index-column="true"
          ref="table"
          :pagination="false"
          :columns="columns"
          :data-source="listIntrusionEvent"
          :loading="isLoading"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'stt' && record">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'name'">
              <a-tooltip
                v-if="!record.iOName && !record.iOId"
                placement="top"
                :title="record.id"
              >
                <span>
                  {{
                    record.id.length > 15
                      ? `${record.id.substring(0, 15)}...`
                      : record.id
                  }}
                </span>
              </a-tooltip>

              <a-tooltip
                v-else
                placement="top"
                :title="record.iOName ? record.iOName : record.iOId"
              >
                <span
                  @click="onView(record)"
                  class="text-ems-neu400 hover:text-ems-main1 hover:underline hover:cursor-pointer line-clamp-1"
                >
                  {{ record.iOName ? record.iOName : record.iOId }}
                </span>
              </a-tooltip>
            </template>
            <template v-if="column.key === 'img' && record">
              <a-popover
                placement="top"
                color="#000"
                overlay-class-name="popup-image"
              >
                <template #content>
                  <PopupContent :record="record" />
                </template>
                <div class="flex justify-center">
                  <img
                    class="w-[62px] h-[62px] object-cover"
                    :class="
                      !record.iOName && !record.iOId ? '' : 'cursor-pointer'
                    "
                    :src="
                      record.image
                        ? record.image
                        : require('@/static/img/no-img.png')
                    "
                    alt=""
                    @click="
                      !record.iOName && !record.iOId ? '' : onView(record)
                    "
                  />
                </div>
              </a-popover>
            </template>
            <template v-if="column.key === 'location' && record">
              <a-tooltip placement="bottom" :title="record.location">
                <div class="line-clamp-1">{{ record.location }}</div>
              </a-tooltip>
            </template>
            <template v-if="column.key === 'type'">
              <div>{{ t(getText(record.type, OBJECT_TYPE_TEXT)) }}</div>
            </template>
            <template v-if="column.key === 'time'">
              <div>
                {{ dayjs(record.createdAt).format('DD/MM/YYYY - HH:mm:ss') }}
              </div>
            </template>
            <template v-if="column.key === 'ioGroup'">
              <div
                class="flex items-center flex-wrap gap-y-1"
                v-if="record.ioGroup"
              >
                <div
                  class="flex-shrink-0"
                  v-for="(item, index) in record.ioGroup"
                  :key="index"
                >
                  <span
                    class="inline-block rounded-lg py-6px px-10px bg-ems-gray600 text-center mr-2 cursor-pointer text-ems-gray200"
                    v-if="index < 1"
                  >
                    {{
                      listGroupKeyValue[item] &&
                      listGroupKeyValue[item].length > 20
                        ? `${listGroupKeyValue[item].substring(0, 20)}...`
                        : listGroupKeyValue[item]
                    }}
                  </span>
                </div>
                <a-popover
                  placement="top"
                  overlay-class-name="popup-groups"
                  v-if="record.ioGroup.length > 1"
                >
                  <template #content>
                    <div>
                      <span
                        class="inline-block rounded-lg py-[6px] text-ems-gray300 text-sm px-3 bg-ems-gray600 uppercase mr-1 mb-2 cursor-pointer"
                        v-for="(item, idx) in record.ioGroup"
                        :key="idx"
                      >
                        {{ listGroupKeyValue[item] }}
                      </span>
                    </div>
                  </template>
                  <span
                    class="inline-block rounded-lg p-1 px-2 bg-ems-gray600 text-ems-gray200"
                  >
                    ...
                  </span>
                </a-popover>
              </div>
            </template>
            <template v-if="column.key === 'actions'">
              <div class="flex gap-12px justify-center">
                <cds-feather-icons
                  v-if="record.iOName || record.iOId"
                  type="eye"
                  size="24"
                  class="hover:cursor-pointer"
                  @click="onView(record)"
                />
                <cds-feather-icons
                  v-if="
                    checkHasPermission(perms, EVENT_IDENTIFICATION_PERM.DELETE)
                  "
                  type="trash-2"
                  size="24"
                  class="hover:cursor-pointer"
                  @click="onDelete(record)"
                />
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </a-col>
  </div>
  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
</template>
<script setup>
import {
  OBJECT_TYPE_VALUE,
  OBJECT_TYPE_TEXT,
  EVENT_IDENTIFICATION_PERM,
} from '@/util/common-constant';
import dayjs from 'dayjs';
import { reactive, computed, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { trim, getText, checkHasPermission } from '@/util/common-utils';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PopupContent from './PopupContent.vue';

const { t } = useI18n();
const { state, dispatch } = useStore();
const router = useRouter();
const route = useRoute();

const props = defineProps({
  params: Object,
  refresh: Boolean,
});

const formState = reactive({
  type: null,
  keyword: '',
  size: 50,
});

const page = ref(0);
const isLoading = ref(false);
const showConfirmCustom = ref(null);
const columns = ref([
  {
    title: t('common.stt'),
    key: 'stt',
    width: '5%',
  },
  {
    title: `ID/${t('object_information.object_name')}`,
    key: 'name',
    width: '10%',
    dataIndex: 'name',
  },
  {
    title: t('report_statistical.image'),
    key: 'img',
    width: '10%',
    dataIndex: 'image',
  },
  {
    title: t('group-management.select-type-placeholder'),
    key: 'type',
    dataIndex: 'type',
    width: '10%',
  },
  {
    title: t('camera.name'),
    key: 'camera',
    dataIndex: 'cameraName',
    width: '15%',
  },
  {
    title: t('identity_object.form.Position'),
    key: 'location',
    dataIndex: 'location',
    width: '10%',
  },
  {
    title: t('user_group.permission_time'),
    key: 'time',
    dataIndex: 'createdAt',
    width: '15%',
  },
  {
    title: t('object_information.group_belong'),
    key: 'ioGroup',
  },
  {
    title: t('common.action'),
    key: 'actions',
    width: '10%',
  },
]);

const perms = computed(() => state.auth.userInfo.permissions);
const listIntrusionEvent = computed(() => state.tracingObject.listIntrusion);
const totalIntrusion = computed(() => state.tracingObject.totalIntrusion);
const countIntrusion = computed(() => listIntrusionEvent.value.length);
const listGroupKeyValue = computed(() => state.ioGroup.listGroupKeyValue);

onMounted(async () => {
  formState.keyword = route.query.keyword ? route.query.keyword : '';
  formState.type = route.query.type ? Number(route.query.type) : null;
});

const checkScroll = async (e) => {
  if (
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
    countIntrusion.value !== totalIntrusion.value
  ) {
    await infiniteScroll();
  }
};

const onChangeInput = debounce(() => {
  onSearch();
}, 300);

const onSearch = () => {
  let query = {
    ...route.query,
    groupCameraId: props.params.groupCameraId,
    dateFrom: props.params.dateFrom,
    dateTo: props.params.dateTo,
    keyword: trim(formState.keyword),
    cameraIds: props.params.cameraIds.join(','),
    type: formState.type,
  };
  router.push({
    query: query,
  });
};

const onView = (row) => {
  router.push({
    path: '/trace-management/identification-event/detail',
    query: {
      ...route.query,
      isIntrusion: true,
      id: row.id,
    },
  });
};

const onDelete = async (row) => {
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('identificationEvent/delete', [row]).then(async () => {
      await getData();
      await dispatch('identificationEvent/getDataChart', props.params);
    });
  }
};

const resetData = () => {
  formState.keyword = '';
  formState.type = null;
  router.push({
    query: {
      tab: 'dashboard',
    },
  });
};

const infiniteScroll = async () => {
  isLoading.value = true;
  page.value += 1;
  let params = {
    groupCameraId: props.params.groupCameraId,
    dateFrom: props.params.dateFrom,
    dateTo: props.params.dateTo,
    keyword: trim(formState.keyword),
    type: formState.type,
    size: formState.size,
    cameraIds: props.params.cameraIds.join(','),
    page: page.value,
  };
  await dispatch('tracingObject/getIntrusion', {
    params,
    reset: false,
  });
  isLoading.value = false;
};

const getData = async () => {
  isLoading.value = true;
  page.value = 0;
  let params = {
    groupCameraId: props.params.groupCameraId,
    dateFrom: props.params.dateFrom,
    dateTo: props.params.dateTo,
    keyword: trim(formState.keyword),
    type: formState.type,
    size: formState.size,
    cameraIds: props.params.cameraIds.join(','),
    page: page.value,
  };
  await dispatch('tracingObject/getIntrusion', {
    params,
    reset: true,
  });
  isLoading.value = false;
};

watch(
  formState,
  debounce(async () => {
    await getData();
  }, 300)
);

watch(
  () => [
    props.params.groupCameraId,
    props.params.cameraIds,
    props.params.dateFrom,
    props.params.dateTo,
  ],
  async () => {
    await getData();
    if (props.refresh) {
      resetData();
    }
  }
);
</script>
<style lang="scss">
.intrusion-event .ant-table-wrapper .ant-table {
  border-radius: 0;
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity));
}
.intrusion-event .ant-table-wrapper .ant-table .ant-table-container {
  padding-right: 6px;
}
.intrusion-event .ant-table-wrapper .ant-table .ant-table-container table {
  --tw-border-opacity: 1;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity));
  border-radius: 0;
}
.intrusion-event .ant-table-wrapper .ant-table .ant-table-container table .ant-table-thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: -3px;
}
.intrusion-event .ant-table-wrapper .ant-table .ant-table-container table .ant-table-thead .ant-table-cell {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity));
  border-bottom-width: 3px;
  border-radius: 0 !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.intrusion-event .ant-table-wrapper .ant-table .ant-table-container table .ant-table-tbody .ant-table-cell {
  padding: 5px 10px;
  border-radius: 0 !important;
  --tw-text-opacity: 1;
  color: rgba(157, 157, 157, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity));
  border-bottom-width: 3px;
  text-align: center;
}
.intrusion-event .ant-table-wrapper .ant-table-content
      .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.intrusion-event .ant-select-selector {
  border-radius: 4px !important;
  border-color: #555 !important;
  background-color: #1f1f1f !important;
}
.intrusion-event .ant-select-selector .ant-select-selection-search {
  background: transparent;
}
.intrusion-event .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
  height: 34px !important;
  line-height: 34px !important;
}
.intrusion-event .ant-select-selector .ant-select-selection-placeholder {
  line-height: 34px !important;
}
.intrusion-event .ant-input-affix-wrapper {
  height: 34px;
  border-color: #555 !important;
  background: #1f1f1f;
  padding: 4px 12px !important;
}
.intrusion-event .ant-input-affix-wrapper .ant-input {
  background: #1f1f1f;
}
.popup-image {
  z-index: 1112;
}
.popup-image .ant-popover-inner {
  border: 2px solid #4c4c4c;
  padding: 0;
  width: 771px;
}
.popup-image .ant-popover-arrow {
  display: none;
}
.popup-groups .ant-popover-arrow {
  display: none;
}
</style>
