import { ModalStyled } from '@/components/modals/styled';
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { SocialMediaWrapper } from '@/view/dashboard/style';
import Tabs from '@/components/tabs/Tabs.vue';
import GroupInfo from './GroupInfo.vue';
import CreateGroup from './CreateGroup.vue';
import List from './List.vue';
import Permission from './Permission.vue';
import {
  CloseCircleFilled,
  FileSearchOutlined,
  InfoCircleFilled,
  LockOutlined,
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons-vue';
import { primaryColor } from '@/config/theme/themeVariables';
import { FORM_MODE } from '@/util/common-constant';

export default {
  name: 'Menu',
  components: {
    SocialMediaWrapper,
    ModalStyled,
    'cds-tabs': Tabs,
    InfoCircleFilled,
    FileSearchOutlined,
    LockOutlined,
    PlusCircleFilled,
    CloseCircleFilled,
    GroupInfo,
    List,
    Permission,
    CreateGroup,
    EditOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
  },
  setup() {
    const { state, dispatch } = useStore();
    const tree = computed(() => state.userGroup.tree);
    const treeValue = ref(null);
    const activeTab = ref('1');
    const formMode = ref(null);
    const groups = computed(() => state.userGroup.groups);
    const group = computed(() => state.userGroup.group);
    const loading = computed(() => state.userGroup.loading);

    const selectGroup = (id) => {
      activeTab.value = '1';
      const group = groups.value.find((e) => e.id === id);
      if (group) {
        dispatch('userGroup/preView', group).then(() =>
          dispatch('userGroup/getPermission', group).then()
        );
      }
      formMode.value = FORM_MODE.UPDATE;
    };
    const resetForm = () => {
      dispatch('userGroup/resetForm', group).then();
      treeValue.value = null;
    };
    const preCreate = () => {
      dispatch('userGroup/preCreate', group).then();
      formMode.value = FORM_MODE.CREATE;
    };
    onMounted(() => {
      dispatch('setTitleHeader', 'User Group').then();
      dispatch('userGroup/getAll').then();
      dispatch('user/getAll').then();
    });
    onUnmounted(resetForm);
    const contextMenuClick = async (menu, id) => {
      switch (menu) {
        case '1':
          selectGroup(id);
          break;
        case '2':
          if (await dispatch('userGroup/delete', { id })) {
            resetForm();
            dispatch('userGroup/getAll').then();
          }
          break;
        case '3':
          selectGroup(id);
          break;
        case '4':
          selectGroup(id);
          break;
      }
    };
    return {
      tree,
      treeValue,
      groups,
      fieldNames: { children: 'children', title: 'name', key: 'id' },
      selectGroup,
      loading,
      primaryColor: primaryColor,
      group,
      resetForm,
      preCreate,
      activeTab,
      FORM_MODE: FORM_MODE,
      formMode,
      contextMenuClick,
    };
  },
};
