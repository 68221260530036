<template>
  <div class="my-16px py-18px px-20px bg-ems-gray800">
    <div class="text-ems-gray200 text-18px mb-24px font-semibold uppercase">
      {{ t('identification-event.io-same-img') }}
    </div>
    <cds-input
      class="w-100 ant-input-sm"
      v-model:value="keywordSearchSimilar"
      :show-count="false"
      size="default"
      :placeholder="t('identity_object.form_label.search_by_info_placeholder')"
      @input="onChangeInput"
    />
    <template v-if="listSelectedSimilar && listSelectedSimilar.length > 0">
      <p class="text-ems-gray200 font-semibold text-16px my-3">
        {{ t('object_information.selected') }}
      </p>
      <div
        class="flex flex-wrap gap-8px min-h-39px px-13px py-6px bg-ems-gray700"
      >
        <div
          v-for="item in listSelectedSimilar"
          :key="item.id"
          :title="item.name ? item.name : item.id"
          class="flex gap-3px pl-5px pr-8px py-2px rounded-23px items-center max-w-115px bg-ems-gray600"
        >
          <div class="group flex-shrink-0">
            <img
              :src="
                !item.imagesAvatar
                  ? require('@/static/img/no-img.png')
                  : item.imagesAvatar
              "
              :alt="item.imagesAvatar"
              width="20"
              height="20"
              class="rounded-lg border-1px border-ems-gray300 w-[20px] h-[20px] rounded-[50%] object-cover group-hover:hidden"
            />
            <img
              src="@/static/img/icon/DeleteSelectedIcon.svg"
              alt="DeleteSelectedIcon"
              width="20"
              height="20"
              class="w-[20px] h-[20px] hidden group-hover:block cursor-pointer"
              @click="onChooseSimilar(item)"
            />
          </div>
          <div class="text-ems-white w-full truncate">
            {{ item.name ? item.name : item.id }}
          </div>
        </div>
      </div>
    </template>
    <p class="text-ems-gray200 font-semibold text-16px my-3">
      {{ t('object_information.suggest') }}
    </p>
    <cds-loading v-if="isLoading || props.isLoading" />
    <template v-else>
      <Empty
        v-if="listSimilar.length === 0"
        :description="t('common.no_data_similar')"
      />
      <div v-else class="flex flex-col gap-[20px] max-h-550px overflow-y-auto">
        <div v-for="item in listSimilar" :key="item.id" class="relative">
          <div
            class="flex gap-[20px] p-[20px] bg-ems-tag1 rounded-lg relative cursor-pointer border-1px border-ems-tag1 hover:border-ems-white"
            @click="onChooseSimilar(item)"
          >
            <div class="flex items-center">
              <a-checkbox
                class="text-ems-gray300 checkbox-custom"
                :checked="isChecked(item.id)"
              />
            </div>
            <img
              :src="
                !item.imagesAvatar
                  ? require('@/static/img/no-img.png')
                  : item.imagesAvatar
              "
              :alt="item.imagesAvatar"
              width="120"
              height="120"
              class="rounded-lg w-[120px] h-[120px] flex-shrink-0 object-cover"
            />
            <div class="flex flex-col gap-12px w-full">
              <span class="text-lg text-ems-gray100 font-semi-bold">{{
                item.name ? item.name : item.id
              }}</span>
              <ul
                class="list-disc list-inside text-12px text-ems-gray500 font-semibold pl-5px similar-profile-ul"
              >
                <!--                <template v-if="item.type === OBJECT_TYPE.HUMAN">-->
                <li class="leading-19px mb-5px">
                  {{ t('event_verify.similarity') }}:
                  <span class="text-ems-boTro4_600"
                    >{{ item.confidence.toFixed(2) }}%
                  </span>
                </li>
                <li class="leading-19px mb-5px truncate">
                  {{ t('event_verify.date_of_birth') }}:
                  {{
                    item.dateOfBirth
                      ? formatDate(item.dateOfBirth, 'DD/MM/YYYY')
                      : ''
                  }}
                </li>
                <li class="leading-19px mb-5px truncate">
                  {{ t('event_verify.address') }}:
                  {{ item.address }}
                </li>
                <li class="leading-19px mb-5px truncate">
                  {{ t('event_verify.phone') }}:
                  {{ item.phone }}
                </li>
                <!--                </template>-->
                <!--                <template v-if="item.type === OBJECT_TYPE.VEHICLE">-->
                <!--                  <li class="leading-19px mb-5px">-->
                <!--                    {{ t('event_verify.category') }}:-->
                <!--                    {{ getNameVehicleType(item.vehicle.vehicle_type) }}-->
                <!--                  </li>-->
                <!--                  <li class="leading-19px mb-5px">-->
                <!--                    {{ t('event_verify.brand') }}: {{ item.vehicle.brand_id }}-->
                <!--                  </li>-->
                <!--                  <li class="leading-19px mb-5px">-->
                <!--                    {{ t('event_verify.color') }}:-->
                <!--                    {{ vehicleColor(item.vehicle.vehicle_color) }}-->
                <!--                  </li>-->
                <!--                </template>-->
              </ul>
              <span
                class="w-[fit-content] self-end mt-5px px-[16px] py-[3px] text-[12px] text-ems-gray300 border-1px border-solid border-ems-gray300 block bottom-[13px] right-[16px] rounded-full cursor-pointer"
                @click="viewDetailIO(item)"
                >{{ t('common.detail') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
  <showConfirmModal ref="showConfirmMerge"></showConfirmModal>
</template>
<script setup>
import { FORM_MODE, OBJECT_TYPE } from '../../../util/common-constant';
import { computed, onMounted, ref, watch } from 'vue';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { WARNING_LEVEL } from '../../../config/Constant';
import { notification } from 'ant-design-vue';
import { Empty } from 'ant-design-vue';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { formatDate } from '../../../util/common-utils';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { state, dispatch } = useStore();
const props = defineProps({
  imgAvatar: {
    type: Array,
    default: null,
  },
  isLoading: {
    type: Boolean,
  },
});
const isLoading = ref(false);
onMounted(async () => {
  isLoading.value = true;
  await dispatch('ioManagement/getListSimilarCreate', {
    keyword: null,
  });
  isLoading.value = false;
  await dispatch('ioGroup/getAllGroup');
  if (route.query.profileEventId && route.query.profileEventType) {
    const response = await dispatch('ioManagement/getDetail', {
      id: route.query.profileEventId,
      type: route.query.profileEventType,
    });
    if (response) {
      await dispatch(
        'identificationEvent/getSelectedMergeSimilar',
        response.id
      );
      listSelectedSimilar.value.push({
        id: response.id,
        name: response.name,
        imagesAvatar: response.imagesAvatar.filter((item) => item.isMain)[0]
          .url,
        tracing: response.tracing,
      });
    }
  }
});
const classGroupTag = (item) => {
  return {
    'bg-ems-main1': item.warningLevel === WARNING_LEVEL[0].id,
    'bg-ems-boTro2_600': item.warningLevel === WARNING_LEVEL[1].id,
    'bg-ems-boTro4_800': item.warningLevel === WARNING_LEVEL[2].id,
    'bg-ems-gray500': item.warningLevel === WARNING_LEVEL[3].id,
  };
};
const listSimilar = computed(() => state.ioManagement.listSimilarCreate);
const listGroup = computed(() => state.ioGroup.listGroup);
const formState = computed(() => state.ioManagement.formState);
const imgMain = computed(() =>
  props.imgAvatar ? props.imgAvatar.filter((item) => item.isMain)[0] : null
);
const listGroupSimilar = computed(() => {
  let groups = {};
  listGroup.value.map((el) => {
    groups[el.id] = el;
  });
  return groups;
});
const keywordSearchSimilar = ref('');
const onChangeInput = debounce(async () => {
  isLoading.value = true;
  await dispatch('ioManagement/getListSimilarCreate', {
    keyword: keywordSearchSimilar.value,
    imgUrl: imgMain.value ? imgMain.value.url : null,
  });
  isLoading.value = false;
}, 300);
const viewDetailIO = (item) => {
  const routeLink = router.resolve({
    name: '/io-management/object-management/edit',
    query: {
      id: item.id,
      type: item.type,
      mode: FORM_MODE.VIEW,
    },
  });
  window.open(routeLink.href, '_blank');
};
const listSelectedSimilar = ref([]);
const onChooseSimilar = (record) => {
  const index = listSelectedSimilar.value.findIndex(
    (item) => item.id === record.id
  );
  if (index > -1) {
    listSelectedSimilar.value.splice(index, 1);
  } else {
    listSelectedSimilar.value.push({
      id: record.id,
      name: record.name,
      imagesAvatar: record.imagesAvatar,
      tracing: record.tracing,
    });
  }
  dispatch('ioManagement/setListMergeProfile', listSelectedSimilar.value);
};
const isChecked = computed(() => (id) => {
  const index = listSelectedSimilar.value.findIndex((item) => item.id === id);
  return index > -1;
});
const showConfirmMerge = ref(null);
defineExpose({
  listSelectedSimilar,
  keywordSearchSimilar,
});
</script>
<style>

.similar-profile-ul li::marker {
  font-size: 0.7em;
}

</style>
