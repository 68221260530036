<template>
  <a-form
    ref="refVehicle"
    :model="formVehicle"
    name="basic"
    :validate-trigger="['change', 'blur']"
    label-wrap
    label-align="left"
    :rules="rules"
    :scroll-to-first-error="{
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    }"
  >
    <div class="grid grid-cols-3 gap-30px">
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.vehicle_owner')"
          :name="['ownerVehicle']"
        >
          <cds-input
            v-model:value="formVehicle.ownerVehicle"
            :maxlength="100"
            :placeholder="t('object_information.vehicle_owner_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :name="['ownerIdCard']"
        >
          <template #label>
            <div
              class="whitespace-nowrap truncate"
              :title="t('object_information.id_owner')"
            >
              {{ t('object_information.id_owner') }}
            </div>
          </template>
          <cds-input
            v-model:value="formVehicle.ownerIdCard"
            :maxlength="20"
            :placeholder="t('object_information.id_owner_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.address_owner')"
          :name="['ownerAddress']"
        >
          <cds-input
            v-model:value="formVehicle.ownerAddress"
            :maxlength="255"
            :placeholder="t('object_information.address_owner_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <div>
          <a-popover
            placement="bottom"
            trigger="click"
            overlayClassName="popup-suggestion"
          >
            <template #content>
              <Empty
                v-if="!suggestVehicle"
                description=""
                class="px-10 py-4 m-0 w-[200px]"
              />
              <div
                class="z-10 max-w-[350px] min-w-[200px] cursor-pointer flex flex-col gap-1 p-[16px] hover:bg-ems-gray600"
                v-else
                @click="selectSuggestion"
              >
                <span class="text-sm text-ems-gray100"
                  >{{ t('object_information.vehicle_owner') }}:
                  <span class="font-semibold">{{
                    suggestVehicle.ownerVehicle
                  }}</span>
                </span>
                <span class="text-sm text-ems-gray100"
                  >{{ t('object_information.license_plate') }}:
                  <span class="font-semibold">{{
                    suggestVehicle.licensePlate
                  }}</span></span
                >
              </div>
            </template>
            <a-form-item
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
              :label="t('object_information.license_plate')"
              :name="['licensePlate']"
            >
              <cds-input
                v-model:value="formVehicle.licensePlate"
                :maxlength="20"
                :placeholder="t('object_information.license_plate_placeholder')"
                :disabled="formMode === FORM_MODE.VIEW"
                @change="onChange"
              />
            </a-form-item>
          </a-popover>
        </div>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.id_vehicle')"
          :name="['registrationNo']"
        >
          <cds-input
            v-model:value="formVehicle.registrationNo"
            :maxlength="20"
            :placeholder="t('object_information.id_vehicle_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.license_date')"
          :name="['registrationDate']"
        >
          <cds-date-picker
            v-model:value="formVehicle.registrationDate"
            placeholder="DD/MM/YYYY"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.issued_by')"
          :name="['registrationAddress']"
        >
          <cds-input
            v-model:value="formVehicle.registrationAddress"
            :maxlength="100"
            :placeholder="t('object_information.issued_by_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
    </div>
    <div class="h-1px w-full bg-ems-gray700 mt-28px"></div>
    <div class="grid grid-cols-3 gap-30px mt-40px pb-63px">
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.vehicle_brand')"
          :name="['brandId']"
        >
          <cds-select
            v-model:value="formVehicle.brandId"
            :options="brand"
            :label-prop="'name'"
            :value-prop="'value'"
            :placeholder="t('object_information.vehicle_brand_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.vehicle_type')"
          :name="['vehicleType']"
        >
          <cds-select
            v-model:value="formVehicle.vehicleType"
            :options="vehicleType"
            :label-prop="'name'"
            :value-prop="'value'"
            :placeholder="t('object_information.vehicle_type_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.color')"
          :name="['vehicleColor']"
        >
          <cds-select
            v-model:value="formVehicle.vehicleColor"
            :options="color"
            :label-prop="'name'"
            :value-prop="'value'"
            :placeholder="t('object_information.color_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.chassis_number')"
          :name="['chassisNumber']"
        >
          <cds-input
            v-model:value="formVehicle.chassisNumber"
            :maxlength="50"
            :placeholder="t('object_information.chassis_number_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.motor_number')"
          :name="['engineNumber']"
        >
          <cds-input
            v-model:value="formVehicle.engineNumber"
            :maxlength="50"
            :placeholder="t('object_information.motor_number_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.year_of_manufacture')"
          :name="['manufacturingDate']"
        >
          <cds-input
            v-model:value="formVehicle.manufacturingDate"
            :maxlength="18"
            :placeholder="
              t('object_information.year_of_manufacture_placeholder')
            "
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.license_plate_type')"
          :name="['licensePlateType']"
        >
          <cds-select
            v-model:value="formVehicle.licensePlateType"
            :options="licensePlateType"
            :label-prop="'name'"
            :value-prop="'value'"
            :placeholder="
              t('object_information.license_plate_type_placeholder')
            "
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.note')"
          :name="['note']"
        >
          <cds-input
            v-model:value="formVehicle.note"
            :maxlength="255"
            :placeholder="t('object_information.note_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
    </div>
  </a-form>
  <showConfirmModal ref="showConfirm" :closable="false"></showConfirmModal>
</template>
<script setup>
import { computed, inject, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { FORM_MODE } from '@/util/common-constant';
import { required, numberRule } from '@/util/validation';
import { notification } from 'ant-design-vue';
import { TAB_KEY } from '@/util/common-constant';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { useRouter, useRoute } from 'vue-router';
import { debounce } from 'lodash';
import Empty from '@/components/empty/index.vue';

const { t } = useI18n();
const { state, dispatch } = useStore();
const formMode = computed(() => state.ioManagement.formMode);
const formVehicle = computed(() => state.ioManagement.formVehicle);
const listMergeProfile = computed(() => state.ioManagement.listMergeProfile);
const objectInfoActiveTab = computed(
  () => state.ioManagement.objectInfoActiveTab
);
const color = computed(() => state.listSelect.color);
const vehicleType = computed(() => state.listSelect.vehicleType);
const brand = computed(() => state.listSelect.brand);
const licensePlateType = computed(() => state.listSelect.licensePlateType);
const router = useRouter();
const refVehicle = ref(null);
const showConfirm = ref(null);
const rules = {
  licensePlate: [required(t('object_information.license_plate'))],
  manufacturingDate: [numberRule(t('object_information.year_of_manufacture'))],
};
const route = useRoute();

const DEBOUNCE_TIME = 500;
const suggestVehicle = computed(() => state.ioManagement.suggestVehicle);
const onChange = debounce(async () => {
  await dispatch('ioManagement/getSuggestVehicle', {
    licensePlate: formVehicle.value.licensePlate,
  });
}, DEBOUNCE_TIME);
const selectSuggestion = () => {
  formVehicle.value.licensePlate = suggestVehicle.value.licensePlate;
  formVehicle.value.chassisNumber = suggestVehicle.value.chassisNumber;
  formVehicle.value.engineNumber = suggestVehicle.value.engineNumber;
  formVehicle.value.manufacturingDate = suggestVehicle.value.manufacturingDate;
  formVehicle.value.ownerVehicle = suggestVehicle.value.ownerVehicle;
  formVehicle.value.ownerAddress = suggestVehicle.value.ownerAddress;
  formVehicle.value.registrationNo = suggestVehicle.value.registrationNo;
};

const onSaveVehicle = async (isCallApi) => {
  let title =
    formMode.value !== FORM_MODE.CREATE
      ? 'common.update_confirm'
      : 'common.save_confirm';
  refVehicle.value
    .validate()
    .then(async () => {
      if (isCallApi) {
        if (await showConfirm.value.onOpenModal(title)) {
          if (await dispatch('ioManagement/checkViolation')) {
            if (formMode.value === FORM_MODE.CREATE) {
              const response = await dispatch('ioManagement/createVehicle');
              if (response) {
                if (listMergeProfile.value.length > 0) {
                  await dispatch('ioManagement/mergeProfile', {
                    id: response,
                    idsSimilar: listMergeProfile.value,
                  });
                }
                await router.push({
                  name: '/io-management/object-management',
                  query: query,
                });
                await dispatch('ioManagement/resetForm');
              }
            } else if (formMode.value === FORM_MODE.UPDATE) {
              const response = await dispatch('ioManagement/updateVehicle');
            }
          }
        }
      }
    })
    .catch(() => {
      if (objectInfoActiveTab.value !== TAB_KEY.INFO) {
        notification.error({
          message: t('common.notification'),
          description: t('common.invalid_form_info'),
          duration: 4,
        });
      }
    });
};
const eventBus = inject('eventBus');
const query = reactive({
  page: 1,
  searchName: null,
  searchGroupId: null,
  searchType: null,
  searchImage: '',
});

onMounted(() => {
  dispatch('listSelect/getAllColor');
  dispatch('listSelect/getAllVehicleType');
  dispatch('listSelect/getAllBrand');
  dispatch('listSelect/getAllLicensePlateType');
  eventBus.on('validate-vehicle', (isCallApi) => {
    onSaveVehicle(isCallApi);
  });
  query.page = route.query.page ? route.query.page : 1;
  query.searchName = route.query.searchName ? route.query.searchName : null;
  query.searchType = route.query.searchType ? route.query.searchType : null;
  query.searchImage = route.query.searchImage ? route.query.searchImage : '';
  query.searchGroupId = route.query.searchGroupId
    ? route.query.searchGroupId
    : null;
  dispatch('ioManagement/getSuggestVehicle', {
    licensePlate: formVehicle.value.licensePlate,
  });
});
</script>
