<template>
  <a-form
    ref="refHuman"
    :model="formHuman"
    :validate-trigger="['change', 'blur']"
    label-wrap
    label-align="left"
    :rules="rules"
    :scroll-to-first-error="{
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    }"
  >
    <div class="grid grid-cols-3 gap-30px">
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.name')"
          :name="['realName']"
        >
          <cds-input
            v-model:value="formHuman.realName"
            :maxlength="100"
            :placeholder="t('object_information.name_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.date_of_birth')"
          :name="['dateOfBirth']"
        >
          <cds-date-picker
            v-model:value="formHuman.dateOfBirth"
            :disabled-date="disabledDate"
            placeholder="DD/MM/YYYY"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.gender')"
          :name="['gender']"
        >
          <cds-select
            v-model:value="formHuman.gender"
            :options="GENDER_LIST"
            :allow-clear="true"
            :placeholder="t('object_information.gender_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.nationality')"
          :name="['nationality']"
        >
          <cds-select
            v-model:value="formHuman.nationality"
            :options="listNationalities"
            :label-prop="'name'"
            :value-prop="'id'"
            show-search
            :is-label-i18n="false"
            :allow-clear="true"
            :placeholder="t('object_information.nationality_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-popover
          placement="bottom"
          trigger="click"
          overlayClassName="popup-suggestion"
        >
          <template #content>
            <Empty
              v-if="!suggestPerson"
              description=""
              class="px-10 py-4 m-0 w-[200px]"
            />
            <div
              class="z-10 max-w-[350px] min-w-[200px] cursor-pointer flex flex-col gap-1 p-[16px] hover:bg-ems-gray600"
              v-else
              @click="selectSuggestion"
            >
              <span class="text-sm text-ems-gray100"
                >{{ t('object_information.name') }}:
                <span class="font-semibold">
                  {{ suggestPerson.realName }}
                </span></span
              >
              <span class="text-sm text-ems-gray100"
                >{{ t('object_information.phone_number') }}:
                <span class="font-semibold">
                  {{ suggestPerson.phone }}
                </span></span
              >
            </div>
          </template>
          <a-form-item
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
            :label="t('object_information.phone_number')"
            :name="['phone']"
            class="relative"
          >
            <cds-input
              v-model:value="formHuman.phone"
              :maxlength="20"
              :placeholder="t('object_information.phone_number_placeholder')"
              :disabled="formMode === FORM_MODE.VIEW"
              @change="onChange"
            />
          </a-form-item>
        </a-popover>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.email')"
          :name="['email']"
        >
          <cds-input
            v-model:value="formHuman.email"
            :maxlength="50"
            :placeholder="t('object_information.email_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.job')"
          :name="['job']"
        >
          <cds-input
            v-model:value="formHuman.job"
            :maxlength="50"
            :placeholder="t('object_information.job_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.media_social')"
          :name="['socialNetwork']"
        >
          <cds-input
            v-model:value="formHuman.socialNetwork"
            :maxlength="100"
            :placeholder="t('object_information.media_social_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.note')"
          :name="['note']"
        >
          <cds-input
            v-model:value="formHuman.note"
            :maxlength="255"
            :placeholder="t('object_information.note_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
    </div>
    <div class="h-1px w-full bg-ems-gray700 mt-28px"></div>
    <div class="grid grid-cols-3 gap-30px mt-40px">
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.tax_code')"
          :name="['taxCode']"
        >
          <cds-input
            v-model:value="formHuman.taxCode"
            :maxlength="20"
            :placeholder="t('object_information.tax_code_place')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.insurance_code')"
          :name="['insuranceCode']"
        >
          <cds-input
            v-model:value="formHuman.insuranceCode"
            :maxlength="20"
            :placeholder="t('object_information.insurance_code_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.document_type')"
          :name="['typeExhibit']"
        >
          <cds-select
            v-model:value="formHuman.typeExhibit"
            :options="DOCUMENT_TYPE"
            :allow-clear="true"
            :placeholder="t('object_information.document_type_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.document_number')"
          :name="['exhibitDetail']"
        >
          <cds-input
            v-model:value="formHuman.exhibitDetail"
            :maxlength="20"
            :placeholder="t('object_information.document_number_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.license_date')"
          :name="['dateOfIssue']"
        >
          <cds-date-picker
            v-model:value="formHuman.dateOfIssue"
            placeholder="DD/MM/YYYY"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.issued_by')"
          :name="['placeOfIssue']"
        >
          <cds-input
            v-model:value="formHuman.placeOfIssue"
            :maxlength="100"
            :placeholder="t('object_information.issued_by_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.address')"
          :name="['address']"
        >
          <cds-input
            v-model:value="formHuman.address"
            :maxlength="255"
            :placeholder="t('object_information.address_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.nation')"
          :name="['country']"
        >
          <cds-select
            v-model:value="formHuman.country"
            :options="listNationalities"
            :label-prop="'name'"
            :value-prop="'id'"
            show-search
            :is-label-i18n="false"
            :allow-clear="true"
            :placeholder="t('object_information.nation_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.province')"
          :name="['provinceId']"
        >
          <cds-select
            v-model:value="formHuman.provinceId"
            :options="listProvince"
            :label-prop="'name'"
            :value-prop="'id'"
            show-search
            :disabled="isNotVietNam || formMode === FORM_MODE.VIEW"
            :is-label-i18n="false"
            :allow-clear="true"
            @change="onChangeProvince"
            :placeholder="t('object_information.province_placeholder')"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.district')"
          :name="['districtId']"
        >
          <cds-select
            v-model:value="formHuman.districtId"
            :options="listDistrict"
            :label-prop="'name'"
            :value-prop="'id'"
            show-search
            :disabled="isNotVietNam || formMode === FORM_MODE.VIEW"
            :is-label-i18n="false"
            :allow-clear="true"
            @change="onChangeDistrict"
            :placeholder="t('object_information.district_placeholder')"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.wards')"
          :name="['wardId']"
        >
          <cds-select
            v-model:value="formHuman.wardId"
            :options="listWard"
            :label-prop="'name'"
            :value-prop="'id'"
            show-search
            :disabled="isNotVietNam || formMode === FORM_MODE.VIEW"
            :is-label-i18n="false"
            :allow-clear="true"
            :placeholder="t('object_information.wards_placeholder')"
          />
        </a-form-item>
      </div>
    </div>
    <div class="h-1px w-full bg-ems-gray700 mt-28px"></div>
    <div class="grid grid-cols-3 gap-30px mt-40px pb-63px">
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.father_name')"
          :name="['fatherName']"
        >
          <cds-input
            v-model:value="formHuman.fatherName"
            :maxlength="100"
            :placeholder="t('object_information.father_name_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :name="['fatherExhibit']"
        >
          <template #label>
            <div
              class="whitespace-nowrap truncate"
              :title="t('object_information.father_id')"
            >
              {{ t('object_information.father_id') }}
            </div>
          </template>
          <cds-input
            v-model:value="formHuman.fatherExhibit"
            :maxlength="20"
            :placeholder="t('object_information.father_id_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.mother_name')"
          :name="['motherName']"
        >
          <cds-input
            v-model:value="formHuman.motherName"
            :maxlength="100"
            :placeholder="t('object_information.mother_name_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :name="['motherExhibit']"
        >
          <template #label>
            <div
              class="whitespace-nowrap truncate"
              :title="t('object_information.mother_id')"
            >
              {{ t('object_information.mother_id') }}
            </div>
          </template>
          <cds-input
            v-model:value="formHuman.motherExhibit"
            :maxlength="20"
            :placeholder="t('object_information.mother_id_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :label="t('object_information.spouse_name')"
          :name="['spouseName']"
        >
          <cds-input
            v-model:value="formHuman.spouseName"
            :maxlength="100"
            :placeholder="t('object_information.spouse_name_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
      <div>
        <a-form-item
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          :name="['spouseExhibit']"
        >
          <template #label>
            <div
              class="whitespace-nowrap truncate"
              :title="t('object_information.spouse_id')"
            >
              {{ t('object_information.spouse_id') }}
            </div>
          </template>
          <cds-input
            v-model:value="formHuman.spouseExhibit"
            :maxlength="20"
            :placeholder="t('object_information.spouse_id_placeholder')"
            :disabled="formMode === FORM_MODE.VIEW"
          />
        </a-form-item>
      </div>
    </div>
  </a-form>
  <showConfirmModal ref="showConfirm" :closable="false"></showConfirmModal>
</template>
<script setup>
import {
  computed,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import {
  emailRule,
  numberRule,
  phoneNumberRule,
  required,
} from '@/util/validation';
import {
  DOCUMENT_TYPE,
  GENDER_LIST,
  NATIONALITY_VIETNAM_ID,
} from '@/config/Constant';
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs';
import { FORM_MODE, TAB_KEY } from '@/util/common-constant';
import { notification } from 'ant-design-vue';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { debounce } from 'lodash';
import Empty from '@/components/empty/index.vue';
import { VIEW_CONSTANT } from '../../../../util/common-constant';

const { t } = useI18n();
const { state, dispatch } = useStore();
const router = useRouter();
const formHuman = reactive(state.ioManagement.formHuman);
const formMode = computed(() => state.ioManagement.formMode);
const listMergeProfile = computed(() => state.ioManagement.listMergeProfile);
const objectInfoActiveTab = computed(
  () => state.ioManagement.objectInfoActiveTab
);
const listNationalities = computed(() => state.nationality.nationalities);
const listProvince = computed(() => state.location.locations || []);
const listDistrict = ref([]);
const listWard = ref([]);
const isNotVietNam = ref(false);
const refHuman = ref(null);
const showConfirm = ref(null);
const rules = {
  realName: [required(t('object_information.name'))],
  email: [emailRule(t('object_information.email'))],
  phone: [phoneNumberRule(t('object_information.phone_number'))],
  fatherExhibit: [numberRule(t('object_information.father_id'))],
  motherExhibit: [numberRule(t('object_information.mother_id'))],
  spouseExhibit: [numberRule(t('object_information.spouse_id'))],
};
const route = useRoute();
const eventBus = inject('eventBus');
const query = reactive({
  page: 1,
  searchName: null,
  searchGroupId: null,
  searchType: null,
  searchImage: '',
});
onMounted(async () => {
  query.page = route.query.page ? route.query.page : 1;
  query.searchName = route.query.searchName ? route.query.searchName : null;
  query.searchType = route.query.searchType ? route.query.searchType : null;
  query.searchImage = route.query.searchImage ? route.query.searchImage : '';
  query.searchGroupId = route.query.searchGroupId
    ? route.query.searchGroupId
    : null;
  await Promise.all([
    dispatch('location/getAllLocations'),
    dispatch('nationality/getAllNationalities'),
  ]);
  eventBus.on('validate-human', (isCallApi) => {
    onSaveHuman(isCallApi);
  });
  checkIsVietNam();
  if (formMode.value !== FORM_MODE.CREATE) {
    if (formHuman.provinceId) {
      listDistrict.value = listProvince.value.find(
        (e) => e.id === formHuman.provinceId
      ).districtsData;
    }
    if (formHuman.districtId) {
      listWard.value = listDistrict.value.find(
        (e) => e.id === formHuman.districtId
      ).wardsData;
    }
  }
  await dispatch('ioManagement/getSuggestPerson', {
    phoneNumber: formHuman.phone,
  });
});
onUnmounted(() => {
  eventBus.off('validate-human');
});
const disabledDate = (current) => {
  return current > dayjs().endOf('day');
};
watch(
  () => formHuman.country,
  (value) => {
    checkIsVietNam();
  }
);

const DEBOUNCE_TIME = 500;
const suggestPerson = computed(() => state.ioManagement.suggestPerson);
const onChange = debounce(async () => {
  await dispatch('ioManagement/getSuggestPerson', {
    phoneNumber: formHuman.phone,
  });
}, DEBOUNCE_TIME);
const selectSuggestion = () => {
  formHuman.phone = suggestPerson.value.phone;
  formHuman.realName = suggestPerson.value.realName;
  formHuman.dateOfBirth = suggestPerson.value.dateOfBirth;
  formHuman.gender = suggestPerson.value.gender;
  formHuman.dateOfIssue = suggestPerson.value.dateOfIssue;
  formHuman.placeOfIssue = suggestPerson.value.placeOfIssue;
  formHuman.taxCode = suggestPerson.value.taxCode;
  formHuman.address = suggestPerson.value.address;
  formHuman.exhibitDetail = suggestPerson.value.exhibitDetail;
};

const checkIsVietNam = () => {
  if (formHuman.country !== NATIONALITY_VIETNAM_ID && formHuman.country) {
    formHuman.provinceId = null;
    formHuman.districtId = null;
    formHuman.wardId = null;
    isNotVietNam.value = true;
  } else {
    isNotVietNam.value = false;
  }
};
const onSaveHuman = async (isCallApi) => {
  let title =
    formMode.value !== FORM_MODE.CREATE
      ? 'common.update_confirm'
      : 'common.save_confirm';
  refHuman.value
    .validate()
    .then(async () => {
      if (isCallApi) {
        if (await showConfirm.value.onOpenModal(title)) {
          if (await dispatch('ioManagement/checkViolation')) {
            if (formMode.value === FORM_MODE.CREATE) {
              const response = await dispatch('ioManagement/createHuman');
              if (response) {
                if(listMergeProfile.value.length > 0) {
                  await dispatch('ioManagement/mergeProfile', {
                    id: response,
                    idsSimilar: listMergeProfile.value,
                  });
                }
                await router.push({
                  name: '/io-management/object-management',
                  query: query,
                });
                await dispatch('ioManagement/resetForm');
              }
            } else if (formMode.value === FORM_MODE.UPDATE) {
              const response = await dispatch('ioManagement/updateHuman');
            }
          }
        }
      }
    })
    .catch(() => {
      if (objectInfoActiveTab.value !== TAB_KEY.INFO) {
        notification.error({
          message: t('common.notification'),
          description: t('common.invalid_form_info'),
          duration: 4,
        });
      }
    });
};
const onChangeProvince = (provinceId) => {
  listDistrict.value = listProvince.value.find(
    (e) => e.id === provinceId
  ).districtsData;
  formHuman.districtId = null;
  formHuman.wardId = null;
  listWard.value = [];
};
const onChangeDistrict = (districtId) => {
  listWard.value = listDistrict.value.find(
    (e) => e.id === districtId
  ).wardsData;
  formHuman.wardId = null;
};
</script>
<style lang="scss">

.popup-suggestion {
  z-index: 1 !important;
  .ant-popover-arrow {
    display: none;
  }
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.1);
  padding: 0px;
  .ant-popover-inner {
    padding: 0 !important;
  }
}

</style>
