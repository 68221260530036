<template>
  <!-- Header -->
  <div
    class="flex justify-between items-center p-20px border-b border-b-1 border-b-solid border-b-ems-gray700"
  >
    <h3 class="uppercase text-white font-semibold">
      {{ t('report_statistical.title_chart_1') }}
    </h3>
    <div class="result flex items-center xxl:gap-20 xl:gap-10">
      <span class="text-ems-gray400">{{ t('report_statistical.count') }}</span>
      <h2 class="text-28px text-ems-main2 font-semibold">
        {{ formatNumberReZero(totalOccurrences) }}
      </h2>
    </div>
  </div>
  <!-- Content -->
  <div>
    <Chart
      v-if="!isLoading"
      ref="barChart"
      :option="occurrencesChart"
      class-name="chart bg-evisa-gray50 rounded-lg pr-4 3xl:px-30px"
    />
    <cds-loading v-else class="h-260px" />
  </div>
  <!-- Footer -->
  <div
    class="flex justify-start gap-10 items-center p-20px border-t border-t-1 border-t-solid border-t-ems-gray700"
  >
    <div class="flex gap-2.5 items-center">
      <div class="w-12px h-12px rounded-full bg-ems-boTro3_600"></div>
      <span class="text-ems-gray400">{{
        t('report_statistical.category_chart_1')
      }}</span>
    </div>
    <div class="flex gap-2.5 items-center">
      <div class="w-12px h-12px rounded-full bg-ems-main1"></div>
      <span class="text-ems-gray400">{{
        t('report_statistical.category_chart_2')
      }}</span>
    </div>
  </div>
</template>
<script setup>
import { reactive, computed, watch, ref } from 'vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import Chart from './Chart.vue';
import { useStore } from 'vuex';
import { formatNumberReZero } from '@/util/common-utils';

const { t } = useI18n();
const { state } = useStore();
const barChart = ref();

const occurrencesChart = reactive({
  xAxis: {
    type: 'value',
    axisLabel: {
      textStyle: {
        color: '#9D9D9D',
      },
      formatter: (value) => {
        return formatNumberReZero(value);
      },
    },
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: '#434343',
      },
    },
    axisTick: {
      alignWithLabel: true,
      inside: true,
    },
  },
  yAxis: {
    data: [],
    axisLabel: {
      interval: 0,
      fontSize: 10,
      textStyle: {
        color: '#9D9D9D',
      },
    },
    axisLine: {
      lineStyle: {
        type: 'dashed',
        color: '#434343',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
        color: '#434343',
      },
    },
  },
  series: [],
  dataZoom: [
    {
      type: 'slider',
      start: 0,
      end: 100,
      yAxisIndex: 0,
    },
  ],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: (params) => {
      let result = '';
      params.forEach((item) => {
        result += `<p class="font-bold">${item.marker} ${formatNumberReZero(
          item.value
        )}</p>`;
      });
      return result;
    },
  },
});

const isLoading = computed(() => state.reportStatistical.loading);
const totalOccurrences = computed(
  () => state.reportStatistical.numberOfOccurrences.total
);
const occurenceDataChart = computed(
  () => state.reportStatistical.numberOfOccurrences.chart
);

watch(
  () => occurenceDataChart.value,
  () => {
    occurrencesChart.yAxis.data =
      !!occurenceDataChart.value.columns &&
      occurenceDataChart.value.columns.map((item) => {
        if (item.split('-').length > 2) {
          return dayjs(item).format('DD/MM');
        } else if (item.split('-').length > 1) {
          return dayjs(item).format('MM/YY');
        }
        return item;
      });
    const series = [
      {
        data: occurenceDataChart.value.vehicle,
        type: 'bar',
        color: '#B5122E',
        label: {
          show: true,
          formatter: (params) =>
            params.value === 0 ? '' : formatNumberReZero(params.value),
          fontSize: 10,
        },
      },
      {
        data: occurenceDataChart.value.human,
        type: 'bar',
        color: '#DCC51E',
        label: {
          show: true,
          formatter: (params) =>
            params.value === 0 ? '' : formatNumberReZero(params.value),
          fontSize: 10,
        },
      },
    ];
    occurrencesChart.series = series;
  }
);
</script>
