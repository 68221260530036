<template>
  <div class="bg-ems-gray1000 h-full detail-profile">
    <div
      class="flex gap-[24px] bg-ems-gray900 py-15px pl-16px pr-24px border-b-1 border-solid border-ems-gray700 sticky top-60px z-1100 relative"
    >
      <div
        class="flex items-center gap-[8px] hover:cursor-pointer text-ems-main1 hover:text-ems-main2"
        @click="onBack"
      >
        <div class="p-2">
          <img
            src="/assets/ems/arrow-left.png"
            alt="left"
            class="block mx-auto"
            width="8"
            height="4"
          />
        </div>
        <span class="font-bold">{{ t('common.comeback') }}</span>
      </div>
      <div class="bg-ems-main2 rounded-8px">
        <div
          v-if="objectName || formState.id"
          class="h-full text-white-a500 px-10px py-4px cursor-pointer"
          @click="copyContent(objectName ? objectName : formState.id)"
        >
          {{ objectName ? objectName : formState.id }}
        </div>
      </div>
      <div
        class="flex items-center justify-center gap-16px absolute right-24px top-10px text-14px leading-[1.5715]"
      >
        <ClipButton
          v-if="checkHasPermission(perms, TRACING_PERM.ADD) && !isLoading"
          class="flex items-center"
          :type="'primary'"
          :disabled="
            !formState.tracing &&
            refSimilar &&
            refSimilar.listSelectedSimilar.findIndex((item) => item.tracing) !==
              -1
          "
          @click="onTracing(route.query.id)"
        >
          <span>{{
            formState.tracing
              ? t('object_information.detail_tracing')
              : t('object_information.add_tracing')
          }}</span>
        </ClipButton>
        <ClipButton
          v-if="formMode !== FORM_MODE.VIEW"
          class="flex items-center w-[100px]"
          :type="'primary'"
          :disabled="isLoading"
          @click="onSave"
        >
          <span>{{ t('common.save') }}</span>
        </ClipButton>
        <ClipButton
          class="flex items-center w-[100px] gap-[6px]"
          :type="'secondary'"
          @click="onClose"
        >
          <cds-feather-icons type="x" size="20" />
          <span>{{ t('common.close') }}</span>
        </ClipButton>
      </div>
    </div>
    <div
      ref="tabContainer"
      class="flex justify-between bg-ems-gray900 pl-16px pr-24px border-b-1 border-solid border-ems-gray700 sticky top-123px z-1100 relative"
    >
      <div
        :class="isBreakLine ? 'pt-60px' : 'pt-26px'"
        class="flex items-center gap-[8px] w-[70%]"
      >
        <a-tabs
          v-model:activeKey="activeTab"
          class="w-[100%] tabs-object-information"
          @change="onChangeTab($event)"
        >
          <template #moreIcon>
            <cds-feather-icons
              type="more-horizontal"
              size="18"
              class="text-ems-white mr-2"
            />
          </template>
          <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tabName">
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div
      v-if="
        activeTab != TAB_KEY.IDENTIFICATION_HISTORY && activeTab != TAB_KEY.MAP
      "
      class="px-16px grid grid-cols-10 gap-16px"
    >
      <div class="col-span-7 mt-16px pb-16px">
        <a-form
          ref="formRef"
          :model="formState"
          name="basic"
          :validate-trigger="['change', 'blur']"
          label-wrap
          label-align="left"
          :rules="rules"
          :scroll-to-first-error="{
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          }"
        >
          <div
            v-if="activeTab != TAB_KEY.IDENTIFICATION_HISTORY"
            class="relative bg-ems-gray800 pt-18px px-20px pb-28px"
          >
            <div v-if="isLoading" class="spin-custom">
              <cds-loading />
            </div>
            <div
              class="text-ems-gray200 text-16px mb-20px font-semibold uppercase"
            >
              {{ t('object_information.general_info') }}
            </div>
            <div class="grid grid-cols-3 gap-30px">
              <div>
                <a-form-item
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :label="t('object_information.object_type')"
                  :name="['objectType']"
                >
                  <cds-select
                    v-model:value="formState.objectType"
                    :options="objectTypeList"
                    :placeholder="
                      t('object_information.object_type_placeholder')
                    "
                    disabled
                  />
                </a-form-item>
              </div>
              <div>
                <a-form-item
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :label="t('object_information.object_name')"
                  :name="['name']"
                >
                  <cds-input
                    v-model:value="formState.name"
                    :maxlength="100"
                    :placeholder="
                      t('object_information.object_name_placeholder')
                    "
                    :disabled="formMode === FORM_MODE.VIEW"
                  />
                </a-form-item>
              </div>
              <div>
                <a-form-item
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :label="t('object_information.characteristics')"
                  :name="['characteristic']"
                >
                  <cds-input
                    v-model:value="formState.characteristic"
                    :show-count="true"
                    :maxlength="255"
                    :placeholder="
                      t('object_information.characteristic_placeholder')
                    "
                    :disabled="formMode === FORM_MODE.VIEW"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="h-1px w-full bg-ems-gray700 mt-28px"></div>
            <div
              class="text-ems-gray200 text-16px mb-20px font-semibold mt-14px"
            >
              {{ t('object_information.group_belong') }}
            </div>
            <div class="grid grid-cols-3 gap-30px">
              <div class="col-span-2 flex flex-wrap gap-16px">
                <div
                  v-if="formState.ioGroups.length < 1"
                  class="text-ems-main2"
                >
                  {{ t('object_information.not_belong_any_group') }}
                </div>
                <div
                  v-else
                  v-for="item in formState.ioGroups"
                  :key="item.id"
                  class="group tag bg-ems-main1 py-6px px-16px rounded-8px text-ems-white text-16px leading-25px relative"
                  :class="[
                    classGroupTag(item),
                    formMode !== FORM_MODE.VIEW ? '' : 'cursor-pointer',
                  ]"
                  :title="item.name"
                  @click="viewGroup(item)"
                >
                  {{
                    item.name.length > 20
                      ? `${item.name.substring(0, 20)}...`
                      : item.name
                  }}
                  <img
                    v-if="formMode !== FORM_MODE.VIEW"
                    src="../../static/img/icon/RemoveIcon.svg"
                    alt="RemoveIcon"
                    class="absolute -top-9px -right-9px cursor-pointer hidden group-hover:block"
                    @click="onDeleteGroup(item)"
                  />
                </div>
              </div>
              <div
                v-if="formMode !== FORM_MODE.VIEW"
                class="col-span-1 select-group-object"
              >
                <div
                  v-if="!isShowSelectGroup"
                  class="border !border-ems-main2 text-16px text-ems-main2 py-6px px-16px rounded-8px flex items-center gap-10px float-end cursor-pointer"
                  @click="onShowSelectGroup"
                >
                  <img
                    src="../../static/img/icon/IconAdd.svg"
                    alt="IconAdd"
                    class="w-24px h-24px"
                  />
                  {{ t('group-management.create-sub-group-title') }}
                </div>
                <SelectMultiGroup
                  v-else
                  class="w-full"
                  :tree-data="treeData"
                  :tree-flat="treeFlat"
                  :placeholder="t('object_information.group_placeholder')"
                  @on-selected="onAddGroup"
                />
              </div>
            </div>
          </div>
          <div class="mt-16px gap-16px">
            <div class="relative bg-ems-gray800 min-h-615px">
              <div v-if="isLoading" class="spin-custom">
                <cds-loading />
              </div>
              <FormInformation v-show="activeTab === TAB_KEY.INFO" />
              <FormViolate
                v-show="activeTab === TAB_KEY.VIOLATE"
                :disabled="formMode === FORM_MODE.VIEW"
              />
              <template
                v-if="
                  activeTab !== TAB_KEY.INFO && activeTab !== TAB_KEY.VIOLATE
                "
              >
                <Suspense>
                  <template #default>
                    <component :is="component" />
                  </template>
                  <template #fallback>
                    <div class="spin-custom">
                      <cds-loading />
                    </div>
                  </template>
                </Suspense>
              </template>
            </div>
          </div>
        </a-form>
      </div>
      <div class="col-span-3" v-if="!isLoading">
        <div class="bg-ems-gray800 w-full mt-16px pt-18px pb-34px px-20px">
          <div
            class="text-ems-gray200 text-16px mb-20px font-semibold uppercase"
          >
            {{ t('object_information.image') }}
          </div>
          <div class="w-full relative">
            <UploadListImage
              v-model:value="formState.imagesAvatar"
              :accept="ACCEPT_IMAGE_ONLY"
              :disabled="disabled"
              :is-edit-main-img="formMode !== FORM_MODE.VIEW"
            ></UploadListImage>
          </div>
        </div>
        <SimilarProfile
          v-if="formMode === FORM_MODE.UPDATE"
          ref="refSimilar"
          @mergeSuccess="getDetailObject"
        />
      </div>
    </div>
    <FormHistory v-if="activeTab === TAB_KEY.IDENTIFICATION_HISTORY" />
    <Map v-if="activeTab === TAB_KEY.MAP" :show-data="true" />
  </div>
  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
</template>
<script setup>
import {
  computed,
  defineAsyncComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ACCEPT_IMAGE_ONLY, TAB_KEY } from '@/util/common-constant';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { required } from '@/util/validation';
import UploadListImage from '@/components/upload-list-image/index.vue';
import SelectMultiGroup from '@/components/select-multi-group/SelectMultiGroup.vue';
import {
  FORM_MODE,
  OBJECT_TYPE,
  TRACING_PERM,
} from '../../util/common-constant';
import { WARNING_LEVEL } from '../../config/Constant';
import FormInformation from './components/FormInformation.vue';
import FormViolate from './components/FormViolate.vue';
import FormHistory from './components/FormHistory.vue';
import Map from './components/Map.vue';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { notification } from 'ant-design-vue';
import SimilarProfile from './components/SimilarProfile.vue';
import { copyContent, checkHasPermission } from '@/util/common-utils';

const FormGroup = defineAsyncComponent(() =>
  import('./components/FormGroup.vue')
);
const FormRelate = defineAsyncComponent(() =>
  import('./components/FormRelate.vue')
);
const FormSocial = defineAsyncComponent(() =>
  import('./components/FormSocial.vue')
);
const eventBus = inject('eventBus');
const { t } = useI18n();
const component = ref('');
const route = useRoute();
const router = useRouter();
const { state, dispatch } = useStore();
const activeTab = ref(route.query.tab ? route.query.tab : 'info');
const tabs = [
  {
    key: TAB_KEY.INFO,
    tabName: t('object_information.info'),
  },
  {
    key: TAB_KEY.VIOLATE,
    tabName: t('object_information.violate'),
  },
  {
    key: TAB_KEY.GROUP,
    tabName: t('object_information.group'),
    component: FormGroup,
  },
  {
    key: TAB_KEY.RELATE,
    tabName: t('object_information.relate'),
    component: FormRelate,
  },
  {
    key: TAB_KEY.SOCIAL,
    tabName: t('object_information.social_media_link'),
    component: FormSocial,
  },
  {
    key: TAB_KEY.IDENTIFICATION_HISTORY,
    tabName: t('object_information.ident-history'),
  },
  {
    key: TAB_KEY.MAP,
    tabName: t('object_information.journey-map'),
  },
];
const classGroupTag = (item) => {
  return {
    'bg-ems-main1': item.warningLevel === WARNING_LEVEL[0].id,
    'bg-ems-boTro2_600': item.warningLevel === WARNING_LEVEL[1].id,
    'bg-ems-boTro4_800': item.warningLevel === WARNING_LEVEL[2].id,
    'bg-ems-gray500': item.warningLevel === WARNING_LEVEL[3].id,
  };
};
watch(
  () => route.query.tab,
  () => {
    if (!route.query.tab) {
      activeTab.value = TAB_KEY.INFO;
    } else {
      activeTab.value = route.query.tab;
    }
    dispatch('ioManagement/setObjectInfoActiveTab', activeTab.value);
    tabs.forEach((item) => {
      if (item.key === activeTab.value) {
        component.value = item.component;
      }
    });
  },
  {
    immediate: true,
    deep: true,
  }
);

const perms = computed(() => state.auth.userInfo.permissions);
const dataForm = computed(() => state.ioManagement.formState);
const objectName = computed(() => state.ioManagement.objectName);
const formState = reactive(dataForm.value);

watch(
  () => dataForm.value,
  (val) => {
    formState.violation = val.violation;
    formState.objectType = val.objectType;
    formState.name = val.name;
    formState.characteristic = val.characteristic;
    formState.imagesAvatar = val.imagesAvatar;
    formState.imagesRelate = val.imagesRelate;
    formState.imagesRelateAdded = val.imagesRelateAdded;
    formState.imagesRelateDeleted = val.imagesRelateDeleted;
    formState.ioGroups = val.ioGroups;
    formState.relatedSuggestions = val.relatedSuggestions;
  },
  {
    deep: true,
  }
);
const rules = {
  objectType: [required(t('object_information.object_type'))],
  name: [required(t('object_information.object_name'))],
};
const objectTypeList = [
  { value: OBJECT_TYPE.HUMAN, label: t('object_information.human') },
  { value: OBJECT_TYPE.VEHICLE, label: t('object_information.vehicle') },
  { value: OBJECT_TYPE.LOCATION, label: t('object_information.location') },
  { value: OBJECT_TYPE.SYMBOL, label: t('object_information.symbol') },
];
const treeData = computed(() => state.ioManagement.treeGroup);
const treeFlat = computed(() => state.ioManagement.groups);
const formMode = computed(() => state.ioManagement.formMode);
const disabled = computed(() => formMode.value === FORM_MODE.VIEW);

const listIdDisabled = new Set();
const tabContainer = ref(null);
const containerWidth = ref(0);
const getContainerWidth = () => {
  if (tabContainer.value) {
    containerWidth.value = tabContainer.value.clientWidth;
  }
};
const isBreakLine = computed(() => {
  return containerWidth.value - 750 < 500;
});
onMounted(async () => {
  isLoading.value = true;
  dispatch(
    'setTitleHeader',
    t('object_information.object_information_management')
  );
  let promises = [
    dispatch('ioManagement/setFormMode', Number(route.query.mode)),
    dispatch('ioManagement/getTreeGroup'),
    getDetailObject(),
    dispatch('ioManagement/getDetailObjRelate', { id: route.query.id }),
  ];
  Promise.all(promises);
  new ResizeObserver(getContainerWidth).observe(tabContainer.value);
});
const isLoading = ref(false);
const getDetailObject = async () => {
  const response = await dispatch('ioManagement/getDetail', {
    id: route.query.id,
    type: route.query.type,
  });
  if (response) {
    isLoading.value = false;
    formState.ioGroups.forEach((item) => {
      addDisabledId(item.groupId, item.parentId);
    });
    for (const item of formState.ioGroups) {
      await dispatch('ioManagement/getTreeById', {
        id: item.groupId,
        name: item.name,
      });
    }
    disableNode(treeData.value);
  } else {
    await router.push({
      path: '/io-management/object-management',
    });
    notification.error({
      message: t('common.notification'),
      description: t('object_information.no_profile_found'),
      duration: 4,
    });
  }
};

const addDisabledId = (id, parentId) => {
  listIdDisabled.add(id);
  // Thêm tất cả children
  const listChildren = treeFlat.value.filter((item) => item.parentId === id);
  addAllChildToListDisabled(listChildren);
  // Thêm các node cùng cha
  if (parentId) {
    listIdDisabled.add(parentId);
    treeFlat.value.forEach((item) => {
      if (item.parentId === parentId) {
        listIdDisabled.add(item.id);
      }
    });
    addAllChildFromParent(parentId);
  }
};
// Hàm đệ quy thêm tất cả children
const addAllChildToListDisabled = (list) => {
  for (let item of list) {
    listIdDisabled.add(item.id);
    if (item.children && item.children.length > 0) {
      addAllChildToListDisabled(item.children);
    }
  }
};
// Hàm đệ quy thêm tất cả children từ node gốc
const addAllChildFromParent = (parentId) => {
  const parentObject = treeFlat.value.filter((item) => item.id === parentId)[0];
  listIdDisabled.add(parentObject.id);
  const listChildren = treeFlat.value.filter(
    (item) => item.parentId === parentObject.id
  );
  addAllChildToListDisabled(listChildren);
  if (parentObject.parentId) {
    addAllChildFromParent(parentObject.parentId);
  }
};

const removeDisabledId = (id, parentId) => {
  listIdDisabled.delete(id);
  // Bỏ tất cả children
  const listChildren = treeFlat.value.filter((item) => item.parentId === id);
  removeAllChildToListDisabled(listChildren);
  // Bỏ các node cùng cha
  if (parentId) {
    listIdDisabled.delete(parentId);
    treeFlat.value.forEach((item) => {
      if (item.parentId === parentId) {
        listIdDisabled.delete(item.id);
      }
    });
    removeAllChildFromParent(parentId);
  }
};
// Hàm đệ quy bỏ tất cả children
const removeAllChildToListDisabled = (list) => {
  for (let item of list) {
    listIdDisabled.delete(item.id);
    if (item.children && item.children.length > 0) {
      removeAllChildToListDisabled(item.children);
    }
  }
};
// Hàm đệ quy bỏ tất cả children từ node gốc
const removeAllChildFromParent = (parentId) => {
  const parentObject = treeFlat.value.filter((item) => item.id === parentId)[0];
  listIdDisabled.delete(parentObject.id);
  const listChildren = treeFlat.value.filter(
    (item) => item.parentId === parentObject.id
  );
  removeAllChildToListDisabled(listChildren);
  if (parentObject.parentId) {
    removeAllChildFromParent(parentObject.parentId);
  }
};
const disableNode = (list) => {
  for (let item of list) {
    item.disabled = listIdDisabled.has(item.id);
    if (item.children && item.children.length > 0) {
      disableNode(item.children);
    }
  }
};
const onAddGroup = (value) => {
  formState.ioGroups = [...formState.ioGroups, value];
  dispatch('ioManagement/getTreeById', { id: value.id, name: value.name });
  addDisabledId(value.id, value.parentId);
  disableNode(treeData.value);
  isShowSelectGroup.value = false;
};
const onDeleteGroup = (value) => {
  formState.ioGroups = formState.ioGroups.filter(
    (item) => item.id !== value.id
  );
  dispatch('ioManagement/removeTree', value.id);
  removeDisabledId(value.id, value.parentId);
  disableNode(treeData.value);
};
const onChangeTab = (key) => {
  if (key === TAB_KEY.GROUP) {
    dispatch('ioManagement/setToFirstTabGroup');
  }
  router.push({
    path: '/io-management/object-management/edit',
    query: { ...route.query, tab: key },
  });
  window.scrollTo({ top: 0, behavior: 'instant' });
};
const emitEventValidate = (isCallApi) => {
  dispatch('ioManagement/updateDataFormState', formState);
  switch (formState.objectType) {
    case OBJECT_TYPE.HUMAN:
      eventBus.emit('validate-human', isCallApi);
      break;
    case OBJECT_TYPE.VEHICLE:
      eventBus.emit('validate-vehicle', isCallApi);
      break;
    case OBJECT_TYPE.LOCATION:
      eventBus.emit('validate-location', isCallApi);
      break;
    case OBJECT_TYPE.SYMBOL:
      eventBus.emit('validate-symbol', isCallApi);
      break;
    default:
      eventBus.emit('validate-child', isCallApi);
  }
};
const formRef = ref(null);
const onSave = () => {
  formRef.value
    .validate()
    .then(() => {
      emitEventValidate(true);
    })
    .catch((err) => {
      emitEventValidate(false);
      formRef.value.scrollToField(err.errorFields[0].name, {
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    });
};
const onBack = () => {
  if (window.history.state.back) {
    history.back();
  } else {
    router.push({
      path: '/io-management/object-management',
      query: {
        page: route.query.page ? route.query.page : 1,
        searchName: route.query.searchName ? route.query.searchName : null,
        searchType: route.query.searchType ? route.query.searchType : null,
        searchGroupId: route.query.searchGroupId
          ? route.query.searchGroupId
          : null,
        filterType: route.query.filterType ? route.query.filterType : null,
        filterGroup: route.query.filterGroup ? route.query.filterGroup : null,
      },
    });
  }
};
const onClose = () => {
  router.push({
    path: '/io-management/object-management',
    query: {
      page: route.query.page ? route.query.page : 1,
      searchName: route.query.searchName ? route.query.searchName : null,
      searchType: route.query.searchType ? route.query.searchType : null,
      searchGroupId: route.query.searchGroupId
        ? route.query.searchGroupId
        : null,
      filterType: route.query.filterType ? route.query.filterType : null,
      filterGroup: route.query.filterGroup ? route.query.filterGroup : null,
    },
  });
};
const showConfirmCustom = ref(null);
const onTracing = async (id) => {
  if (!formState.tracing) {
    if (await showConfirmCustom.value.onOpenModal('common.add_to_tracing')) {
      const response = await dispatch('ioManagement/tracingObject', id);
      if (response) {
        notification.success({
          message: t('common.notification'),
          description: t('object_tracing.add_tracing_profile_success'),
          duration: 4,
        });
      }
      await router.push({
        path: '/trace-management/object-tracing/detail',
        query: {
          id: id,
          tab: TAB_KEY.INFO,
        },
      });
    }
  } else {
    await router.push({
      path: '/trace-management/object-tracing/detail',
      query: {
        id: id,
        tab: TAB_KEY.INFO,
      },
    });
  }
};
const isShowSelectGroup = ref(false);
const onShowSelectGroup = () => {
  isShowSelectGroup.value = true;
};
const refSimilar = ref(null);
onUnmounted(() => {
  dispatch('ioManagement/resetForm');
});
const viewGroup = (item) => {
  if (formMode.value === FORM_MODE.VIEW) {
    router.push({
      path: '/io-management/group-management',
      query: { idGroup: item.id },
    });
  }
};
</script>
<style lang="scss">
.detail-profile .tag:hover {
  transition: 0.2s;
  transform: scale(1.1);
}
.detail-profile .select-group-object .ant-select {
  height: 38px;
}
.detail-profile .select-group-object .ant-select-selector {
  background-color: #373737 !important;
  border: none !important;
  border-radius: 8px !important;
}
.detail-profile .select-group-object .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.detail-profile .select-group-object .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
.tabs-object-information .ant-tabs-nav-list {
  padding: 0 5px;
}
</style>
