<template>
  <a-form
    ref="formRef"
    :model="formState"
    name="basic"
    :validate-trigger="['change', 'blur']"
    label-wrap
    label-align="left"
    :rules="rules"
    :scroll-to-first-error="{
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    }"
  >
    <div class="bg-ems-gray1000">
      <div
        ref="tabContainer"
        class="flex justify-between bg-ems-gray900 pl-16px pr-24px border-b-1 border-solid border-ems-gray700 sticky top-60px z-1100 relative"
      >
        <div :class="isBreakLine ? 'pt-60px' : 'pt-26px'" class="w-[75%]">
          <a-tabs
            v-model:activeKey="activeTab"
            class="w-[100%] tabs-object-information"
            @change="onChangeTab($event)"
          >
            <template #moreIcon>
              <cds-feather-icons
                type="more-horizontal"
                size="18"
                class="text-ems-white mr-2"
              />
            </template>
            <a-tab-pane
              v-for="item in tabs"
              :key="item.key"
              :tab="item.tabName"
            >
            </a-tab-pane>
          </a-tabs>
        </div>
        <div
          class="items-center justify-center gap-16px flex absolute right-24px top-20px"
        >
          <ClipButton
            class="flex items-center !px-10"
            :type="'primary'"
            @click="onSave"
          >
            <span>{{ t('common.save') }}</span>
          </ClipButton>
          <ClipButton
            class="flex items-center !px-10"
            :type="'primary'"
            @click="onClose"
          >
            <span>{{ t('common.close') }}</span>
          </ClipButton>
        </div>
      </div>
      <div
        v-if="activeTab != TAB_KEY.MAP"
        class="px-16px grid grid-cols-10 gap-16px"
      >
        <div class="col-span-7 mt-16px pb-16px">
          <div class="bg-ems-gray800 pt-18px px-20px pb-28px">
            <div
              class="text-ems-gray200 text-16px mb-20px font-semibold uppercase"
            >
              {{ t('object_information.general_info') }}
            </div>
            <div class="grid grid-cols-3 gap-30px">
              <div>
                <a-form-item
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :label="t('object_information.object_type')"
                  :name="['objectType']"
                >
                  <cds-select
                    v-model:value="formState.objectType"
                    :options="objectTypeList"
                    :placeholder="
                      t('object_information.object_type_placeholder')
                    "
                  />
                </a-form-item>
              </div>
              <div>
                <a-form-item
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :label="t('object_information.object_name')"
                  :name="['name']"
                >
                  <cds-input
                    v-model:value="formState.name"
                    :maxlength="100"
                    :placeholder="
                      t('object_information.object_name_placeholder')
                    "
                  />
                </a-form-item>
              </div>
              <div>
                <a-form-item
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  :label="t('object_information.characteristics')"
                  :name="['characteristic']"
                >
                  <cds-input
                    v-model:value="formState.characteristic"
                    :show-count="true"
                    :maxlength="255"
                    :placeholder="
                      t('object_information.characteristic_placeholder')
                    "
                  />
                </a-form-item>
              </div>
            </div>
            <div class="h-1px w-full bg-ems-gray700 mt-28px"></div>
            <div
              class="text-ems-gray200 text-16px mb-20px font-semibold mt-14px"
            >
              {{ t('object_information.group_belong') }}
            </div>
            <div class="grid grid-cols-3 gap-30px">
              <div class="col-span-2 flex flex-wrap gap-16px">
                <div
                  v-if="formState.ioGroups.length < 1"
                  class="text-ems-main2"
                >
                  {{ t('object_information.not_belong_any_group') }}
                </div>
                <div
                  v-else
                  v-for="item in formState.ioGroups"
                  :key="item.id"
                  class="group bg-ems-main1 py-6px px-16px rounded-8px text-ems-white text-16px leading-25px relative"
                  :class="{
                    'bg-ems-main1': item.warningLevel === WARNING_LEVEL[0].id,
                    'bg-ems-boTro2_600':
                      item.warningLevel === WARNING_LEVEL[1].id,
                    'bg-ems-boTro4_800':
                      item.warningLevel === WARNING_LEVEL[2].id,
                    'bg-ems-gray500': item.warningLevel === WARNING_LEVEL[3].id,
                  }"
                  :title="item.name"
                >
                  {{
                    item.name > 20
                      ? `${item.name.substring(0, 20)}...`
                      : item.name
                  }}
                  <img
                    src="../../static/img/icon/RemoveIcon.svg"
                    alt="RemoveIcon"
                    class="absolute -top-9px -right-9px cursor-pointer hidden group-hover:block"
                    @click="onDeleteGroup(item)"
                  />
                </div>
              </div>
              <div class="col-span-1 select-group-object">
                <div
                  v-if="!isShowSelectGroup"
                  class="border !border-ems-main2 text-16px text-ems-main2 py-6px px-16px rounded-8px flex items-center gap-10px float-end cursor-pointer"
                  @click="onShowSelectGroup"
                >
                  <img
                    src="../../static/img/icon/IconAdd.svg"
                    alt="IconAdd"
                    class="w-24px h-24px"
                  />
                  {{ t('group-management.create-sub-group-title') }}
                </div>
                <SelectMultiGroup
                  v-else
                  class="w-full"
                  :tree-data="treeData"
                  :tree-flat="treeFlat"
                  :placeholder="t('object_information.group_placeholder')"
                  @on-selected="onAddGroup"
                />
              </div>
            </div>
          </div>
          <div class="mt-16px gap-16px">
            <div class="relative bg-ems-gray800 min-h-615px">
              <FormInformation v-show="activeTab === TAB_KEY.INFO" />
              <FormViolate v-show="activeTab === TAB_KEY.VIOLATE" />
              <!--                  <FormGroup v-if="activeTab === TAB_KEY.GROUP" />-->
              <!--                  <FormRelate v-if="activeTab === TAB_KEY.RELATE" />-->
              <!--                  <FormSocial v-if="activeTab === TAB_KEY.SOCIAL" />-->
              <template
                v-if="
                  activeTab !== TAB_KEY.INFO && activeTab !== TAB_KEY.VIOLATE
                "
              >
                <Suspense>
                  <template #default>
                    <component :is="component" />
                  </template>
                  <template #fallback>
                    <div class="spin-custom">
                      <cds-loading />
                    </div>
                  </template>
                </Suspense>
              </template>
            </div>
          </div>
        </div>
        <div class="col-span-3">
          <div class="bg-ems-gray800 w-full mt-16px pt-18px pb-34px px-20px">
            <div
              class="text-ems-gray200 text-16px mb-20px font-semibold uppercase"
            >
              {{ t('object_information.image') }}
            </div>
            <div class="w-full relative">
              <UploadListImage
                v-model:value="formState.imagesAvatar"
                :accept="ACCEPT_IMAGE_ONLY"
                @change="onChangeImg"
              ></UploadListImage>
            </div>
          </div>
          <div
            v-show="
              formState.imagesAvatar &&
              formState.imagesAvatar.length > 0 &&
              isShowSimilar
            "
          >
            <SimilarProfileCreate
              ref="refSimilar"
              :img-avatar="formState.imagesAvatar"
              :is-loading="isLoading"
            />
          </div>
        </div>
      </div>
      <Map v-if="activeTab === TAB_KEY.MAP" :show-data="false" />
    </div>
  </a-form>
</template>
<script setup>
import {
  defineAsyncComponent,
  onMounted,
  reactive,
  ref,
  watch,
  inject,
  computed,
  onUnmounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ACCEPT_IMAGE_ONLY, TAB_KEY } from '@/util/common-constant';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { required } from '@/util/validation';
import UploadListImage from '@/components/upload-list-image/index.vue';
import SelectMultiGroup from '@/components/select-multi-group/SelectMultiGroup.vue';
import { FORM_MODE, OBJECT_TYPE } from '../../util/common-constant';
import { useForm } from 'ant-design-vue/es/form';
import { WARNING_LEVEL } from '../../config/Constant';
import FormInformation from './components/FormInformation.vue';
import FormViolate from './components/FormViolate.vue';
import Map from './components/Map.vue';
import SimilarProfileCreate from './components/SimilarProfileCreate.vue';

const FormGroup = defineAsyncComponent(() =>
  import('./components/FormGroup.vue')
);
const FormRelate = defineAsyncComponent(() =>
  import('./components/FormRelate.vue')
);
const FormSocial = defineAsyncComponent(() =>
  import('./components/FormSocial.vue')
);

const eventBus = inject('eventBus');
const { t } = useI18n();
const component = ref('');
const route = useRoute();
const router = useRouter();
const { state, dispatch } = useStore();
const activeTab = ref(route.query.tab ? route.query.tab : TAB_KEY.INFO);
const tabs = [
  {
    key: TAB_KEY.INFO,
    tabName: t('object_information.info'),
  },
  {
    key: TAB_KEY.VIOLATE,
    tabName: t('object_information.violate'),
  },
  {
    key: TAB_KEY.GROUP,
    tabName: t('object_information.group'),
    component: FormGroup,
  },
  {
    key: TAB_KEY.RELATE,
    tabName: t('object_information.relate'),
    component: FormRelate,
  },
  {
    key: TAB_KEY.SOCIAL,
    tabName: t('object_information.social_media_link'),
    component: FormSocial,
  },
  {
    key: TAB_KEY.MAP,
    tabName: t('object_information.journey-map'),
  },
];
watch(
  () => route.query,
  () => {
    if (!route.query.tab) {
      activeTab.value = TAB_KEY.INFO;
    } else {
      activeTab.value = route.query.tab;
    }
    dispatch('ioManagement/setObjectInfoActiveTab', activeTab.value);
    tabs.forEach((item) => {
      if (item.key === activeTab.value) {
        component.value = item.component;
      }
    });
  },
  {
    immediate: true,
    deep: true,
  }
);
const tabContainer = ref(null);
const containerWidth = ref(0);
const getContainerWidth = () => {
  if (tabContainer.value) {
    containerWidth.value = tabContainer.value.clientWidth;
  }
};
const isBreakLine = computed(() => {
  return containerWidth.value - 662 < 266;
});
onMounted(() => {
  dispatch(
    'setTitleHeader',
    t('object_information.object_information_management')
  );
  dispatch('ioManagement/getTreeGroup');
  dispatch('ioManagement/setFormMode', FORM_MODE.CREATE);
  new ResizeObserver(getContainerWidth).observe(tabContainer.value);
});
const formState = reactive(state.ioManagement.formState);
const rules = {
  objectType: [required(t('object_information.object_type'))],
  name: [required(t('object_information.object_name'))],
};
const objectTypeList = [
  { value: OBJECT_TYPE.HUMAN, label: t('object_information.human') },
  { value: OBJECT_TYPE.VEHICLE, label: t('object_information.vehicle') },
  { value: OBJECT_TYPE.LOCATION, label: t('object_information.location') },
  { value: OBJECT_TYPE.SYMBOL, label: t('object_information.symbol') },
];
const treeData = computed(() => state.ioManagement.treeGroup);
const treeFlat = computed(() => state.ioManagement.groups);
const listIdDisabled = new Set();
const addDisabledId = (id, parentId) => {
  listIdDisabled.add(id);
  // Thêm tất cả children
  const listChildren = treeFlat.value.filter((item) => item.parentId === id);
  addAllChildToListDisabled(listChildren);
  // Thêm các node cùng cha
  if (parentId) {
    listIdDisabled.add(parentId);
    treeFlat.value.forEach((item) => {
      if (item.parentId === parentId) {
        listIdDisabled.add(item.id);
      }
    });
    addAllChildFromParent(parentId);
  }
};
// Hàm đệ quy thêm tất cả children
const addAllChildToListDisabled = (list) => {
  for (let item of list) {
    listIdDisabled.add(item.id);
    if (item.children && item.children.length > 0) {
      addAllChildToListDisabled(item.children);
    }
  }
};
// Hàm đệ quy thêm tất cả children từ node gốc
const addAllChildFromParent = (parentId) => {
  const parentObject = treeFlat.value.filter((item) => item.id === parentId)[0];
  listIdDisabled.add(parentObject.id);
  const listChildren = treeFlat.value.filter(
    (item) => item.parentId === parentObject.id
  );
  addAllChildToListDisabled(listChildren);
  if (parentObject.parentId) {
    addAllChildFromParent(parentObject.parentId);
  }
};

const removeDisabledId = (id, parentId) => {
  listIdDisabled.delete(id);
  // Bỏ tất cả children
  const listChildren = treeFlat.value.filter((item) => item.parentId === id);
  removeAllChildToListDisabled(listChildren);
  // Bỏ các node cùng cha
  if (parentId) {
    listIdDisabled.delete(parentId);
    treeFlat.value.forEach((item) => {
      if (item.parentId === parentId) {
        listIdDisabled.delete(item.id);
      }
    });
    removeAllChildFromParent(parentId);
  }
};
// Hàm đệ quy bỏ tất cả children
const removeAllChildToListDisabled = (list) => {
  for (let item of list) {
    listIdDisabled.delete(item.id);
    if (item.children && item.children.length > 0) {
      removeAllChildToListDisabled(item.children);
    }
  }
};
// Hàm đệ quy bỏ tất cả children từ node gốc
const removeAllChildFromParent = (parentId) => {
  const parentObject = treeFlat.value.filter((item) => item.id === parentId)[0];
  listIdDisabled.delete(parentObject.id);
  const listChildren = treeFlat.value.filter(
    (item) => item.parentId === parentObject.id
  );
  removeAllChildToListDisabled(listChildren);
  if (parentObject.parentId) {
    removeAllChildFromParent(parentObject.parentId);
  }
};
const disableNode = (list) => {
  for (let item of list) {
    item.disabled = listIdDisabled.has(item.id);
    if (item.children && item.children.length > 0) {
      disableNode(item.children);
    }
  }
};
const onAddGroup = (value) => {
  formState.ioGroups = [...formState.ioGroups, value];
  dispatch('ioManagement/getTreeById', { id: value.id, name: value.name });
  addDisabledId(value.id, value.parentId);
  disableNode(treeData.value);
  isShowSelectGroup.value = false;
};
const onDeleteGroup = (value) => {
  formState.ioGroups = formState.ioGroups.filter(
    (item) => item.id !== value.id
  );
  dispatch('ioManagement/removeTree', value.id);
  removeDisabledId(value.id, value.parentId);
  disableNode(treeData.value);
};
const onChangeTab = (key) => {
  if (key === TAB_KEY.GROUP) {
    dispatch('ioManagement/setToFirstTabGroup');
  }
  router.push({
    path: '/io-management/object-management/create',
    query: { tab: key },
  });
  window.scrollTo({ top: 0, behavior: 'instant' });
};
const emitEventValidate = (isCallApi) => {
  switch (formState.objectType) {
    case OBJECT_TYPE.HUMAN:
      eventBus.emit('validate-human', isCallApi);
      break;
    case OBJECT_TYPE.VEHICLE:
      eventBus.emit('validate-vehicle', isCallApi);
      break;
    case OBJECT_TYPE.LOCATION:
      eventBus.emit('validate-location', isCallApi);
      break;
    case OBJECT_TYPE.SYMBOL:
      eventBus.emit('validate-symbol', isCallApi);
      break;
    default:
      eventBus.emit('validate-child', isCallApi);
  }
};
const { validate } = useForm(formState, rules);
const onSave = () => {
  validate()
    .then(() => {
      emitEventValidate(true);
    })
    .catch(() => {
      emitEventValidate(false);
    });
};
const isShowSelectGroup = ref(false);
const onShowSelectGroup = () => {
  isShowSelectGroup.value = true;
};
const onClose = () => {
  router.push({
    path: '/io-management/object-management',
    query: {
      page: route.query.page ? route.query.page : 1,
      searchName: route.query.searchName ? route.query.searchName : null,
      searchType: route.query.searchType ? route.query.searchType : null,
      searchImage: route.query.searchImage ? route.query.searchImage : '',
      searchGroupId: route.query.searchGroupId
        ? route.query.searchGroupId
        : null,
    },
  });
};
const isShowSimilar = ref(false);
const refSimilar = ref(null);
const isLoading = ref(false);
const onChangeImg = async () => {
  const mainImg = formState.imagesAvatar.filter((item) => item.isMain)[0];
  isLoading.value = true;
  await dispatch('ioManagement/getListSimilarCreate', {
    keyword: refSimilar.value ? refSimilar.value.keywordSearchSimilar : null,
    imgUrl: mainImg ? mainImg.url : null,
  });
  isLoading.value = false;
  isShowSimilar.value = !!(
    formState.imagesAvatar && formState.imagesAvatar.length > 0
  );
};
onUnmounted(() => {
  dispatch('ioManagement/resetForm');
});
</script>
<style>
.select-group-object .ant-select {
  height: 38px;
}
.select-group-object .ant-select-selector {
  background-color: #373737 !important;
  border: none !important;
  border-radius: 8px !important;
}
.select-group-object .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.select-group-object .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
</style>
