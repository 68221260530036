<template>
  <a-table
    ref="editTable"
    class="simple-table"
    :columns="columns"
    :data-source="dataSource"
    :pagination="false"
    :scroll="{ x: 1000 }"
    bordered
  >
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.type === 'input'">
        <a-form-item
          v-if="editableData[record.id]"
          :name="[...(column.formParentName || []), index, column.dataIndex]"
          :rules="column.rules"
          :label-col="{ span: 24 }"
        >
          <a-input
            class="ant-input-sm text-ems-gray400"
            v-model:value="record[column.dataIndex]"
            style="margin: -5px 0"
            @pressEnter.prevent=""
            :maxlength="255"
            show-count
            allow-clear
          />
        </a-form-item>
        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
      <template v-if="column.key === 'action'">
        <div class="flex justify-center gap-10px">
          <a-popconfirm
            v-if="!editableData[record.id]"
            class="popconfirm"
            :title="t('common.delete_confirm')"
            :ok-text="t('common.yes')"
            :cancel-text="t('common.no')"
            @confirm="deleteRow(record.id)"
            overlayClassName="popup-confirm-tb-edit"
          >
            <a-button
              :disabled="disabledAction"
              class="p-0 bg-transparent border-none"
            >
              <img
                src="../../static/img/icon/DeleteRowIcon.svg"
                alt="DeleteRowIcon"
              />
            </a-button>
          </a-popconfirm>
          <a-button
            class="p-0 bg-transparent border-none"
            v-if="!disabledAction && editableData[record.id]"
            @click="revertRow(record.id)"
          >
            <img
              src="../../static/img/icon/revert-icon.png"
              alt="EditRowIcon"
            />
          </a-button>
          <a-button
            class="p-0 bg-transparent border-none"
            v-if="!disabledAction && editableData[record.id]"
            @click="doneEditRow(record)"
          >
            <img src="../../static/img/icon/done-icon.png" alt="EditRowIcon" />
          </a-button>
          <a-button
            class="p-0 bg-transparent border-none"
            :disabled="disabledAction"
            v-if="!editableData[record.id]"
            @click="editRow(record.id)"
          >
            <img
              src="../../static/img/icon/EditRowIcon.svg"
              alt="EditRowIcon"
            />
          </a-button>
        </div>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import VueTypes from 'vue-types';
import { onMounted, reactive, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';

const { t } = useI18n();
const props = defineProps({
  columns: VueTypes.array.def([]),
  data: VueTypes.object.def([]),
  disabledAction: VueTypes.bool.def(false),
  searchKey: VueTypes.string.def(''),
});
const emit = defineEmits(['deleteRow', 'doneEditRow', 'revertRow']);
const editableData = reactive({});
const columnsVal = ref(props.columns);
const dataSource = computed(() => {
  let arr = [];
  props.data.map((item) => {
    if (
      item.charge.toUpperCase().indexOf(props.searchKey.toUpperCase()) > -1 ||
      item.decisionNoDate.toUpperCase().indexOf(props.searchKey.toUpperCase()) >
        -1 ||
      item.decisionUnit.toUpperCase().indexOf(props.searchKey.toUpperCase()) >
        -1
    ) {
      arr.push(item);
    }
  });
  return arr;
});
onMounted(() => {
  columnsVal.value.unshift({
    title: t('common.stt'),
    align: 'center',
    width: '50px',
    fixed: 'right',
    customRender: (row) => {
      return row.index + 1;
    },
  });
});
const editRow = (id) => {
  editableData[id] = _.cloneDeep(
    props.data.filter((item) => id === item.id)[0]
  );
};
const deleteRow = (id) => {
  emit('deleteRow', id);
};
const revertRow = (id) => {
  emit('revertRow', { id: id, data: editableData[id] });
};
const doneEditRow = (data) => {
  if (
    data.charge !== '' &&
    data.decisionNoDate !== '' &&
    data.decisionUnit !== ''
  ) {
    delete editableData[data.id];
    emit('doneEditRow', data);
  }
};
defineExpose({
  editRow,
});
</script>
<style>
.simple-table .ant-table-container {
  --tw-border-opacity: 1 !important;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity)) !important;
}
.simple-table .ant-table-content {
  border-radius: 0;
}
.simple-table .ant-table {
  border-radius: 0;
  --tw-border-opacity: 1 !important;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity)) !important;
}
.simple-table .ant-table table {
  --tw-border-opacity: 1 !important;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity)) !important;
  border-radius: 0 !important;
}
.simple-table .ant-table table .ant-table-thead .ant-table-cell {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.simple-table .ant-table table .ant-table-tbody .ant-table-cell {
  --tw-text-opacity: 1;
  color: rgba(157, 157, 157, var(--tw-text-opacity));
}
.simple-table .ant-table table .ant-table-cell {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  --tw-border-opacity: 1 !important;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity)) !important;
  border-radius: 0 !important;
}
.simple-table .ant-table-content
    .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.simple-table .ant-input-sm {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.simple-table .ant-input-sm .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.simple-table .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .simple-table .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity)) !important;
}
.simple-table .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper, .simple-table .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.simple-table .ant-form-item-explain-connected {
  height: auto;
  min-height: 24px;
  opacity: 1;
}
.simple-table .ant-table-cell {
  vertical-align: top;
}
.popup-confirm-tb-edit .ant-popover-arrow-content {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.popup-confirm-tb-edit .ant-popover-message-title {
  --tw-text-opacity: 1;
  color: rgba(180, 180, 180, var(--tw-text-opacity));
}
</style>
