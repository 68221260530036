<template>
  <cds-main class="">
    <cds-cards title="Search">
      <a-form
          ref="formRef"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
          :rules="rules"
          :validate-trigger="['change', 'blur']"
      >
        <a-row :gutter="30">
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <a-form-item label="Name" name="roleName">
              <cds-input class="ant-input-sm" v-model:value="formState.roleName" :maxlength="15" show-count
                         size="small"
                         placeholder="Name"
                         @press-enter="onSearch"/>
            </a-form-item>
          </a-col>
          <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24">
            <cds-from-to-date
                :disabled-date="disabledDate"
                v-model:dateFrom="formState.dateFrom"
                v-model:dateTo="formState.dateTo"
            />
          </a-col>
        </a-row>
      </a-form>
      <cds-search-form-button
          :buttons="buttonsForm"
          @onClear="resetForm"
          @onSearch="onSearch"
          @onCreate="preCreate"
          @onDelete="onDelete"
      />
    </cds-cards>
    <cds-cards title="Role list">
      <cds-table
          ref="table"
          :has-checkbox="true"
          :index-column="true"
          :pagination="true"
          :columns="columns"
          :api-fetch="apiFetch"
          :param-fetch="paramFetch"
      >
        <template #custom-body="{column, record}">
          <template v-if="column.key === 'action' && record">
            <action-button
                :buttons="buttonActions"
                @onEdit="onEdit(record)"
                @onView="onView(record)"
            />
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

  <cds-modal
      :title="titleModal"
      :visible="visible"
      :loading="loading"
      :mask-closable="false"
      hide-footer
      @onCancel="visible = false"
  >
    <form-modal ref="modal" @saveSuccess="saveSuccess"></form-modal>
  </cds-modal>
</template>

<script src="./role-controller.js"></script>

<style scoped>



</style>
