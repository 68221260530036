<template>
  <cds-main class="!pt-10 list-device">
    <div
      class="mb-3 mr-6 ml-4 flex justify-between items-center gap-x-[32px] gap-y-3"
    >
      <cds-from-to-date
        :disabled-date="disabledDate"
        v-model:dateFrom="formSearch.dateFrom"
        v-model:dateTo="formSearch.dateTo"
        :is-show-label="false"
      />
      <a-input
        size="large"
        :placeholder="t('group-management.placeholder-input-search')"
        v-model:value="formSearch.searchKey"
        class="w-[250px] ant-input-sm rounded-[8px] bg-ems-tag1 border-none"
        @press-enter="onSearch"
      >
        <template #prefix>
          <img
            src="@/static/img/group-management/input-search.svg"
            alt="input-suffix"
            height="16"
            width="16"
          />
        </template>
      </a-input>

      <div class="flex items-center gap-4">
        <ClipButton
          type="primary"
          size="small"
          style="height: fit-content"
          class="w-[120px]"
          @click="onDelete"
        >
          <cds-feather-icons
            type="trash-2"
            size="18"
            class="text-ems-white mr-2"
          />
          <span class="text-sm text-ems-white">{{ $t('common.delete') }}</span>
        </ClipButton>
        <ClipButton
          type="primary"
          size="small"
          style="height: fit-content"
          class="w-[120px]"
          @click="createGroup"
        >
          <cds-feather-icons
            type="plus"
            size="18"
            class="text-ems-white mr-2"
          />
          <span class="text-sm text-ems-white">{{ $t('common.add') }}</span>
        </ClipButton>
        <a-upload name="file" :multiple="false" class="h-[40px]">
          <ClipButton
            type="primary"
            size="small"
            style="height: fit-content"
            class="w-[150px]"
          >
            <img
              src="@/static/img/icon/icon-up.svg"
              alt="icon-up"
              height="24"
              width="24"
            />
            <span class="text-sm text-ems-white ml-1 whitespace-nowrap">{{
              $t('common.export-file')
            }}</span>
          </ClipButton>
        </a-upload>
      </div>
    </div>
    <div class="h-[calc(100vh-41px-40px-16px-60px)]">
      <cds-table
        ref="table"
        :has-checkbox="true"
        :index-column="true"
        :pagination="true"
        :columns="columns"
        :data-source="data"
      >
        <!-- :api-fetch="apiFetch"
        :param-fetch="paramFetch" -->
        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'action' && record">
            <ActionButton
              :buttons="buttonActions"
              @onEdit="onEdit(record)"
              @onView="onView(record)"
            />
          </template>

          <template v-if="column.key === 'status' && record">
            <tag
              tag-type="colorful"
              :color="record.status === 1 ? '#006400' : '#B5122E'"
              :text="
                record.status === 1 ? t('device.active') : t('device.inactive')
              "
            ></tag>
          </template>
        </template>
      </cds-table>
    </div>
    <div
      class="absolute top-[53%] right-0 z-60 icon-expand-drawer cursor-pointer"
      @click="openSearchAdvanced"
      v-if="isSave"
    />
    <drawer-custom
      :visible="openDrawer"
      :width="450"
      @onClose="onClose"
      @onCancel="openDrawer = false"
      :component="FormModal"
      :form-state="formState"
      @onSearchAdvanced="onSaveDevice"
      :title="title"
      @onHide="onHide"
    />
  </cds-main>
</template>

<script setup>
import {
  onMounted,
  reactive,
  ref,
  toRaw,
  defineAsyncComponent,
  computed,
} from 'vue';
// import ConstantAPI from '@/config/ConstantAPI';
import { useForm } from 'ant-design-vue/es/form';
import { useStore } from 'vuex';
import { maxlength } from '@/util/validation';
import _ from 'lodash';
import ClipButton from '@/components/buttons/ClipButton.vue';
import Tag from '../../../components/tags/Tag';
import { useI18n } from 'vue-i18n';
import ActionButton from '../../../components/action-button';
import dayjs from 'dayjs';
import { ACTION } from '@/config/Constant.js';
// const apiFetch = ConstantAPI.role.SEARCH;
const { t } = useI18n();
const DrawerCustom = defineAsyncComponent(() =>
  import('@/components/drawer-custom/index.vue')
);
const FormModal = defineAsyncComponent(() =>
  import('./components/FormModal.vue')
);
const columns = ref([
  {
    title: t('device.name'),
    dataIndex: 'name',
    key: 'name',
    resizable: true,
    width: 250,
  },
  {
    title: t('device.code'),
    dataIndex: 'code',
    resizable: true,
    key: 'code',
    width: 150,
  },
  {
    title: t('device.series'),
    dataIndex: 'series',
    resizable: true,
    key: 'series',
    width: 150,
  },
  {
    title: t('device.type'),
    dataIndex: 'type',
    resizable: true,
    key: 'type',
    width: 150,
  },
  {
    title: t('device.status'),
    dataIndex: 'status',
    resizable: true,
    key: 'status',
    width: 150,
  },
  {
    title: t('device.user'),
    dataIndex: 'user',
    resizable: true,
    key: 'user',
    width: 200,
  },
  {
    title: t('device.action'),
    dataIndex: 'action',
    key: 'action',
    width: 110,
    align: 'center',
    fixed: 'right',
  },
]);
const buttonActions = [
  {
    permission: 'device:edit',
    type: 'EDIT',
  },
  {
    type: 'VIEW',
  },
];
const data = [
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 1,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
  {
    name: 'xxxxxxxxxxxxx',
    code: 'PC09483485',
    series: 'L22462468',
    type: 'Hiện trường',
    status: 0,
    user: 'Cán bộ X',
  },
];
const { state, dispatch } = useStore();
const action = computed(() => state.deviceManagement.action);
const title = computed(() => {
  return action.value === ACTION.VIEW
    ? t('device.view-device')
    : action.value === ACTION.EDIT
    ? t('device.edit-device')
    : t('device.create-device');
});
const openDrawer = ref(false);
const isSave = ref(false);
const table = ref(null);
const disabledDate = (current) => current && current > dayjs().endOf('day');

onMounted(() => {
  dispatch('setTitleHeader', t('device.header'));
});
const formSearch = reactive({
  searchKey: '',
  dateFrom: null,
  dateTo: null,
});
let formState = reactive({
  name: null,
  code: null,
  type: null,
  user: null,
});
const rules = ref({
  searchKey: [maxlength(255, 'Key')],
});
const { validate } = useForm(formSearch, rules);
let paramFetch = _.cloneDeep(toRaw(formSearch));
const setParamFetch = () => {
  paramFetch.searchKey = formSearch.searchKey;
};
const onHide = () => {
  openDrawer.value = false;
  isSave.value = true;
};
const createGroup = () => {
  dispatch('deviceManagement/preCreate').then();
  resetForm();
  openDrawer.value = true;
};
const onSearch = () => {
  validate().then(
    () => {
      setParamFetch();
      table.value.fetchData().then();
    },
    () => {}
  );
};
const onEdit = (row) => {
  dispatch('deviceManagement/preUpdate', row).then();
  formState.name = row.name;
  formState.code = row.code;
  formState.type = row.type;
  formState.user = row.user;
  openDrawer.value = true;
};
const onView = (row) => {
  dispatch('deviceManagement/preView', row).then();
  formState.name = row.name;
  formState.code = row.code;
  formState.type = row.type;
  formState.user = row.user;
  openDrawer.value = true;
};
const onDelete = async () => {
  if (await this.dispatch('deviceManagement/delete', table.value.selectedRows))
    table.value.resetAndFetch(true);
};
const resetForm = () => {
  formState = reactive({
    name: null,
    code: null,
    type: null,
    user: null,
  });
};
const onClose = (isSaveData) => {
  if (!isSaveData) {
    resetForm();
  }
  openDrawer.value = false;
  isSave.value = isSaveData;
};
const onSaveDevice = () => {
  console.log('onSaveDevice');
};
</script>

<style lang="scss">
.list-device .ant-input-sm input.ant-input {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.list-device .ant-input-sm input.ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.list-device .icon-expand-drawer {
  background-image: url('../../../static/img/icon/open-drawer.svg');
  background-size: cover;
  height: 67px;
  width: 19px;
}
.list-device .icon-expand-drawer:hover {
  background-image: url('../../../static/img/icon/open-drawer-active.svg');
}
.list-device .ant-form-item {
  margin-bottom: 0 !important;
}
.list-device .ant-form-item .ant-picker {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-style: none;
}
.list-device .ant-tag {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
</style>
