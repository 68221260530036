<template>
  <cds-main class="h-full flex flex-col">
    <div
      class="list-camera pl-[16px] pr-[20px] py-[18px] flex justify-between items-center gap-20"
    >
      <div class="flex items-center gap-[16px]">
        <InputSearch
          class="ant-input-sm"
          v-model:value="formState.cameraName"
          size="small"
          :maxlength="50"
          :placeholder="$t('common.search')"
          @press-enter="onSearch"
          @onSearchAdvanced="onSearchAdvanced"
        />
        <div class="w-240px">
          <SelectSearchList
            :options="listGroupCamera"
            v-model:value="formState.groupId"
            :allow-clear="true"
            :placeholder="t('camera.select_group_camera')"
            @change="onSelectGroup"
          />
        </div>
      </div>
      <ClipButton
        type="reset"
        size="small"
        style="height: fit-content"
        class="w-max h-[40px]"
        @click="resetForm"
      >
        <cds-feather-icons
          type="rotate-cw"
          size="16"
          class="text-ems-white mr-2"
        />
        <span class="text-ems-white">{{ $t('common.reset') }}</span>
      </ClipButton>
    </div>
    <cds-cards class="h-full full-height-card m-0">
      <cds-table
        ref="table"
        :has-checkbox="false"
        :index-column="true"
        :columns="columns"
        :pagination="true"
        :api-fetch="apiFetch"
        :param-fetch="paramFetch"
      >
        <template v-slot:[`icon`]="record">
          <cds-feather-icons :type="record.icon" />
        </template>

        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'cameraState'">
            <a-tag
              class="px-3 text-center"
              :style="`background-color: transparent; border: 1px solid ${
                record.cameraState === 'CONNECTING' ? '#957406' : '#F60993'
              }; color:${
                record.cameraState === 'CONNECTING' ? '#957406' : '#F60993'
              }`"
            >
              {{
                record.cameraState === 'CONNECTING'
                  ? $t('camera.pause')
                  : $t('camera.active')
              }}
            </a-tag>
          </template>
          <template v-if="column.key === 'aiFlowTypes'">
            <div class="flex justify-start w-full space-x-2">
              <img
                v-if="record.aiFlowTypes.includes('HUMAN')"
                alt="person_active_ai"
                src="@/static/img/icon/person_active_ai.svg"
              />
              <img
                v-else
                alt="person_default_ai"
                src="@/static/img/icon/person_default_ai.svg"
              />
              <img
                v-if="record.aiFlowTypes.includes('VEHICLE')"
                alt="car_active_ai"
                src="@/static/img/icon/car_active_ai.svg"
              />
              <img
                v-else
                alt="car_default_ai"
                src="@/static/img/icon/car_default_ai.svg"
              />
            </div>
          </template>
          <template v-if="column.key === 'action' && record">
            <action-button
              class="text-left"
              :buttons="buttonActions"
              @onEdit="onEdit(record)"
              @onView="onView(record)"
            />
          </template>
        </template>
      </cds-table>
    </cds-cards>
    <cds-modal
      :visible="isPopupDelete"
      :mask-closable="false"
      hide-footer
      @onCancel="isPopupDelete = false"
      class="bg-ems-gray800 pb-0"
    >
      <ModalConfirm
        ref="modal"
        @confirm="handleConfirm"
        @onClose="isPopupDelete = false"
        :title="t('common.confirm_delete')"
      ></ModalConfirm>
    </cds-modal>
  </cds-main>
</template>
<script setup>
import { reactive, ref, toRaw, watch, onMounted, computed } from 'vue';
import ActionButton from '@/components/action-button';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import InputSearch from '@/components/input-search/index.vue';
// import SelectCustom from '../../../components/select-custom/index.vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import ConstantAPI from '@/config/ConstantAPI';
import { debounce } from 'lodash';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import { trim } from '@/util/common-utils';
import SelectSearchList from '@/components/select-search-list/SelectSearchList.vue';
const { t } = useI18n();
const isPopupDelete = ref(false);
const apiFetch = ConstantAPI.CAMERA.GET_LIST_CAMERA_INFO;
const DEBOUNCE_TIME = 500;
const { state, dispatch } = useStore();
const listGroupCamera = computed(() => state.cameras.groupCamera || []);
const formState = reactive({
  cameraName: '',
  groupId: null,
});
const { resetFields } = useForm(formState);
const columns = ref([
  {
    title: t('camera.name'),
    dataIndex: 'cameraName',
    key: 'cameraName',
    resizable: true,
    width: 150,
  },
  {
    title: t('camera.group'),
    dataIndex: 'groupNames',
    resizable: true,
    key: 'groupNames',
    width: 200,
    align: 'left',
  },
  {
    title: t('camera.location'),
    dataIndex: 'cameraLocation',
    key: 'cameraLocation',
    resizable: true,
    align: 'left',
    width: 200,
  },
  {
    title: t('camera.address'),
    dataIndex: 'ipAddress',
    key: 'ipAddress',
    resizable: true,
    width: 120,
  },
  {
    title: t('camera.status'),
    dataIndex: 'cameraState',
    key: 'cameraState',
    resizable: true,
    width: 100,
  },
  {
    title: t('camera.stream_ai'),
    dataIndex: 'aiFlowTypes',
    key: 'aiFlowTypes',
    resizable: true,
    width: 100,
  },
]);
let paramFetch = _.cloneDeep(toRaw(formState));
const setParamFetch = () => {
  paramFetch.cameraName = trim(formState.cameraName);
};
const delayedFetchData = debounce(() => {
  table.value.page = 1;
  setParamFetch();
  table.value.fetchData().then();
}, DEBOUNCE_TIME);

watch(
  () => formState.cameraName,
  () => {
    delayedFetchData();
  }
);
const onSelectGroup = debounce(function () {
  paramFetch.groupId = formState.groupId;
  table.value.resetAndFetch();
}, DEBOUNCE_TIME);
watch(
  () => formState.groupId,
  () => {
    onSelectGroup();
  }
);
onMounted(() => {
  dispatch('cameras/getGroupCamera');
  dispatch('setTitleHeader', t('camera.header')).then();
});
const resetForm = () => {
  resetFields();
  formState.groupId = null;
  paramFetch.groupId = formState.groupId;
};

const table = ref(null);
</script>
<style lang="scss">

.list-camera {
}

</style>
