<template>
    <!-- Header -->
    <div class="flex justify-between items-center p-20px border-b border-b-1 border-b-solid border-b-ems-gray700">
        <h3 class="uppercase text-white font-semibold">{{ t('report_statistical.title_chart_2') }}</h3>
        <div class="result flex items-center gap-20">
            <span class="text-ems-gray400">{{ t('report_statistical.count') }}</span>
            <h2 class="text-28px text-ems-main2 font-semibold">{{ formatNumberReZero(totalIntrusions) }}</h2>
        </div>
    </div>
    <!-- Content -->
    <div>
        <Chart
            v-if="!isLoading"
            ref="lineChart"
            :option="intrusionChart"
            class-name="chart bg-evisa-gray50 rounded-lg pr-4"
        />
        <cds-loading v-else class="h-260px" />
    </div>
    <!-- Footer -->
    <div class="flex justify-start gap-10 items-center p-20px border-t border-t-1 border-t-solid border-t-ems-gray700">
        <div class="flex gap-2.5 items-center">
            <div class="w-12px h-12px rounded-full bg-ems-boTro3_600"></div>
            <span class="text-ems-gray400">{{ t('report_statistical.category_chart_3') }}</span>
        </div>
        <div class="flex gap-2.5 items-center">
            <div class="w-12px h-12px rounded-full bg-ems-main1"></div>
            <span class="text-ems-gray400">{{ t('report_statistical.category_chart_4') }}</span>
        </div>
    </div>
</template>
<script setup>
    import { reactive, computed, watch, ref } from 'vue';
    import { graphic } from 'echarts';
    import dayjs from 'dayjs';
    import { useI18n } from 'vue-i18n';
    import Chart from './Chart.vue';
    import { useStore } from 'vuex';
    import { formatNumberReZero } from "@/util/common-utils";

    defineProps({});
    const { t } = useI18n();
    const { state } = useStore();

    const lineChart = ref();
    const intrusionChart = reactive({
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [],
        axisLabel: {
        textStyle: {
            color: '#9D9D9D'
          }
        },
        axisLine: {
          lineStyle: {
            type: "dashed",
            color: '#434343'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: '#434343'
          }
        },
        axisTick: {
          alignWithLabel: true,
          inside: true,
        },
      },
      yAxis: [
      {
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: '#434343'
          }
        },
        axisLabel: {
          textStyle: {
            color: '#9D9D9D'
          },
          formatter: (value) => {
            return formatNumberReZero(value);
          }
        },
        axisTick: {
          alignWithLabel: true,
          inside: true,
        }
      }
    ],
    series: [],
    tooltip: {
      formatter: (params) => {
        return `<p class="font-bold">${params.marker} ${formatNumberReZero(params.value)}</p>`;
      }
    }
  });

    const isLoading = computed(() => state.reportStatistical.loading);
    const intrusionDataChart = computed(() => state.reportStatistical.numberOfIntrusions.chart);
    const totalIntrusions = computed(() => state.reportStatistical.numberOfIntrusions.total);

    watch(() => intrusionDataChart.value, () => {
    intrusionChart.xAxis.data = !!intrusionDataChart.value.columns && intrusionDataChart.value.columns.map((item) => {
      if (item.split('-').length > 2 ) {
        return dayjs(item).format('DD/MM');
      } else if (item.split('-').length > 1) {
        return dayjs(item).format('MM/YY');
      }
      return item;
    });

    intrusionChart.series = [
      {
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          formatter: (params) => params.value === 0 ? '' : formatNumberReZero(params.value),
          padding: [3, 3, 3, 3],
          backgroundColor: "#262626",
          color: "#DCC51E",
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        lineStyle: {
          color: "#DCC51E"
        },
        itemStyle: {
          color: "#DCC51E",
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(220, 197, 30, 0.06)'
            },
            {
              offset: 1,
              color: 'rgba(64, 56, 0, 0.2)'
            }])
        },
        data: intrusionDataChart.value.normal
      },
      {
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          formatter: (params) => params.value === 0 ? '' : formatNumberReZero(params.value),
          padding: [3, 3, 3, 3],
          backgroundColor: "#262626",
          color: "#B5122E",
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        lineStyle: {
          color: "#B5122E"
        },
        itemStyle: {
          color: "#B5122E"
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255, 80, 111, 0.2)'
            },
            {
              offset: 1,
              color: 'rgba(124, 14, 33, 0.2)'
            }])
        },
        data: intrusionDataChart.value.intrusion
      },
    ];
  });
</script>