<template>
  <cds-main class="role-group flex flex-col">
    <div
      class="absolute top-[-8px] left-0 text-[20px] font-semibold text-ems-gray100 uppercase"
    >
      {{ title }}
    </div>
    <div class="pt-[30px] border-b-1 border-solid border-ems-tag1">
      <a-tabs v-model:activeKey="activeTab" @change="onChangeTab($event)">
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tabName">
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="w-full h-full component-custom !mb-10 flex-1">
      <Suspense>
        <template #default>
          <component
            :is="component"
            :formState="formState"
            :action="action"
            @onCheck="onCheck"
            @selecting-user="
              () => {
                isSelecting = true;
              }
            "
            :listUsers="listUsers"
            :isSelecting="isSelecting"
            :errName="errName"
            :errDesc="errDesc"
            :errTreeData="errTreeData"
          />
        </template>
        <template #fallback>
          <cds-loading />
        </template>
      </Suspense>
    </div>
    <div class="flex justify-end">
      <ClipButton
        v-if="!isSelecting && action !== ACTION.VIEW"
        type="primary"
        size="small"
        class="self-end !px-[30px]"
        @click="saveGroup"
        >{{ t('common.save') }}
      </ClipButton>
      <ClipButton
        v-if="isSelecting"
        type="primary"
        size="small"
        class="self-end !px-[30px]"
        @click="addUserToGroup"
        >{{ t('common.add') }}
      </ClipButton>
    </div>
  </cds-main>
  <cds-modal
    :visible="visible"
    :mask-closable="false"
    hide-footer
    @onCancel="visible = false"
    class="bg-ems-gray800 pb-0"
  >
    <ModalConfirm
      ref="modal"
      @confirm="handleConfirm"
      @onClose="visible = false"
      :title="t('common.confirm_save')"
    ></ModalConfirm>
  </cds-modal>
</template>
<script setup>
import {
  computed,
  defineAsyncComponent,
  reactive,
  ref,
  onMounted,
  defineEmits,
} from 'vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { i18n } from '@/main';
import { ACTION } from '@/config/Constant.js';
import { useStore } from 'vuex';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import { notification } from 'ant-design-vue';
import {trim} from '@/util/common-utils'
const { t } = i18n.global;
const { state, dispatch } = useStore();
const isSelecting = ref(false);
const visible = ref(false);
const KEY_TREE_ALL = '0-0';
const listUsers = reactive(state.roleGroup.group.listUsers);
const idRoleGroup = computed(() => state.roleGroup.group.id);
const SystemRole = defineAsyncComponent(() => import('./SystemRole.vue'));
const ListUsers = defineAsyncComponent(() => import('./ListUsers.vue'));
const emits = defineEmits(['create-success']);
const title = computed(() => {
  if (action.value === 0) {
    return t('role-group.title-view');
  }
  if (action.value === 1) {
    return t('role-group.title-edit');
  }
  if (action.value === 2) {
    return t('role-group.title-create');
  }
});
const action = computed(() => state.roleGroup.action);
const formState = reactive(state.roleGroup.group);
const component = ref(SystemRole);
const roleMenus = ref([]);
const roleMenusImage = ref([]);
const roleGroups = ref([]);
const roleCameras = ref([]);
const lisKeyMenuParentFun = ref([]);
const lisKeyMenuParentImg = ref([]);
const lisKeyMenuParentGroup = ref([]);
const lisKeyMenuParentCamera = ref([]);
const isSelectTreeFun = ref(false)
const isSelectTreeImg = ref(false)
const isSelectTreeGroup = ref(false)
const isSelectTreeCamera = ref(false)
const listOfSelectedUsers = ref(
  computed(() => state.roleGroup.listUserInRole)
);
const indexInit = ref(0);
const listSelectUser = ref([]);
const tabs = [
  {
    key: 'system-role',
    tabName: t('role-group.system-role'),
    component: SystemRole,
  },
  {
    key: 'list-users',
    tabName: t('role-group.list-users'),
    component: ListUsers,
  },
];
const activeTab = ref('system-role');
const onChangeTab = (key) => {
  activeTab.value = key;
  tabs.forEach((item) => {
    if (item.key === activeTab.value) {
      component.value = item.component;
    }
  });
  isSelecting.value = false;
};

const onCheck = (data) => {
  if (data.tab === 'function') {
    formState.selectedFunction = data.value;
    isSelectTreeFun.value = true
    lisKeyMenuParentFun.value = data.infoNode.halfCheckedKeys.filter(
      (item) => item !== KEY_TREE_ALL
    );
  }
  if (data.tab === 'management-image') {
    formState.selectedImage = data.value;
    isSelectTreeImg.value= true
    lisKeyMenuParentImg.value = data.infoNode.halfCheckedKeys.filter(
      (item) => item !== KEY_TREE_ALL
    );
  }
  if (data.tab === 'group-management') {
    formState.selectedGroup = data.value;
    isSelectTreeGroup.value = true
    lisKeyMenuParentGroup.value = data.value.filter(
      (item) => item !== KEY_TREE_ALL
    );
  }
  if (data.tab === 'camera-management') {
    formState.selectedCamera = data.value
    isSelectTreeCamera.value = true
    lisKeyMenuParentCamera.value = data.infoNode.halfCheckedKeys.filter(
      (item) => item !== KEY_TREE_ALL
    );
  };
};
const addItemsToRoleMenus = (items) => {
  items.forEach((item) => {
    roleMenus.value.push({ menuId: item });
  });
};

const addItemsToRoleMenusImage = (items) => {
  items.forEach((item) => {
    roleMenusImage.value.push({ menuId: item });
  });
};

const addItemsToRoleGroups = (items) => {
  items.forEach((item) => {
    roleGroups.value.push({ groupId: item });
  });
};
const addItemsToRoleCameras = (items) => {
  items.forEach((item) => {
    roleCameras.value.push({ cameraId: item });
  });
};
function removeDuplicatesByProperty(array, propertyName) {
  const seen = new Set();
  return array.filter((item) => {
    const propertyValue = item[propertyName];
    if (seen.has(propertyValue)) {
      return false;
    } else {
      seen.add(propertyValue);
      return true;
    }
  });
}
const handleConfirm = () => {
  formState.selectedImage && addItemsToRoleMenusImage(formState.selectedImage);
  !isSelectTreeImg.value && formState.selectedImageParent && addItemsToRoleMenusImage(formState.selectedImageParent);
  lisKeyMenuParentImg.value && addItemsToRoleMenusImage(lisKeyMenuParentImg.value);
  formState.selectedFunction && addItemsToRoleMenus(formState.selectedFunction);
  !isSelectTreeFun.value && formState.selectedFunctionParent && addItemsToRoleMenus(formState.selectedFunctionParent);
  lisKeyMenuParentFun.value && addItemsToRoleMenus(lisKeyMenuParentFun.value);
  // formState.selectedGroup && addItemsToRoleGroups(formState.selectedGroup)
  lisKeyMenuParentGroup.value && addItemsToRoleGroups(lisKeyMenuParentGroup.value);
  !isSelectTreeGroup.value && formState.selectedGroupParent && addItemsToRoleGroups(formState.selectedGroupParent);
  formState.selectedCamera && addItemsToRoleCameras(formState.selectedCamera)
  lisKeyMenuParentCamera.value && addItemsToRoleCameras(lisKeyMenuParentCamera.value);
  !isSelectTreeCamera.value && formState.selectedCameraParent && addItemsToRoleCameras(formState.selectedCameraParent);
  if (action.value === 1) {
    let formStateConvertUpdate = {
      id: idRoleGroup.value,
      name: trim(formState.name),
      description: trim(formState.desc),
      userRoles: listOfSelectedUsers.value.map((item) => ({ userId: item.id })),
      roleCameras:removeDuplicatesByProperty(roleCameras.value,'cameraId').filter((item) => item.cameraId !== KEY_TREE_ALL),
      roleMenus: removeDuplicatesByProperty(roleMenus.value,'menuId').concat(removeDuplicatesByProperty(roleMenusImage.value,'menuId')).filter((item) => item.menuId !== KEY_TREE_ALL),
      roleGroups: removeDuplicatesByProperty(roleGroups.value,'groupId').filter((item) => item.groupId !== KEY_TREE_ALL),
      status: formState.status,
    };
    dispatch('roleGroup/update', formStateConvertUpdate).then(() => {
      visible.value = false;
      emits('create-success');
    });
  }
  if (action.value === 2) {
    let formStateConvertCreate = {
      name: trim(formState.name),
      description: trim(formState.desc),
      userRoles: listOfSelectedUsers.value.map((item) => ({ userId: item.id })),
      roleCameras:roleCameras.value,
      roleMenus: roleMenus.value.concat(roleMenusImage.value).filter((item) => item.menuId !== KEY_TREE_ALL),
      roleGroups: roleGroups.value.filter((item) => item.groupId !== KEY_TREE_ALL),
      status: formState.status,
    };
    dispatch('roleGroup/create', formStateConvertCreate).then(() => {
      visible.value = false;
      emits('create-success');
    });
  }
};

const addUserToGroup = () => {
  dispatch('roleGroup/addListUser', listOfSelectedUsers.value);
  isSelecting.value = false;
};

const errName = ref('');
const errDesc = ref('');
const errTreeData = ref(false);

const saveGroup = () => {
  if (!validate()) {
    visible.value = true;
  }
};
const validate = () => {
  let isFail = false;
  if (formState.name.length === 0 || formState.name.length > 50) {
    errName.value =
      formState.name.length === 0
        ? t('role-group.name-required')
        : t('role-group.name-invalid');
    isFail = true;
  } else {
    errName.value = '';
  }
  if (formState.desc.length > 200) {
    errDesc.value = t('role-group.desc-invalid');
    isFail = true;
  } else {
    errDesc.value = '';
  }
  return isFail;
};
</script>
<style lang="scss">
.role-group {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.role-group .ant-tabs-nav-list {
  -webkit-column-gap: 47px;
  -moz-column-gap: 47px;
  grid-column-gap: 47px;
  column-gap: 47px;
}
.role-group .ant-tabs .ant-tabs-ink-bar {
  display: block;
}
.role-group .ant-tabs .ant-tabs-tab {
  padding-bottom: 23px;
  padding-left: 0px;
}
.role-group .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: uppercase;
}
</style>
