<template>
  <div class="clearfix">
    <div
      v-if="list.length > 0"
      @drop="dropHandler($event)"
      @dragover="$event.preventDefault()"
      @dragenter="$event.preventDefault()"
    >
      <div
        v-if="isShowSelectedImg"
        class="h-237px rounded-8px overflow-hidden mb-16px"
      >
        <img
          class="w-full h-full object-cover"
          alt="example"
          :src="selectedImg"
        />
        <img
          v-if="isSelectedImgMain"
          src="../../static/img/icon/StarFill.svg"
          alt="Icon Upload"
          class="main-image"
        />
        <img
          v-if="!isSelectedImgMain && isEditMainImg"
          src="../../static/img/icon/Star.svg"
          alt="Icon Upload"
          class="main-image cursor-pointer"
          @click="onSetMainImage(selectedImgIndex)"
        />
      </div>
      <div
        class="grid grid-cols-2 2xl:grid-cols-3 gap-[14px] max-h-400px overflow-auto"
      >
        <PreviewGroup>
          <div
            v-for="(item, index) in list"
            class="hover-icon relative h-104px rounded-8px overflow-hidden cursor-pointer img-upload-custom"
          >
            <Image v-if="item.url" class="" :height="104" :src="item.url">
              <template #previewMask>
                <div
                  class="w-full h-full absolute top-0 bg-ems-black bg-opacity-60 z-50 icon-trash-custom"
                >
                  <div class="flex gap-20px justify-center items-center h-full">
                    <cds-feather-icons color="#FFFFFF" type="eye" size="20" />
                    <cds-feather-icons
                      v-if="!disabled"
                      class="invisible"
                      color="#FFFFFF"
                      type="trash"
                      size="20"
                    />
                  </div>
                </div>
              </template>
            </Image>
            <div v-else class="w-full h-full">
              <a-spin />
            </div>

            <cds-feather-icons
              class="absolute top-42px right-35px xl:right-35px 3xl:right-70px  w-20px h-20px z-51 hidden group-hover:block icon-trash-custom"
              color="#FFFFFF"
              type="trash"
              size="20"
              @click="confirmRemoveImage(index)"
              v-if="!disabled"
            />
            <img
              v-if="item.isMain && isShowSelectedImg"
              src="../../static/img/icon/StarFill.svg"
              alt="Icon Upload"
              class="absolute top-5px right-5px w-20px h-20px z-51 icon-trash-custom"
            />
            <img
              v-if="!item.isMain && isShowSelectedImg && isEditMainImg"
              src="../../static/img/icon/Star.svg"
              alt="Icon Upload"
              class="absolute top-5px right-5px w-20px h-20px z-51 hidden group-hover:block icon-trash-custom"
              @click="onSetMainImage(index)"
            />
          </div>
        </PreviewGroup>
      </div>
    </div>
    <a-upload
      v-model:file-list="list"
      :customRequest="customRequest"
      @change="change"
      :before-upload="beforeUpload"
      :accept="accept"
      :disabled="disabled"
      :multiple="multiple"
      class="multi-img"
      :show-upload-list="false"
    >
      <div
        v-if="list.length < 1"
        class="w-full bg-ems-gray700 rounded-8px flex flex-col justify-center mb-24px"
        :class="isShowSelectedImg ? 'h-477px' : 'h-104px'"
      >
        <img
          src="../../static/img/icon/ImageIcon.svg"
          alt="Icon Upload"
          class="block mx-auto"
        />
        <div class="text-center text-ems-gray300">
          {{ t('object_information.no_image') }}
        </div>
      </div>
      <div
        v-if="!disabled && (multiple || list.length === 0)"
        class="w-full h-45px flex justify-center items-center bg-ems-main1 rounded-8px"
      >
        <div class="text-ems-white">
          {{ t('object_information.add_image') }}
        </div>
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      :title="previewTitle"
      :footer="null"
      @cancel="handleCancel"
    >
      <video v-if="type === 'video'" width="470" height="340" controls>
        <source :src="previewImage" type="video/mp4" />
      </video>
      <!--      <img v-else :alt="previewTitle" style="width: 100%" :src="previewImage" />-->
    </a-modal>
  </div>

  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
</template>
<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { DataService } from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import { Image, notification } from 'ant-design-vue';
import VueTypes from 'vue-types';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { checkResponse } from '@/util/common-utils';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default defineComponent({
  components: {
    PlusOutlined,
    showConfirmModal,
    Image,
    PreviewGroup: Image.PreviewGroup,
  },
  props: {
    value: VueTypes.array.def('' | []),
    disabled: VueTypes.bool.def(false),
    multiple: VueTypes.bool.def(true),
    accept: VueTypes.string,
    beforeUpload: VueTypes.func,
    rootPath: VueTypes.string,
    // type image, video
    type: VueTypes.string.def('image'),
    isShowSelectedImg: VueTypes.bool.def(true),
    isEditMainImg: VueTypes.bool.def(true),
  },
  emits: ['upload-new-img', 'delete-img', 'update:value', 'change'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const previewVisible = ref(false);
    const previewImage = ref('');
    const previewTitle = ref('');
    const fileList = ref([]);
    const selectedImg = ref('');
    const selectedImgIndex = ref('');
    const isSelectedImgMain = ref(false);
    const isShowDelete = ref(false);
    const onSelectedImg = (index) => {
      selectedImg.value = list.value[index].url;
      selectedImgIndex.value = index;
      isSelectedImgMain.value = list.value[index].isMain;
    };
    const onSetMainImage = (index) => {
      list.value = list.value.map((e, i) => ({
        ...e,
        isMain: index === i,
      }));
      onSelectedImg(index);
      // isSelectedImgMain.value = list.value[selectedImgIndex.value].isMain;
      emit(
        'update:value',
        list.value.map((e) => ({
          fileName: e.name ? e.name : e.fileName,
          url: e.url,
          isMain: e.isMain,
        }))
      );
      emit('change');
    };
    const handlePreviewCustom = (item) => {
      previewImage.value = item.url;
      previewVisible.value = true;
      previewTitle.value = item.name;
    };
    const handleCancel = () => {
      previewVisible.value = false;
      previewTitle.value = '';
    };
    const isLoading = ref(false);
    const customRequest = async (options) => {
      const { onSuccess, onError, file, onProgress } = options;
      const fmData = new FormData();
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (event) => {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append('file', file);
      fmData.append('rootPath', props.rootPath);
      isLoading.value = true;
      try {
        const response = await DataService.upload(
          ConstantAPI.upload_service.UPLOAD,
          fmData,
          config
        );
        checkResponse(
          response,
          () => {
            isLoading.value = false;
            onSuccess(response.data.url);
            emit('upload-new-img', response.data);
            emit('change');
            emit(
              'update:value',
              list.value.map((e) => ({
                fileName: e.name ? e.name : e.fileName,
                url: e.url,
                isMain: e.isMain,
                id: e.id ? e.id : 0,
              }))
            );
          },
          () => {
            isLoading.value = false;
            notification.error({
              message: t('common.notification'),
              description: response.error,
              duration: 4,
            });
            onError();
          }
        );
      } catch (err) {
        isLoading.value = false;
        onError({ err });
      }
    };
    const change = async () => {
      list.value = list.value.map((e) => ({
        ...e,
        url: _.isNil(e.url) ? e.response : e.url,
        isMain: e.isMain ? e.isMain : false,
        fileName: e.name ? e.name : e.fileName,
      }));
      if (
        list.value &&
        list.value.length > 0 &&
        !list.value.find((e) => e.isMain)
      ) {
        list.value[0].isMain = true;
        selectedImg.value = list.value[0].url;
        selectedImgIndex.value = 0;
        isSelectedImgMain.value = list.value[0].isMain;
      }
      emit(
        'update:value',
        list.value.map((e) => ({
          fileName: e.name ? e.name : e.fileName,
          url: e.url,
          isMain: e.isMain,
          id: e.id ? e.id : 0,
        }))
      );
    };
    const showConfirmCustom = ref(null);
    const confirmRemoveImage = async (index) => {
      if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
        removeImage(index);
      }
    };
    const removeImage = (index) => {
      emit('delete-img', list.value[index]);
      list.value.splice(index, 1);
      if (
        list.value &&
        list.value.length > 0 &&
        !list.value.find((e) => e.isMain)
      ) {
        list.value[0].isMain = true;
        selectedImg.value = list.value[0].url;
        selectedImgIndex.value = 0;
        isSelectedImgMain.value = list.value[0].isMain;
      }
      if (
        list.value &&
        list.value.length > 0 &&
        !list.value.find((e) => e.url === selectedImg.value)
      ) {
        selectedImg.value = list.value[0].url;
        selectedImgIndex.value = 0;
      }
      emit(
        'update:value',
        list.value.map((e) => {
          return {
            fileName: e.name ? e.name : e.fileName,
            url: e.url,
            isMain: e.isMain,
          };
        })
      );
      emit('change');
    };
    const uploadDrop = async (file) => {
      const fileSizeMB = file.size / (1024 * 1024);

      if (fileSizeMB > 2) {
        notification.error({
          message: t('common.notification'),
          description: t('common.Image exceeds'),
          duration: 4,
        });
        return;
      }

      const fmData = new FormData();
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      fmData.append('file', file);
      emit('update:value', null);
      isLoading.value = true;
      const response = await DataService.upload(
        ConstantAPI.upload_service.UPLOAD,
        fmData,
        config
      );
      checkResponse(
        response,
        () => {
          isLoading.value = false;
          list.value.push({
            fileName: response.data.fileName ? response.data.fileName : file.name,
            url: response.data.url,
          })
          emit('upload-new-img', response.data);
          emit('change');
          emit(
            'update:value',
            list.value.map((e) => ({
              fileName: e.name ? e.name : e.fileName,
              url: e.url,
              isMain: e.isMain,
              id: e.id ? e.id : 0,
            }))
          );
        },
        () => {
          isLoading.value = false;
          notification.error({
            message: t('common.notification'),
            description: response.error,
            duration: 4,
          });
        }
      );
    };
    const dropHandler = (e) => {
      uploadDrop(e.dataTransfer.files[0]);
      e.preventDefault();
    };
    const list = ref(
      props.value.map((e) => ({
        status: 'done',
        url: e.url,
        name: e.name ? e.name : e.fileName,
        isMain: e.isMain,
        id: e.id,
      }))
    );
    const onGetListImg = () => {
      if (
        list.value &&
        list.value.length > 0 &&
        list.value.filter((item) => item.isMain).length === 0
      ) {
        list.value[0].isMain = true;
      }
      if (list.value && list.value.length > 0) {
        selectedImg.value = list.value[0].url;
        selectedImgIndex.value = 0;
        isSelectedImgMain.value = list.value[0].isMain;
      }
    };
    onMounted(() => {
      onGetListImg();
    });
    watch(list.value, () => {
      onGetListImg();
    });
    watch(
      () => props.value,
      () => {
        if (props.value && props.value.every((item) => item.url)) {
          list.value = props.value.map((e) => ({
            status: 'done',
            url: e.url,
            name: e.name ? e.name : e.fileName,
            isMain: e.isMain,
            id: e.id,
          }));
        }
      }
    );
    return {
      t,
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      previewTitle,
      customRequest,
      change,
      list,
      selectedImg,
      selectedImgIndex,
      onSelectedImg,
      onSetMainImage,
      isSelectedImgMain,
      handlePreviewCustom,
      removeImage,
      isLoading,
      confirmRemoveImage,
      showConfirmCustom,
      isShowDelete,
      dropHandler,
    };
  },
});
</script>
<style lang="scss">

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.multi-img .ant-upload {
  border: none !important;
  width: 100% !important;
  border-radius: 8px;
  margin-top: 24px;
}

.main-image {
  position: absolute;
  right: 15px;
  top: 15px;
}

img.ant-image-preview-img {
  display: inline-block;
}

.img-upload-custom {
  img.ant-image-img {
    height: 104px;
    object-fit: cover;
  }

  .ant-image {
    width: 100%;
  }
}

.icon-trash-custom {
  opacity: 0;
}

.hover-icon:hover {
  .icon-trash-custom {
    display: block !important;
    opacity: 1;
  }

  .ant-image-mask {
    opacity: 1 !important;
    transition: opacity 0s;
  }
}

</style>
