<template>
  <div class="grid grid-cols-3 gap-30px">
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.address')"
        :name="'address'"
        :rules="rules.address"
      >
        <cds-input
          v-model:value="formLocation.address"
          :maxlength="255"
          :placeholder="t('object_information.address_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.province')"
        :name="['nhanDang']"
      >
        <cds-select
          v-model:value="formLocation.provinceId"
          :options="listProvince"
          :label-prop="'name'"
          :value-prop="'id'"
          show-search
          :is-label-i18n="false"
          @change="onChangeProvince"
          :placeholder="t('object_information.province_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.district')"
        :name="['nhanDang']"
      >
        <cds-select
          v-model:value="formLocation.districtId"
          :options="listDistrict"
          :label-prop="'name'"
          :value-prop="'id'"
          show-search
          :is-label-i18n="false"
          @change="onChangeDistrict"
          :placeholder="t('object_information.district_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.wards')"
        :name="['nhanDang']"
      >
        <cds-select
          v-model:value="formLocation.wardId"
          :options="listWard"
          :label-prop="'name'"
          :value-prop="'id'"
          show-search
          :is-label-i18n="false"
          :placeholder="t('object_information.wards_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.location_type')"
        :name="'type'"
      >
        <cds-select
          v-model:value="formLocation.type"
          :options="typeLocation"
          :label-prop="'name'"
          :placeholder="t('object_information.location_type_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.note')"
        :name="['nhanDang']"
      >
        <cds-input
          v-model:value="formLocation.note"
          :maxlength="255"
          :placeholder="t('object_information.note_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
  </div>
  <div class="h-1px w-full bg-ems-gray700 mt-28px"></div>
  <div class="grid grid-cols-3 gap-30px mt-40px pb-63px">
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.contact_name')"
        :name="['nhanDang']"
      >
        <cds-input
          v-model:value="formLocation.contactName"
          :maxlength="100"
          :placeholder="t('object_information.contact_name_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.contact_phone_number')"
        :name="['nhanDang']"
      >
        <cds-input
          v-model:value="formLocation.contactPhone"
          :maxlength="50"
          :placeholder="
            t('object_information.contact_phone_number_placeholder')
          "
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.contact_address')"
        :name="['nhanDang']"
      >
        <cds-input
          v-model:value="formLocation.contactAddress"
          :maxlength="100"
          :placeholder="t('object_information.contact_address_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.owner_name')"
        :name="['nhanDang']"
      >
        <cds-input
          v-model:value="formLocation.ownerName"
          :maxlength="100"
          :placeholder="t('object_information.owner_name_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :name="['nhanDang']"
      >
        <template #label>
          <div
            class="whitespace-nowrap truncate"
            :title="t('object_information.owner_id')"
          >
            {{ t('object_information.owner_id') }}
          </div>
        </template>
        <cds-input
          v-model:value="formLocation.ownerIdCard"
          :maxlength="100"
          :placeholder="t('object_information.owner_id_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
    <div>
      <a-form-item
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :label="t('object_information.owner_address')"
        :name="['nhanDang']"
      >
        <cds-input
          v-model:value="formLocation.ownerAddress"
          :maxlength="100"
          :placeholder="t('object_information.owner_address_placeholder')"
          :disabled="formMode === FORM_MODE.VIEW"
        />
      </a-form-item>
    </div>
  </div>
  <showConfirmModal ref="showConfirm" :closable="false"></showConfirmModal>
</template>
<script setup>
import { computed, inject, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useForm } from 'ant-design-vue/es/form';
import { FORM_MODE } from '../../../../util/common-constant';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { useRouter, useRoute } from 'vue-router';

const { t } = useI18n();
const { state, dispatch } = useStore();
const formLocation = reactive(state.ioManagement.formLocation);
const typeLocation = computed(() => state.ioManagement.typeAddress);
const formMode = computed(() => state.ioManagement.formMode);
const listMergeProfile = computed(() => state.ioManagement.listMergeProfile);
const router = useRouter();
const listProvince = computed(() => state.location.locations || []);
const showConfirm = ref(null);
const listDistrict = ref([]);
const listWard = ref([]);
const rules = {
  address: [],
};
const route = useRoute();
const { validate } = useForm(formLocation, rules);
const onSaveLocation = (isCallApi) => {
  let title =
    formMode.value !== FORM_MODE.CREATE
      ? 'common.update_confirm'
      : 'common.save_confirm';
  useForm(formLocation, rules)
    .validate()
    .then(async () => {
      if (isCallApi) {
        if (await showConfirm.value.onOpenModal(title)) {
          if (await dispatch('ioManagement/checkViolation')) {
            if (formMode.value === FORM_MODE.CREATE) {
              const response = await dispatch('ioManagement/createLocation');
              if (response) {
                if(listMergeProfile.value.length > 0) {
                  await dispatch('ioManagement/mergeProfile', {
                    id: response,
                    idsSimilar: listMergeProfile.value,
                  });
                }
                await dispatch('ioManagement/resetForm');
                await router.push({
                  name: '/io-management/object-management',
                  query: query,
                });
              }
            } else if (formMode.value === FORM_MODE.UPDATE) {
              const response = await dispatch('ioManagement/updateLocation');
            }
          }
        }
      }
    });
};
const eventBus = inject('eventBus');
const query = reactive({
  page: 1,
  searchName: null,
  searchGroupId: null,
  searchType: null,
  searchImage: '',
});
onMounted(async () => {
  query.page = route.query.page ? route.query.page : 1;
  query.searchName = route.query.searchName ? route.query.searchName : null;
  query.searchType = route.query.searchType ? route.query.searchType : null;
  query.searchImage = route.query.searchImage ? route.query.searchImage : '';
  query.searchGroupId = route.query.searchGroupId
    ? route.query.searchGroupId
    : null;
  await Promise.all([
    dispatch('location/getAllLocations'),
    dispatch('ioManagement/getTypeAddress'),
  ]);
  eventBus.on('validate-location', (isCallApi) => {
    onSaveLocation(isCallApi);
  });
  if (formMode.value !== FORM_MODE.CREATE) {
    listDistrict.value = listProvince.value.find(
      (e) => e.id === formLocation.provinceId
    ).districtsData;
    listWard.value = listDistrict.value.find(
      (e) => e.id === formLocation.districtId
    ).wardsData;
  }
});
onUnmounted(() => {
  eventBus.off('validate-location');
});
const onChangeProvince = (provinceId) => {
  listDistrict.value = listProvince.value.find(
    (e) => e.id === provinceId
  ).districtsData;
  formLocation.value.districtId = null;
  formLocation.value.wardId = null;
  listWard.value = [];
};
const onChangeDistrict = (districtId) => {
  listWard.value = listDistrict.value.find(
    (e) => e.id === districtId
  ).wardsData;
  formLocation.value.wardId = null;
};
</script>
