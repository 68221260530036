<template>
  <div
    class="max-h-500px 3xl:max-h-760px overflow-y-auto"
    @scroll="checkScroll"
  >
    <a-table
      :has-checkbox="false"
      :index-column="true"
      :data-source="listNonCheckout"
      ref="table"
      :pagination="false"
      :columns="columns"
      :loading="isLoading"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'stt' && record">
          {{ index + 1 }}
        </template>
        <template v-if="column.key === 'img' && record">
          <div class="w-[62px] h-[62px] img-custom hover:cursor-pointer">
            <img
              class="w-full h-full object-cover"
              :src="
                record.imageCrop
                  ? record.imageCrop
                  : require('@/static/img/no-img.png')
              "
              alt=""
            />
          </div>
        </template>
        <template v-if="column.key === 'location' && record">
          <a-tooltip placement="bottom" :title="record.location">
            <div class="line-clamp-1">{{ record.location }}</div>
          </a-tooltip>
        </template>
        <template v-if="column.key === 'time_check_in' && record">
          <div>
            {{ dayjs(record.createdAt).format('DD/MM/YYYY - HH:mm:ss') }}
          </div>
        </template>
      </template>
    </a-table>
    <div class="flex justify-center pb-2">
      <a-button
        v-if="isShowButton"
        type="primary"
        ghost
        class="!text-ems-main2 !border-ems-main2 text-base font-semibold"
        @click="handleViewMore()"
      >
        {{ t('common.view-more') }}
      </a-button>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import {
  FORM_MODE,
  OBJECT_TYPE,
  OBJECT_TYPE_TEXT,
} from '@/util/common-constant';
import { getText } from '@/util/common-utils';

defineProps({});

const { t } = useI18n();
const { state, dispatch } = useStore();
const isShowButton = ref(false);
const route = useRoute();
const router = useRouter();

const columns = ref([
  {
    title: t('common.stt'),
    key: 'stt',
    width: 90,
  },
  {
    title: t('report_statistical.image'),
    key: 'img',
    width: 150,
    dataIndex: 'imageCrop',
  },
  {
    title: t('group-management.select-type-placeholder'),
    key: 'type',
    dataIndex: 'type',
    width: 200,
    customRender: ({ record }) => {
      return t(getText(record.type, OBJECT_TYPE_TEXT));
    },
  },
  {
    title: t('camera.name'),
    key: 'cameraName',
    dataIndex: 'cameraName',
    width: 100,
  },
  {
    title: t('identity_object.form.Position'),
    key: 'location',
    dataIndex: 'location',
    width: 200,
  },
  {
    title: t('user_group.permission_time'),
    key: 'time_check_in',
    dataIndex: 'time_check_in',
  },
]);

const handleViewMore = () => {
  dispatch(
    'reportStatistical/fetchNonCheckouts',
    listNonCheckout.value.length + 10
  );
  isShowButton.value = false;
};

const checkScroll = (e) => {
  if (
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
    listNonCheckout.value.length !== listDraftNonCheckout.value.length
  ) {
    isShowButton.value = true;
  }
};

const listNonCheckout = computed(() => state.reportStatistical.nonCheckouts);
const listDraftNonCheckout = computed(
  () => state.reportStatistical.draftNonCheckouts
);
const isLoading = computed(() => state.reportStatistical.loading);
</script>
