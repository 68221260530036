<template>
  <div class="px-20px py-18px">
    <div class="text-ems-gray200 text-16px mb-20px font-semibold uppercase">
      {{ t('object_information.detail_info') }}
    </div>
    <FormHuman v-if="formState.objectType === OBJECT_TYPE.HUMAN" />
    <FormVehicle v-if="formState.objectType === OBJECT_TYPE.VEHICLE" />
    <FormLocation v-if="formState.objectType === OBJECT_TYPE.LOCATION" />
    <FormSymbol v-if="formState.objectType === OBJECT_TYPE.SYMBOL" />
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import FormHuman from '@/view/object-information/components/FormDetailInformation/FormHuman.vue';
import FormVehicle from '@/view/object-information/components/FormDetailInformation/FormVehicle.vue';
import FormLocation from '@/view/object-information/components/FormDetailInformation/FormLocation.vue';
import FormSymbol from '@/view/object-information/components/FormDetailInformation/FormSymbol.vue';
import { OBJECT_TYPE } from '../../../util/common-constant';

const { t } = useI18n();
const { state } = useStore();
const formState = computed(() => state.ioManagement.formState);
</script>
