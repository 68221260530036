<template>
  <a-form
      ref="formModal"
      :model="formState"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
      @finish="onFinish"
  >
    <a-form-item label="Name" name="name">
      <cds-input :read-only="disabled" class="w-100 ant-input-sm" v-model:value="formState.name" :maxlength="30"
                 show-count
                 size="small"
                 placeholder="Name"/>
    </a-form-item>
    <a-form-item label="Description" name="description">
      <cds-input :read-only="disabled" class="w-100 ant-input-sm" v-model:value="formState.description" :maxlength="100"
                 show-count
                 size="small"
                 placeholder="Description"/>
    </a-form-item>
    <a-form-item v-if="disabled" label="Menu">
      <cds-tree :tree-data="menus" :field-names="fieldNames" v-model:value="formState.roleMenus"/>
    </a-form-item>
    <a-form-item v-else label="Menu" name="roleMenus">
      <cds-tree :tree-data="menus" :field-names="fieldNames" v-model:value="formState.roleMenus"/>
    </a-form-item>
    <a-form-item label="Created Time" v-if="disabled">
      {{ formatDateVN(formState.createdTime) }}
    </a-form-item>
    <a-form-item label="Modified Time" v-if="disabled">
      {{ formatDateVN(formState.modifiedTime) }}
    </a-form-item>

    <hr class="footer">


    <div class="w-100 text-right">
      <cds-button key="back" type="light" size="small" @click="onCancel">
        <cds-feather-icons class="mr-10" type="x"/>
        {{ disabled ? 'Close' : 'Cancel' }}
      </cds-button>
      <cds-button
          class="ml-20"
          v-if="!disabled"
          key="submit"
          type="primary"
          size="small"
          type-html="submit"
      >
        <cds-feather-icons type="save"/>
        Save
      </cds-button>
    </div>

  </a-form>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRaw } from 'vue';
import { useStore } from "vuex";
import _ from 'lodash';
import { FORM_MODE } from "@/util/common-constant";
import { maxlength, required } from "@/util/validation";
import { formatDate } from '@/util/common-utils';


export default defineComponent({
  emits: ['saveSuccess'],
  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const formState = reactive(state.role.role);
    const formMode = computed(() => state.role.formMode);
    const disabled = formMode.value === FORM_MODE.VIEW;
    const rules = computed(() => {
      if (disabled) return {};
      return {
        name: [required('Name'), maxlength(30, 'Name')],
        description: [maxlength(100, 'Description')],
        roleMenus: [required('Menu')],
      };
    });
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };
    const formModal = ref(null);
    const fieldNames = { title: 'name', key: 'id' };
    const formatDateVN = computed(() => formatDate);

    const setDisabled = (menu) => {
      menu.disabled = true;
      if (menu.children) {
        menu.children.forEach(m => setDisabled(m));
      }
    };
    const menus = computed(() => {
      const menu = _.cloneDeep(state.menu.list);
      if (!disabled) return menu;
      menu.forEach(m => setDisabled(m));
      return menu;
    });
    const onCancel = () => dispatch('role/setVisible', false);
    const onFinish = async () => {
      try {
        const data = _.cloneDeep(toRaw(formState));
        data.roleMenus = data.roleMenus.map(e => ({ menuId: e }))
        if (await dispatch(formMode.value === FORM_MODE.CREATE ? 'role/create' : 'role/update', data)) {
          emit('saveSuccess');
        }
        // eslint-disable-next-line no-empty
      } catch {

      }
    };
    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      menus,
      fieldNames,
      formModal,
      onFinish,
      onCancel,
      disabled,
      formatDateVN,
    };
  },
});
</script>

<style scoped>



</style>
