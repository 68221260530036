import Table from '../../../components/table/Table';
import { onMounted, reactive, ref, toRaw, computed } from 'vue';
import ConstantAPI from '@/config/ConstantAPI';
import ActionButton from '../../../components/action-button';
import { ModalStyled } from '../../../components/modals/styled';
import { useForm } from 'ant-design-vue/es/form';
import { useStore } from 'vuex';
import FormModal from './FormModal';
import { greaterThanCurrent, formatDate } from '@/util/common-utils';
import { maxlength } from '@/util/validation';
import _ from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { defaultButton } from '@/util/common-constant';

const apiFetch = ConstantAPI.role.SEARCH;
export default {
  name: 'Role',
  components: {
    'cds-table': Table,
    ActionButton,
    ModalStyled,
    FormModal,
  },
  setup() {
    const columns = ref([
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        resizable: true,
        width: 260,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        resizable: true,
        key: 'description',
        width: 260,
      },
      {
        title: 'Created time',
        key: 'createdTime',
        sortDirections: ['descend', 'ascend'],
        resizable: true,
        sortable: true,
        dataIndex: 'createdTime',
        customRender({ record }) {
          return formatDate(record.createdTime);
        },
        align: 'center',
        width: 150,
      },
      {
        title: 'Modified time',
        key: 'modifiedTime',
        resizable: true,
        sortable: true,
        dataIndex: 'modifiedTime',
        customRender({ record }) {
          if (!record.modifiedTime) return '';
          return formatDate(record.modifiedTime);
        },
        align: 'center',
        width: 150,
      },
      {
        key: 'action',
        width: 110,
      },
    ]);
    const labelCol = {
      lg: 8,
      md: 8,
      xs: 24,
    };
    const wrapperCol = {
      lg: 16,
      md: 16,
      xs: 24,
    };
    const { buttonsForm, buttonActions } = defaultButton('role');
    const table = ref(null);
    const { state, dispatch } = useStore();
    const loading = computed(() => state.role.loading);
    onMounted(() => {
      dispatch('setTitleHeader', 'Role');
      dispatch('menu/getAll');
    });
    const formState = reactive({
      roleName: '',
      dateFrom: null,
      dateTo: null,
    });
    const rules = ref({
      roleName: [maxlength(15, 'Role')],
    });
    const { resetFields, validate, validateInfos } = useForm(formState, rules);
    let paramFetch = _.cloneDeep(toRaw(formState));
    const setParamFetch = () => {
      paramFetch.roleName = formState.roleName;
      paramFetch.dateFrom = formState.dateFrom;
      paramFetch.dateTo = formState.dateTo;
    };
    const resetForm = () => {
      resetFields();
      formState.dateFrom = null;
      formState.dateTo = null;
      setParamFetch();
      table.value.resetAndFetch();
    };
    const onSearch = () => {
      validate().then(
        () => {
          setParamFetch();
          table.value.fetchData().then();
        },
        () => {}
      );
    };
    const disabledDate = greaterThanCurrent;
    return {
      columns,
      apiFetch,
      labelCol,
      wrapperCol,
      formState,
      resetFields,
      resetForm,
      onSearch,
      validate,
      validateInfos,
      rules,
      disabledDate,
      dispatch,
      table,
      titleModal: 'Create role',
      paramFetch,
      loading,
      buttonsForm,
      buttonActions,
    };
  },
  computed: {
    ...mapFields('role', ['visible']),
  },
  methods: {
    preCreate() {
      this.dispatch('role/preCreate').then(
        () => (this.titleModal = 'Create role')
      );
    },
    saveSuccess() {
      this.dispatch('role/setVisible', false).then(() =>
        this.$refs.table.resetAndFetch(true)
      );
    },
    onEdit(row) {
      this.dispatch('role/preUpdate', row).then();
      this.titleModal = 'Update role';
    },
    onView(row) {
      this.dispatch('role/preView', row).then();
      this.titleModal = 'Role details';
    },
    async onDelete() {
      if (await this.dispatch('role/delete', this.$refs.table.selectedRows))
        this.$refs.table.resetAndFetch(true);
    },
  },
};
