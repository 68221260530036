<template>
  <cds-cards class="permission">
    <div class="container">
      <a-row v-for="(permission, i) in permissions" :key="i" :gutter="20" class="row-permission pl-2">
        <CloseCircleFilled class="close-icon" @click="removePermission(i)"></CloseCircleFilled>
        <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24" v-if="permission">
          <a-row :gutter="20" class="pl-2 mb-2">
            <a-checkbox class="mb-2" v-model:checked="permission.io" @change="checkIOChange(permission)">{{ $t('user_group.io_management') }}</a-checkbox>
            <IOModal v-model:groups="permission.groups" v-model:ios="permission.ios"></IOModal>
          </a-row>

          <a-row :gutter="20" class="pl-2 mb-2">
            <a-checkbox v-model:checked="permission.map">{{ $t('user_group.map') }}</a-checkbox>
          </a-row>

          <a-row :gutter="20" class="pl-2 mb-2">
            <a-checkbox v-model:checked="permission.event">{{ $t('user_group.event_management') }}</a-checkbox>
          </a-row>

          <a-row :gutter="20" class="pl-2 mb-2">
            <a-checkbox v-model:checked="permission.dashboard">{{ $t('user_group.dashboard') }}</a-checkbox>
          </a-row>
        </a-col>
        <a-col :xxl="12" :xl="12" :lg="12" :md="12" :xs="24" v-if="permission">
          <a-row class="checkbox-permission">
            <a-col :xxl="4" :xl="4" :lg="4" :md="4" :xs="24" class="mb-1">
              <a-checkbox v-model:checked="permission.checkAll" :disabled="!permission.io" @change="checkAllChange(permission)">
                {{ $t('user_group.all') }}
              </a-checkbox>
            </a-col>
            <a-col :xxl="5" :xl="5" :lg="5" :md="5" :xs="24">
              <a-checkbox v-model:checked="permission.create" :disabled="!permission.io" @change="checkChange(permission)">{{ $t('user_group.create') }}</a-checkbox>
            </a-col>
            <a-col :xxl="5" :xl="5" :lg="5" :md="5" :xs="24">
              <a-checkbox v-model:checked="permission.view" :disabled="!permission.io" @change="checkChange(permission)">{{ $t('user_group.view') }}</a-checkbox>
            </a-col>
            <a-col :xxl="5" :xl="5" :lg="5" :md="5" :xs="24">
              <a-checkbox v-model:checked="permission.update" :disabled="!permission.io" @change="checkChange(permission)">{{ $t('user_group.update') }}</a-checkbox>
            </a-col>
            <a-col :xxl="5" :xl="5" :lg="5" :md="5" :xs="24">
              <a-checkbox v-model:checked="permission.delete" :disabled="!permission.io" @change="checkChange(permission)">{{ $t('user_group.delete') }}</a-checkbox>
            </a-col>
            <a-col :span="24">
              <a-form-item>
                <cds-date-range-picker
                    :disabled-date="lessThanCurrent"
                    placeholder=""
                    format="DD/MM/YYYY HH:mm:ss"
                    show-time
                    :disabled="!permission.io"
                    v-model:value="permission.time"
                >
                </cds-date-range-picker>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <PlusCircleFilled class="plus-icon mt-3" :style="{'font-size': '1.7rem', color: primaryColor}" @click="addPermission"></PlusCircleFilled>
    </div>

    <hr class="footer">

    <div class="w-100 text-right">
      <cds-button
          key="update"
          type="primary"
          size="small"
          class="mr-20"
          @click="update"
      >
        <cds-feather-icons class="mr-10"/>
        {{ $t('common.update') }}
      </cds-button>
      <cds-button key="back" type="light" size="small" @click="onCancel">
        <cds-feather-icons class="mr-10" type="x"/>
        {{ $t('common.cancel') }}
      </cds-button>
    </div>
  </cds-cards>
</template>

<script>
import {computed, defineComponent, onMounted} from "vue";
import {useStore} from "vuex";
import IOModal from './io-modal.vue'
import {lessThanCurrent} from "@/util/common-utils";
import {CloseCircleFilled, PlusCircleFilled} from "@ant-design/icons-vue";
import {primaryColor} from "@/config/theme/themeVariables";

export default defineComponent({
  components: {
    CloseCircleFilled,
    IOModal,
    PlusCircleFilled,

  },
  emits: ['cancel'],
  setup(_, {emit}) {
    const {state, dispatch} = useStore();
    const permissions = computed(() => state.userGroup.group.permissions);
    const checkAllChange = (permission) => {
      permission.create = permission.update = permission.delete = permission.view = permission.checkAll
    }
    const checkChange = (permission) => {
      permission.checkAll = permission.create && permission.update && permission.delete && permission.view
    }

    const checkIOChange = (permission) => {
      if (!permission.io) {
        permission.create = permission.update = permission.delete = permission.view = false
      }
    }

    const addPermission = () => {
      permissions.value.push({
        order: 0,
        dashboard: false,
        event: false,
        map: false,
        io: false,
        view: false,
        create: false,
        update: false,
        delete: false,
        groups: [],
        ios: {},
        time: []
      })
    }

    const removePermission = (i) => {
      permissions.value.splice(i, 1)
    }
    const onCancel = () => emit('cancel')

    onMounted(() => dispatch('person/getIOSelect'))

    const update = () => {
      dispatch('userGroup/updatePermission')
    }
    return {
      permissions,
      checkAllChange,
      checkChange,
      checkIOChange,
      primaryColor: primaryColor,
      addPermission,
      removePermission,
      onCancel,
      update
    }
  },
  methods: {lessThanCurrent}
});
</script>

<style scoped lang="scss">

.checkbox-permission {
  .ant-checkbox-wrapper {
    //display: block;
  }
}

.permission {
  .container {
    height: 57vh;
    overflow: auto;
  }

  .row-permission {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    padding-right: 20px;
  }

  .plus-icon {
    cursor: pointer;
  }

  .close-icon {
    font-size: 1rem;
    color: #a5a5a5;
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
    &:hover {
      color: red
    }
  }
}

</style>
